import api from '@/api/api';
const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA;

const config = {
    headers: {
        "Content-Type": "application/json",
    }
};


export default class PaymentIntent {
    
    async getPaymentIntent(tokenAuth, personId) {
        const resource = `/${business}/stripe_pending_intent/get_list`;
        const API_URL = `${host}${resource}`;

        const params = {
            token_auth: tokenAuth,
            person_id: personId
        };

        try
        {
            return await api.httpGet(API_URL, config, params);
        }
        catch(error)
        {
            console.log("Payment :::", error)
        }
    }

}