<template>
<div>
  <Loading :isLoading="isLoading" />
  <center v-show="listPaymentIntent.length > 0">
    <b>Cliente ya realizó pago de parte social.</b>
  </center>
  <div class="2xl:grid 2xl:grid-cols-12 md:grid md:grid-cols-12 grid grid-cols-1 gap-4" style="padding:14px;">
    <Modal v-show="showModalComments" :widthContainer="'60%'">
      <template v-slot:header>
        <h3 style="font-weight:700;font-family:Lato;color:#810042;">Observación</h3>
      </template>
      <template v-slot:body>
        <textarea name="textarea" rows="5" cols="87" id="commentDocuments" v-model="comment" style="padding:10px;border:1px solid #FFC300;border-radius:5px;font-weight:700;font-family:Lato;color:grey;width:100%;height:150px;"></textarea>
      </template>
      <template v-slot:footer>
        <input type="button" value="Cancelar" class="buttonWithoutBackground" @click="closeModalComments()">
        <input type="button" value="Añadir" class="buttonSave" @click="saveComment()" style="margin-left:10px;">
      </template>
    </Modal>

    <Modal v-show="showModalCommentsReview" :widthContainer="'60%'">
      <template v-slot:header>
        <h3 style="font-weight:700;font-family:Lato;color:#810042;">Observaciones</h3>
      </template>
      <template v-slot:body>
        <div class="overflow-auto h-96">
          <div v-for="(comment, index) in comment_review" :key="index">
            <textarea name="textarea" rows="5" cols="87" id="commentDocumentsReview" disabled="disabled" style="padding:10px;border:1px solid #FFC300;border-radius:5px;font-weight:700;font-family:Lato;color:grey;width:100%;height:150px;" :value="comment.comment" @input="comment.comment = $event.target.value" ></textarea>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <input type="button" value="Cerrar" class="buttonWithoutBackground" @click="closeModalCommentsReview()">
      </template>
    </Modal>

    <Modal v-show="showModalCancelRequest" :widthContainer="'60%'">
      <template v-slot:header>
        <h3 style="font-weight:700;font-family:Lato;color:#810042;">Cancelar Solicitud de Ingreso</h3>
      </template>
      <template v-slot:body>
        <div class="grid grid-cols-2 gap-4">
          <div class="form-group">
            <label class="labelTitle">Razón de cancelación:</label>
            <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            minlength="13" maxlength="13" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{0,13}" id="title_history" v-model="reason_cancel" required>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="form-group col-span-2">
            <label class="labelTitle">Observación:</label>
            <textarea name="textarea" rows="5" cols="87" v-model="comment_cancel" id="commentRejected" class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex"></textarea>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <input type="button" value="Cerrar" class="mx-5 buttonWithoutBackground" @click="closeModalCommentsReview()">
        <input type="button" value="Aceptar" class="mx-5 buttonSave" @click="cancelRequest(reason_cancel, comment_cancel)">
      </template>
    </Modal>

    <Modal v-show="showModalRejectRequest" :widthContainer="'60%'">
      <template v-slot:header>
        <h3 style="font-weight:700;font-family:Lato;color:#810042;">Rechazar Solicitud de Ingreso</h3>
      </template>
      <template v-slot:body>
        <div class="w-full gap-4">
          <div class="form-group">
            <label class="labelTitle">Razón de rechazo:</label>
            <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor " v-model="reason_rejection" required>
              <option value="Mal comportamiento en el historial crediticio">Mal comportamiento en el historial crediticio</option>
              <option value="Sin liquidez en sus comprobantes de ingresos">Sin liquidez en sus comprobantes de ingresos</option>
              <option value="Errores o inconsistencias en la información presentada">Errores o inconsistencias en la información presentada</option>
              <option value="Audio y video no legibles como prueba de vida">Audio y video no legibles como prueba de vida</option>
              <option value="Situación laboral inestable">Situación laboral inestable</option>
            </select>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="form-group col-span-2">
            <label class="labelTitle">Observación:</label>
            <textarea name="textarea" rows="5" cols="87" v-model="comment_rejected" id="commentRejected" class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex;"></textarea>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <input type="button" value="Cancelar" class="buttonWithoutBackground" @click="showModalRejectRequest = false">
        <input type="button" value="Aceptar" class="buttonSave" @click="rejectRequest(reason_rejection, comment_rejected)" style="margin-left:10px;">
      </template>
    </Modal>

    <Modal v-show="showModalObservationHistoryAdd" :widthContainer="'60%'">
      <template v-slot:header>
        <h3 style="font-weight:700;font-family:Lato;color:#810042;">Añadir observación</h3>
      </template>
      <template v-slot:body>
        <div class="grid grid-cols-2 gap-4">
          <div class="form-group">
            <label class="labelTitle mr-5">Título:</label>
            <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor "
            minlength="60" maxlength="60" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{0,60}" id="title_history" v-model="title_history" required>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="form-group col-span-2">
            <label class="labelTitle">Observación:</label>
            <textarea class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex" name="textarea" v-model="comment_history" id="commentHistory"></textarea>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <input type="button" value="Cancelar" class="buttonWithoutBackground" @click="closeModalCommentHistory()">
        <input type="button" value="Añadir" class="buttonSave" @click="saveCommentHistory(title_history, comment_history)" style="margin-left:10px;">
      </template>
    </Modal>

    <Modal v-show="showModalObservationHistory" :widthContainer="'60%'">
      <template v-slot:header>
        <h6 style="font-weight:700;font-family:Lato;color:#810042;">Historial de Observaciones</h6>
      </template>
      <template v-slot:body>
        <div v-if="list_observation_history.length>0" style="overflow-y: scroll;height:320px;">
          <div class="md:grid md:grid-cols-12 grid grid-cols-1 gap-4" style="margin-top:10px;" v-for="item in list_observation_history" :key="'A'+item.id"> 
            <div class="col-span-2" style="background:#fff;border-width: 1px;border-style: solid;border-color:#85C875;border-radius:7px;box-shadow: 0 0 5px #9ecaed;color:#fff;background-color:#66bb6a;padding:5px;height:150px;display:flex;align-items:center;text-align:center;">
              <p style="font-size:13px;font-weight:700;width:100%;">{{ $filters.formatCurrentDateName(item.added_comment_date) }} <br /> <span style="font-size:10px;width:100%;">{{ $filters.formatCurrentDateHour(item.added_comment_date) }} <br />  Hrs</span></p>
            </div>
            <div class="col-span-10" style="background:#fff;border-width: 1px;border-style: solid;border-color:#85C875;border-radius:7px;box-shadow: 0 0 5px #9ecaed;padding:5px;">
              <p style="background-color: #f98b04;color:#fff;font-size:15px;font-weight:bold;border-radius:5px;padding:2px 5px 2px 5px;width:195px;">Confirmar Datos de socio</p>
              <p style="font-size:13px;font-weight:700;color:#174A84;font-size:12px;">{{ item.comment }}</p>
              <p style="font-size:13px;font-weight:700;color:#174A84;font-size:12px;text-align:right;">{{ $filters.formatCamelize(`${name_associate} ${paternal_name_associate}`) }}</p>
            </div>
          </div>
        </div>
        <div v-if="list_observation_history.length==0" class="w-full text-center h-32 font-light text-sm">
            Sin observaciones para mostrar
        </div>
      </template>
      <template v-slot:footer>
        <input type="button" value="Cancelar" class="buttonWithoutBackground" @click="showModalObservationHistory = false">
        <input type="button" value="Añadir" class="buttonSave" @click="openlModalObservationHistory()" style="margin-left:10px;">
      </template>
    </Modal>
  
    <Modal v-show="showModalHistory" :widthContainer="'40%'">
      <template v-slot:header>
        <h3 style="font-weight:700;font-family:Lato;color:#810042;">Correo de Documentación Faltante</h3>
      </template>
      <template v-slot:body>
        <div v-if="itemMailHistory != null" style="overflow-y: scroll;height:320px;">
          <p style="color: rgb(23, 74, 132);"> <label class="font-semibold">Fecha de envío: </label> {{ $filters.formatDate(listMailHistory[itemMailHistory].created_at) }} - {{ $filters.formatCurrentDateHour(listMailHistory[itemMailHistory].created_at) }} Hrs</p>
          <p style="color: rgb(23, 74, 132);"> <label class="font-semibold">Correo al que se envió:</label> {{ listMailHistory[itemMailHistory].receiver }} </p>
          <p style="color: rgb(23, 74, 132);"> <label class="font-semibold">Enviado por: </label> {{ listMailHistory[itemMailHistory].body.associate_name }}</p>
          <p style="color: rgb(23, 74, 132);"> <label class="font-semibold">Documentos Faltantes: </label> 
            <ul class="ml-12">
              <li style="color: rgb(23, 74, 132);" v-for="(document, index) in listMailHistory[itemMailHistory].body.missing_documents"  :key="index">
                &#8226; {{document}}
              </li>
            </ul>
          
          </p>
          <p style="color: rgb(23, 74, 132);"> <label class="font-semibold">Documentos Faltantes:</label> </p>
          <span style="color: rgb(23, 74, 132);" v-html="listMailHistory[itemMailHistory].body.description"></span>
        </div>
      </template>
      <template v-slot:footer>
        <input type="button" value="Cerrar" class="buttonWithoutBackground w-64" @click="showModalHistory = false">
      </template>
    </Modal>

    <Modal v-if="showModalDatosPersonales" :heightContainer="'80%'" :widthContainer="'60%'">
      <template v-slot:header>
        <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;">Datos Personales</p>
      </template>
      <template v-slot:body>
        <form @submit="updateDatosPersonales">
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20">
            <div class="">
              <div class="form-group">
                <label class="labelTitle">R.F.C.:</label>
                <p style="font-size:12px;color:grey;font-weight:900;font-family:Lato;" class="shadow-md border-2 rounded-lg py-2 w-full px-5">{{ admission_request_update.admission_information.rfc }}</p>
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Primer Nombre:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="13" maxlength="13" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{0,13}" id="name" v-model="admission_request_update.admission_information.name" disabled>
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Segundo Nombre:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor focus:border-cherryColor"
                minlength="13" maxlength="13" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{0,13}" id="middle_name" v-model="admission_request_update.admission_information.middle_name" disabled>
              </div>
            </div>
            <div>
              <div class="sm:mt-2">
                <label class="labelTitle">Apellido Paterno:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="13" maxlength="13" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{0,13}" id="paternal_name" v-model="admission_request_update.admission_information.paternal_name" disabled>
              </div>
            </div>
            <div class="sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">Apellido Materno:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="13" maxlength="13" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{0,13}" id="maternal_name" v-model="admission_request_update.admission_information.maternal_name" disabled>
              </div>
            </div>
            <div class="sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">Género:</label> <br>
                <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="admission_request_update.admission_information.gender" disabled>
                  <option value="masculino">MASCULINO</option>
                  <option value="femenino">FEMENINO</option>
                </select>
              </div>
            </div>
            <div class="sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">Fecha de Nacimiento:</label>
                <!-- <input type="text" id="datepickerDateBirth" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="birthday_datos_personales" required> -->
                <VueDatePickerNew
                  teleport-center
                  :max-date="today"
                  :clearable="false"
                  v-model="birthday_datos_personales"
                  :enable-time-picker="false"
                  dark
                  :flow="['year', 'month', 'calendar']"
                  format="dd/MM/yyyy"
                  model-type="dd/MM/yyyy"
                  locale="es"
                  cancelText="Cancelar"
                  selectText="Seleccionar"
                />
              </div>
            </div>
            <div class="sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">C.U.R.P.:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="13" maxlength="18" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{0,18}" id="curp" v-model="admission_request_update.admission_information.curp" required>
              </div>
            </div>           
          </div>
          <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;" class="sm:mt-3">Identificación</p>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20">
            <div class="sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">Tipo de Identificación:</label>
                <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="admission_request_update.admission_information.identification_type" required>
                  <option value="INE/IFE">INE/IFE</option>
                  <option value="PASAPORTE">PASAPORTE</option>
                </select>
              </div>
            </div>
            <div class="sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">Número de Identificación:</label>
                <input v-if="admission_request_update.admission_information.identification_type == 'INE/IFE' || admission_request_update.admission_information.identification_type == ''" type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="13" maxlength="13" pattern="[0-9]{0,13}" id="identification_number" v-model="admission_request_update.admission_information.identification_number" required>
                <input v-if="admission_request_update.admission_information.identification_type == 'PASAPORTE'" type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="10" maxlength="10" pattern="[a-zA-Z-0-9]{0,10}" id="identification_number" v-model="admission_request_update.admission_information.identification_number" required>
              </div>
            </div>
          </div>
          <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;" class="sm:mt-3">Datos de contacto</p>
          <div class="md:grid md:grid-cols-4 grid grid-cols-1 gap-x-20">
            <div class="md:col-span-2 sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">Teléfono:</label> <br>
                <input class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                minlength="12" maxlength="12" v-maska="'### ###-####'" min="0"  id="phoneDatosPersonalesUpdate" v-model="phone_datos_personales_update" @change="changeDatosContactoPersonales('telefono particular', phone_datos_personales_update)">
              </div>
            </div>
            <div class="md:col-span-2">
              <div class="form-group">
                <label class="labelTitle">Celular:</label>
                <input class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;" type="text"
                minlength="12" maxlength="12" v-maska="'### ###-####'" min="0"  id="cellPhoneDatosPersonalesUpdate"  v-model="cellphone_datos_personales_update" @change="changeDatosContactoPersonales('celular', cellphone_datos_personales_update)">
              </div>
            </div>
            <div class="md:col-span-2 sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">Correo Electrónico:</label>
                <input type="email" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                minlength="1" maxlength="200" id="nationality" v-model="email_datos_personales_update">
              </div>
            </div>
          </div>
          <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;" class="mt-5">Lugar de Nacimiento</p>
          <div class="md:grid md:grid-cols-4 grid grid-cols-1 gap-x-20">
            <div class="md:col-span-2" v-if="admission_request_update.admission_information.country_birth.toLowerCase() == 'méxico'">
              <div class="form-group">
                <label class="labelTitle">Estado:</label>
                <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="admission_request_update.admission_information.state_birth" @change="changeValueStateNac()" required>
                  <option v-for="state in list_states_nac" :key="state.id" v-bind:value="addUpperCase(state.nombre)">{{ addUpperCase(state.nombre) }}</option>
                </select>
              </div>
            </div>
            <div class="md:col-span-2" v-if="admission_request_update.admission_information.country_birth.toLowerCase() == 'méxico'">
              <div class="form-group">
                <label class="labelTitle">Nacionalidad:</label>
                <input :disabled="true" type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                minlength="4" maxlength="30" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{4,30}"  id="nationality" v-model="admission_request_update.admission_information.nationality" required>
              </div>
            </div>
            <div class="md:col-span-2 sm:mt-2" v-if="admission_request_update.admission_information.country_birth.toLowerCase() == 'méxico'">
              <div class="form-group">
                <label class="labelTitle">Municipio:</label>
                <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="admission_request_update.admission_information.municipality_birth" @change="changeValueMunicipalityNac()" required>
                  <option v-for="municipality in list_municipalities_nac" :key="municipality.id" v-bind:value="addUpperCase(municipality.nombre)">{{ addUpperCase(municipality.nombre) }}</option>
                </select>
              </div>
            </div>
            <div class="md:col-span-2 sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">País:</label>
                <input :disabled="true" type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                minlength="4" maxlength="30" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{4,30}" id="country" v-model="admission_request_update.admission_information.country_birth" required>
              </div>
            </div>
            <div class="md:col-span-2 sm:mt-2" v-if="admission_request_update.admission_information.country_birth.toLowerCase() == 'méxico'">
              <div class="form-group">
                <label class="labelTitle">Ciudad:</label>
                <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="admission_request_update.admission_information.city_birth" :required="isCityNacRequired ? true : false">
                  <option v-for="city in list_cities_nac" :key="city.id" v-bind:value="addUpperCase(city.nombre)">{{ addUpperCase(city.nombre) }}</option>
                </select>
              </div>
            </div>
          </div>
          <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;" class="sm:mt-5">Estado Civil</p>
          <div class="md:grid md:grid-cols-4 grid grid-cols-1 gap-x-10">
            <div class="md:col-span-2 sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">Estado Civil:</label>
                <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="admission_request_update.admission_information.marital_status">
                  <option value="SOLTERO(A)">Soltero(a)</option>
                  <option value="CASADO(A)">Casado(a)</option>
                  <option value="DIVORCIADO(A)">Divorciado(a)</option>
                  <option value="VIUDO(A)">Viudo(a)</option>
                  <option value="CONCUBINATO">Concubinato</option>
                </select>
              </div>
            </div>
            <div class="md:col-span-2" v-show="admission_request_update.admission_information.marital_status == 'CASADO(A)'">
              <div class="form-group">
                <label class="labelTitle">Régimen de Bienes:</label>
                <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="admission_request_update.admission_information.regime" :required="admission_request_update.admission_information.marital_status == 'CASADO(A)' ? true : false">
                  <option value="SEPARADOS">Separados</option>
                  <option value="MANCUMUNADOS">Mancumunados</option>
                </select>
              </div>
            </div>
          </div>
          <div class="mt-5" v-if="admission_request_update.admission_information.marital_status == 'CASADO(A)' || admission_request_update.admission_information.marital_status == 'CONCUBINATO'">
            <p style="font-size: 20px; font-weight: 700; font-family: Lato; color: rgb(129, 0, 66);" >Datos del {{ (admission_request_update.admission_information.marital_status == "CASADO(A)")? 'Cónyuge': 'Concubino(a)' }} </p>
            <div class="md:grid md:grid-cols-4 grid grid-cols-1 gap-x-10">
              <div>
                <div class="form-group">
                  <label class="labelTitle">Género:</label>
                  <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="gender_spouse" required>
                    <option value="masculino">MASCULINO</option>
                    <option value="femenino">FEMENINO</option>
                  </select>
                </div>
              </div>
              <div>
                <div class="form-group">
                  <label class="labelTitle">R.F.C:</label>
                  <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                  minlength="13" maxlength="13" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{13,13}" id="nationality" v-model="rfc_spouse" required>
                </div>
              </div>
              <div>
                <div class="form-group">
                  <label class="labelTitle">Primer Nombre:</label>
                  <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                  minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{3,30}" id="name" v-model="name_spouse" required>
                </div>
              </div>
              <div>
                <div class="form-group">
                  <label class="labelTitle">Segundo Nombre:</label>
                  <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                  minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{3,30}" id="middle_name" v-model="middle_name_spouse">
                </div>
              </div>
              <div>
                <div class="form-group">
                  <label class="labelTitle">Apellido Paterno:</label>
                  <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                  minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{3,30}" id="paternal_name" v-model="paternal_name_spouse">
                </div>
              </div>
              <div class="">
                <div class="form-group">
                  <label class="labelTitle">Apellido Materno:</label>
                  <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                  minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{3,30}" id="maternal_name" v-model="maternal_name_spouse">
                </div>
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-4 grid grid-cols-1 gap-x-20">
            <div class="md:col-span-4">
              <button class="buttonSave" style="float:right;">Guardar</button>
              <input type="button" value="Cancelar" class="buttonWithoutBackground" @click="cancelDatosPersonales();" style="float:right;margin-right:10px;">
            </div>
          </div>
        </form>
      </template>
    </Modal>

    <Modal v-show="showModalDatosPersonalesDireccion" :widthContainer="'60%'">
      <template v-slot:header>
        <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;">Datos de Dirección</p>
      </template>
      <template v-slot:body>
        <form @submit="updateDatosPersonalesDireccion">
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20">
            <div>
              <div class="form-group">
                <label class="labelTitle">Calle:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                minlength="4" maxlength="50" @keyup="setterAdressPerson()" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,50}" id="street" v-model="admission_request_update.admission_information.adress_attributes.street" required>
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Entre Calle:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                minlength="1" maxlength="50" @keyup="setterAdressPerson()" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{1,50}" id="between_street_one" v-model="admission_request_update.admission_information.adress_attributes.between_street_one" required>
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Y Calle:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                minlength="1" maxlength="50" @keyup="setterAdressPerson()" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{1,50}" id="between_street_two" v-model="admission_request_update.admission_information.adress_attributes.between_street_two" required>
              </div>
            </div>
            <div class="sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">Número Exterior:</label>
                <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                onKeyPress="if(this.value.length==5) return false;" @keyup="setterAdressPerson()" minlength="1" maxlength="6" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{1,6}" min="0" oninput="validity.valid||(value='');" id="ext_number" v-model="admission_request_update.admission_information.adress_attributes.ext_number" required>
              </div>
            </div>
            <div class="sm:mt-2">
              <div class="form-group">
                <label class="labelTitle">Número Interior:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="1" maxlength="20" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{1,20}" @keyup="setterAdressPerson()" id="int_number" v-model="admission_request_update.admission_information.adress_attributes.int_number">
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20 sm:mt-2">
            <div>
              <div class="form-group">
                <label class="labelTitle">Código Postal:</label>
                <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="5" maxlength="5" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{0,13}" id="postalCode"
                @keyup="getInfoAdressByPostalCodeInformacionPersonal(admission_request_update.admission_information.adress_attributes.postal_code)"
                onKeyPress="if(this.value.length==5) return false;" min="0" oninput="validity.valid||(value='');" v-on:blur="validateCP(admission_request_update.admission_information.adress_attributes.postal_code)" v-model="admission_request_update.admission_information.adress_attributes.postal_code" required>
              </div>
            </div>
            <div class="md:col-span-2">
              <div class="form-group">
                <label class="labelTitle w-full">Colonia:</label> <br>
                <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="admission_request_update.admission_information.adress_attributes.suburb" @change="setterAdressPerson()" required>
                  <option v-for="colony in list_colonys_datos_personales " v-bind:value="addUpperCase(colony.nombre)" :key="colony.id">{{ addUpperCase(colony.nombre) }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20 sm:mt-2">
          <div class="">
              <div class="form-group">
                <label class="labelTitle">Municipio:</label>
                <p style="font-size:12px;color:grey;font-weight:900;font-family:Lato;text-transform: uppercase;">{{ admission_request_update.admission_information.adress_attributes.municipality }}</p>
              </div>
            </div>
            <div class="">
              <div class="form-group">
                <label class="labelTitle">Ciudad:</label>
                <p style="font-size:12px;color:grey;font-weight:900;font-family:Lato;text-transform: uppercase;">{{ admission_request_update.admission_information.adress_attributes.city }}</p>
              </div>
            </div>
            <div class="">
              <div class="form-group">
                <label class="labelTitle">Estado:</label>
                <p style="font-size:12px;color:grey;font-weight:900;font-family:Lato;text-transform: uppercase;">{{ admission_request_update.admission_information.adress_attributes.state }}</p>
              </div>
            </div>
            <div v-if="listHousingTypes.length > 0 && !['health_worker'].includes(clientClassification)" class="form-group">
              <label class="labelTitle w-full">Tipo de Vivienda:</label> <br>
              <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="residence_status" required>
                <option v-for="(housingType, index) in listHousingTypes" :key="index" v-bind:value="housingType.value">{{ housingType.name }}</option>
              </select>
            </div>
            <div v-if="!['health_worker'].includes(clientClassification)" class="form-group">
              <label class="labelTitle w-full">Inicio de Residencia:</label> <br>
              <VueDatePickerNew
                teleport-center
                :max-date="today"
                :clearable="false"
                v-model="residence_start"
                :enable-time-picker="false"
                dark
                :flow="['year', 'month', 'calendar']"
                format="dd/MM/yyyy"
                model-type="dd/MM/yyyy"
                locale="es"
                cancelText="Cancelar"
                selectText="Seleccionar"
              />
            </div>
          </div>
          <div class="md:grid md:grid-cols-4 grid grid-cols-1 gap-x-20">
            <div class="md:col-span-4">
              <button class="buttonSave" style="float:right;">Guardar</button>
              <input type="button" value="Cancelar" class="buttonWithoutBackground" @click="cancelDatosDireccion()" style="float:right;margin-right:10px;">
            </div>
          </div>
        </form>
      </template>
    </Modal>

    <Modal v-if="showModalDatosLaborales" :heightContainer="'80%'" :widthContainer="'60%'">
      <template v-slot:header>
        <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;">Datos Laborales</p>
      </template>
      <template v-slot:body>
        <form @submit="updateDatosLaborales">
          <div class="md:grid md:grid-cols-1 grid grid-cols-1 gap-x-20">
            <div v-if="['businessman','educator','student','retired'].includes(clientClassification)">
              <div class="form-group">
                <label class="labelTitle">Estatus:</label>
                <div class="input-group">
                  <input type="radio" name="rdbStatus" id="rdbStatus-active" value="ACTIVO" v-model="admission_request_update.admission_information.work_info_attributes.laboral_status"
                  v-on:change="setAdmissionDatePicker()" required /> <label for="rdbStatus-active">Activo(a)</label>
                  <input type="radio" name="rdbStatus" id="rdbStatus-status" value="JUBILADO" v-model="admission_request_update.admission_information.work_info_attributes.laboral_status"
                  v-on:change="setAdmissionDatePicker()" style="margin-left:20px;" /> <label for="rdbStatus-status">Jubilado(a)</label>
                  <input type="radio" name="rdbStatus" id="rdbStatus-status" value="ESTUDIANTE" v-model="admission_request_update.admission_information.work_info_attributes.laboral_status"
                  v-on:change="setAdmissionDatePicker()" style="margin-left:20px;" /> <label for="rdbStatus-status">Estudiante</label>
                </div>
              </div>
            </div>
            <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20" v-if="admission_request_update.admission_information.work_info_attributes.laboral_status == 'ACTIVO' || admission_request_update.admission_information.work_info_attributes.laboral_status == 'ESTUDIANTE'">
              <div v-if="clientClassification == 'businessman'" class="form-group">
                <label class="labelTitle">Nombre empresa:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,40}" id="school" v-model="admission_request_update.admission_information.work_info_attributes.business_name" required>
              </div>
              <div v-if="clientClassification != 'businessman'" class="form-group">
                <label class="labelTitle" v-if="['educator','student','retired'].includes(clientClassification)">Escuela:</label>
                <label class="labelTitle" v-if="['health_worker'].includes(clientClassification)">Nombre centro trabajo:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,40}" id="school" v-model="admission_request_update.admission_information.workplace_attributes.name" required>
              </div>
              <div v-if="admission_request_update.admission_information.work_info_attributes.principal_occupation != ''" class="form-group">
                <label class="labelTitle">Ocupación Principal:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,40}" id="school" v-model="admission_request_update.admission_information.work_info_attributes.principal_occupation" required>
              </div>
              <div v-if="false" class="form-group">
                <label class="labelTitle">Clave CT:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="text-transform: uppercase;"
                minlength="3" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{3,50}" id="ct" v-model="admission_request_update.admission_information.workplace_attributes.center_key" required>
              </div>
              <div class="form-group">
                <label class="labelTitle">Teléfono:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="12" maxlength="12" v-maska="'### ###-####'" min="0" id="phone" v-on:change="changePhoneDatosLaborales(phone_datos_laborales_update)" v-model="phone_datos_laborales_update">
              </div>
              <div class="form-group">
                <label class="labelTitle">Extensión:</label>
                <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                onKeyPress="if(this.value.length==4) return false;" oninput="validity.valid||(value='');"
                pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{0,4}" min="0" id="ext_number" v-on:change="changeExtensionDatosLaborales(extension_datos_laborales_update)" v-model="extension_datos_laborales_update">
              </div>
              <div class="form-group" v-if="false">
                <label class="labelTitle">Clave Presupuestal:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="4" maxlength="20" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,20}" id="clavepresupuestal" v-model="admission_request_update.admission_information.work_info_attributes.employee_number[0]" required>
              </div>
              <div class="form-group" v-if="['educator','student','retired'].includes(clientClassification)">
                <label class="labelTitle">Fecha de Ingreso:</label>
                <input type="text" id="datepickerAdmissionDate" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="active_since_datos_laborales" required>
              </div>
              <div class="form-group" v-if="['businessman','health_worker'].includes(clientClassification)">
                <label class="labelTitle">Fecha de Ingreso:</label>
                  <VueDatePickerNew
                    teleport-center
                    :max-date="today"
                    :clearable="false"
                    v-model="active_since_datos_laborales"
                    :enable-time-picker="false"
                    dark
                    :flow="['year', 'month', 'calendar']"
                    format="dd/MM/yyyy"
                    model-type="dd/MM/yyyy"
                    locale="es"
                    cancelText="Cancelar"
                    selectText="Seleccionar"
                  />
              </div>
              <div class="form-group" v-if="listCategories.length > 0">
                <label class="labelTitle">Categoría:</label>
                <select lang="en" translate="no" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" ref="stateSelected" required v-model="admission_request_update.admission_information.work_info_attributes.category" 
                  style="">
                    <option v-for="category in listCategories" v-bind:value="category.value">{{ category.name }}</option>
                </select>
              </div>
              <div v-if="admission_request_update.admission_information.work_info_attributes.laboral_status == 'ACTIVO'">
                <div class="form-group" v-if="['educator','student','retired'].includes(clientClassification)">
                  <label class="labelTitle">Puesto/Ocupación:</label>
                  <select class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" ref="stateSelected" required v-model="admission_request_update.admission_information.work_info_attributes.occupation">
                    <option v-bind:value="addUpperCase(item.occupation)" v-for="item in occupation_list" :key="item.id">{{addUpperCase(item.occupation)}}</option>
                  </select>
                </div>
                <div class="form-group" v-if="['health_worker'].includes(clientClassification)">
                  <label class="labelTitle">Puesto/Ocupación:</label>
                  <input type="text" id="occupationAdmissionDate" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                    minlength="2" maxlength="30" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{2,30}" v-model="admission_request_update.admission_information.work_info_attributes.occupation" required>
                </div>
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20 sm:mt-5">
            <div class="col-span-2">
              <div class="form-group">
                <label class="labelTitle">¿Tiene o ha tenido algún cargo público?</label>
                <div class="input-group">
                  <input type="radio" name="rdbHasHadPublicPosition" id="rdbHasHadPublicPosition-yes"  v-bind:value="true" v-model="has_had_public_positions" v-on:change="changePublicOffice('titular', 'has_had_public_positions', has_had_public_positions)" required/><label for="rdbHasHadPublicPosition-yes">SI</label>
                  <input type="radio" name="rdbHasHadPublicPosition" id="rdbHasHadPublicPosition-no" v-bind:value="false" v-model="has_had_public_positions" v-on:change="changePublicOffice('titular', 'has_had_public_positions', has_had_public_positions)" style="margin-left:20px"/><label for="rdbHasHadPublicPosition-no">NO</label><br>
                </div>
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20 sm:mt-2" v-show="has_had_public_positions == true">
            <div>
              <div class="form-group">
                <label class="labelTitle">Cargo público</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-on:change="changePublicOffice('titular', 'positions_name', positions_name)"
                minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,50}" id="cargopublico" v-model="positions_name">
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Periodo de:</label>
                <!-- <input type="text" id="datepickerStartDatePublicPosition" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="start_date_public_positions"> -->
                <VueDatePickerNew
                  teleport-center
                  :max-date="today"
                  :clearable="false"
                  v-model="start_date_public_positions"
                  :enable-time-picker="false"
                  dark
                  :flow="['year', 'month', 'calendar']"
                  format="dd/MM/yyyy"
                  model-type="dd/MM/yyyy"
                  locale="es"
                  cancelText="Cancelar"
                  selectText="Seleccionar"
                />
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">a:</label>
                <!-- <input type="text" id="datepickerEndDatePublicPosition" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="end_date_public_positions"> -->
                <VueDatePickerNew
                  teleport-center
                  :max-date="today"
                  :clearable="false"
                  v-model="end_date_public_positions"
                  :enable-time-picker="false"
                  dark
                  :flow="['year', 'month', 'calendar']"
                  format="dd/MM/yyyy"
                  model-type="dd/MM/yyyy"
                  locale="es"
                  cancelText="Cancelar"
                  selectText="Seleccionar"
                />
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20 sm:mt-5">
            <div class="col-span-3">
              <div class="form-group">
                <label class="labelTitle">¿Algún familiar ha tenido cargo público?</label>
                <div class="input-group">
                  <input type="radio" name="rdbHasHadPublicPositionFamiliar" id="rdbHasHadPublicPositionFamiliar-yes" v-bind:value="true" v-model="has_had_public_positions_familiar" v-on:change="changePublicOffice('familiar', 'has_had_public_positions', has_had_public_positions_familiar)" required/> <label for="rdbHasHadPublicPositionFamiliar-yes">SI</label>
                  <input type="radio" name="rdbHasHadPublicPositionFamiliar" id="rdbHasHadPublicPositionFamiliar-no" v-bind:value="false" v-model="has_had_public_positions_familiar" v-on:change="changePublicOffice('familiar', 'has_had_public_positions', has_had_public_positions_familiar)" style="margin-left:20px"/> <label for="rdbHasHadPublicPositionFamiliar-no">NO</label><br>
                </div>
              </div>
            </div>
            <div v-show="has_had_public_positions_familiar == true">
              <div class="form-group">
                <label class="labelTitle">Cargo público</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-on:change="changePublicOffice('familiar', 'positions_name', positions_name_familiar)"
                minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,50}" id="cargopublico" v-model="positions_name_familiar">
              </div>
            </div>
            <div v-show="has_had_public_positions_familiar == true">
              <div class="form-group">
                <label class="labelTitle">Periodo de:</label>
                <!--<input type="text" id="datepickerStartDatePublicPositionFamiliar" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="start_date_public_positions_familiar">-->
                <VueDatePickerNew
                  teleport-center
                  :max-date="today"
                  :clearable="false"
                  v-model="start_date_public_positions_familiar"
                  :enable-time-picker="false"
                  dark
                  :flow="['year', 'month', 'calendar']"
                  format="dd/MM/yyyy"
                  model-type="dd/MM/yyyy"
                  locale="es"
                  cancelText="Cancelar"
                  selectText="Seleccionar"
                />
              </div>
            </div>
            <div v-show="has_had_public_positions_familiar == true">
              <div class="form-group">
                <label class="labelTitle">a:</label>
                <!--<input type="text" id="datepickerEndDatePublicPositionFamiliar" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="end_date_public_positions_familiar">-->
                <VueDatePickerNew
                  teleport-center
                  :max-date="today"
                  :clearable="false"
                  v-model="end_date_public_positions_familiar"
                  :enable-time-picker="false"
                  dark
                  :flow="['year', 'month', 'calendar']"
                  format="dd/MM/yyyy"
                  model-type="dd/MM/yyyy"
                  locale="es"
                  cancelText="Cancelar"
                  selectText="Seleccionar"
                />
              </div>
            </div>
          </div>
          <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;" v-if="admission_request_update.admission_information.work_info_attributes.laboral_status == 'ACTIVO' || admission_request_update.admission_information.work_info_attributes.laboral_status == 'ESTUDIANTE'">Dirección</p>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20 sm:mt-2" v-if="admission_request_update.admission_information.work_info_attributes.laboral_status == 'ACTIVO' || admission_request_update.admission_information.work_info_attributes.laboral_status == 'ESTUDIANTE'">
            <div class="form-group">
              <label class="labelTitle">Calle:</label>
              <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,50}" id="calleDatosLaborales" v-model="admission_request_update.admission_information.workplace_attributes.adress_attributes.street" required>
            </div>
            <div class="form-group">
              <label class="labelTitle">Entre Calle:</label>
              <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,50}" id="entreCalleDatosLaborales" v-model="admission_request_update.admission_information.workplace_attributes.adress_attributes.between_street_one" required>
            </div>
            <div class="form-group">
              <label class="labelTitle">Y Calle:</label>
              <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,50}" id="yCalleDatosLaborales" v-model="admission_request_update.admission_information.workplace_attributes.adress_attributes.between_street_two" required>
            </div>
            <div class="form-group">
              <label class="labelTitle">Número Exterior:</label>
              <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              onKeyPress="if(this.value.length==5) return false;" min="0" oninput="validity.valid||(value='');" id="ext_number" v-model="admission_request_update.admission_information.workplace_attributes.adress_attributes.ext_number" required>
            </div>
            <div class="form-group">
              <label class="labelTitle">Código Postal:</label>
              <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              minlength="13" maxlength="13" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{0,13}" id="postalCode"
              @keyup="getInfoAdressByPostalCodeDatosLaborales(admission_request_update.admission_information.workplace_attributes.adress_attributes.postal_code)"
              onKeyPress="if(this.value.length==5) return false;" min="0" oninput="validity.valid||(value='');" v-on:blur="validateCP(admission_request_update.admission_information.workplace_attributes.adress_attributes.postal_code)" v-model="admission_request_update.admission_information.workplace_attributes.adress_attributes.postal_code" required>
            </div>
            <div class="form-group sm:mt-2">
              <label class="labelTitle">Colonia:</label>
              <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="admission_request_update.admission_information.workplace_attributes.adress_attributes.suburb" required>
                <option v-for="colony in list_colonys_datos_laborales " v-bind:value="addUpperCase(colony.nombre)" :key="colony.id">{{ addUpperCase(colony.nombre) }}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="labelTitle">Municipio:</label>
              <p style="font-size:15px;color:grey;font-weight:400;font-family:Lato;text-transform: uppercase;">{{ admission_request_update.admission_information.workplace_attributes.adress_attributes.municipality }}</p>
            </div>
            <div class="form-group">
              <label class="labelTitle">Ciudad:</label>
              <p style="font-size:15px;color:grey;font-weight:400;font-family:Lato;text-transform: uppercase;">{{ admission_request_update.admission_information.workplace_attributes.adress_attributes.city }}</p>
            </div>
            <div class="form-group">
              <label class="labelTitle">Estado:</label>
              <p style="font-size:15px;color:grey;font-weight:400;font-family:Lato;text-transform: uppercase;">{{ admission_request_update.admission_information.workplace_attributes.adress_attributes.state }}</p>
            </div>
          </div>
          <div class="md:grid md:grid-cols-4 grid grid-cols-1 gap-x-20 sm:mt-2">
            <div class="md:col-span-4">
              <button class="buttonSave" style="float:right;">Guardar</button>            
              <input type="button" value="Cancelar" class="buttonWithoutBackground" @click="cancelDatosLaborales('educator')" style="float:right;margin-right:10px;">
            </div>
          </div>
        </form>
      </template>
    </Modal>

    <Modal v-if="showModalDatosLaboralesMiembros" :heightContainer="'80%'" :widthContainer="'60%'">
      <template v-slot:header>
        <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;">Datos Laborales a</p>
      </template>
      <template v-slot:body>
        <form @submit="updateDatosLaboralesMiembros">
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20  gap-y-4">
            <div>
              <div class="form-group">
                <label class="labelTitle">Ocupación principal (Fuente principal de ingreso):</label> <br/>
                <input type="radio" name="rdbmainOccupation" value="Empleado" v-model="admission_request_update.admission_information.work_info_attributes.principal_occupation" v-on:change="changePrincipalOccupation()" required /> Empleado
                <input type="radio" name="rdbmainOccupation" value="Negocio Propio" v-model="admission_request_update.admission_information.work_info_attributes.principal_occupation" v-on:change="changePrincipalOccupation()" style="margin-left:20px;" /> Negocio Propio
              </div>
            </div>
            <div v-if="admission_request_update.admission_information.work_info_attributes.principal_occupation == 'Negocio Propio'">
              <div class="form-group">
                <label class="labelTitle">Razón Social de la empresa que genera su principal fuente de ingresos:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="3" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{}" id="clavepresupuestal" v-model="admission_request_update.admission_information.work_info_attributes.business_name" required>
              </div>
            </div>
            <div>
              <div class="">
                <label class="labelTitle">Clave/Número de empleado:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="4" maxlength="20" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,20}"  v-model="admission_request_update.admission_information.work_info_attributes.employee_number[0]" :required="!admission_request.assisted_request">
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Puesto que desempeña:</label>
                <select v-if="admission_request.assisted_request" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" ref="stateSelected" required v-model="admission_request_update.admission_information.work_info_attributes.occupation">
                  <optgroup v-for="businessActivity in listBusinessActivities"
                    :key="businessActivity.id"
                    :label="businessActivity.description">
                    <option :value="businessActivity.name">{{ businessActivity.name }}</option>
                  </optgroup>
                </select>
                <select v-if="!admission_request.assisted_request" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" ref="stateSelected" required v-model="admission_request_update.admission_information.work_info_attributes.occupation">
                  <option v-bind:value="addUpperCase(item.occupation)" v-for="item in occupation_list" :key="item.id">{{addUpperCase(item.occupation)}}</option>
                </select>
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Nombre del jefe inmediato:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="4" maxlength="20" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,20}" id="clavepresupuestal" v-model="admission_request_update.admission_information.work_info_attributes.supervisor">
              </div>
            </div>
            <div class="">
              <div class="form-group">
                <label class="labelTitle">Teléfono:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="12" maxlength="12" v-maska="'### ###-####'" min="0" id="phone" v-on:change="changePhoneDatosLaborales(phone_datos_laborales_update)" v-model="phone_datos_laborales_update" required>
              </div>
            </div>
            <div class="">
              <div class="form-group">
                <label class="labelTitle">Extensión:</label>
                <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                onKeyPress="if(this.value.length==4) return false;" oninput="validity.valid||(value='');"
                pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{}" min="0" id="ext_number" v-on:change="changeExtensionDatosLaborales(extension_datos_laborales_update)" v-model="extension_datos_laborales_update">
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Fecha de Ingreso:</label>
                <input type="text" id="datepickerAdmissionDate" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="active_since_datos_laborales" required>
              </div>
            </div>
          </div>
          <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;" class="gap-x-20  gap-y-4">Dirección</p>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20  gap-y-4">
            <div class="py-5">
              <div class="form-group">
                <label class="labelTitle">Calle:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,50}" id="cityDatosLaborales" v-model="admission_request_update.admission_information.workplace_attributes.street" required>
              </div>
            </div>
            <div class="py-5">
              <div class="form-group">
                <label class="labelTitle">Número Exterior:</label>
                <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                onKeyPress="if(this.value.length==5) return false;" min="0" oninput="validity.valid||(value='');" id="ext_number" v-model="admission_request_update.admission_information.workplace_attributes.ext_number" required>
              </div>
            </div>
            <div class="py-5">
              <div class="form-group">
                <label class="labelTitle">Código Postal:</label>
                <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                minlength="13" maxlength="13" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{0,13}" id="postalCode"
                @keyup="getInfoAdressByPostalCodeDatosLaborales(admission_request_update.admission_information.workplace_attributes.adress_attributes.postal_code)"
                onKeyPress="if(this.value.length==5) return false;" min="0" oninput="validity.valid||(value='');" v-on:blur="validateCP(admission_request_update.admission_information.workplace_attributes.adress_attributes.postal_code)" v-model="admission_request_update.admission_information.workplace_attributes.adress_attributes.postal_code" required>
              </div>
            </div>
            <div class="py-5">
              <div class="form-group">
                <label class="labelTitle">Colonia:</label>
                <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-model="admission_request_update.admission_information.workplace_attributes.adress_attributes.suburb" required>
                  <option v-for="colony in list_colonys_datos_laborales " v-bind:value="addUpperCase(colony.nombre)" :key="colony.id">{{ addUpperCase(colony.nombre) }}</option>
                </select>
              </div>
            </div>
          <div class="py-5">
              <div class="form-group">
                <label class="labelTitle">Municipio:</label>
                <p style="font-size:20px;color:grey;font-weight:400;font-family:Lato;text-transform: uppercase;">{{ admission_request_update.admission_information.workplace_attributes.adress_attributes.municipality }}</p>
              </div>
            </div>
            <div class="py-5">
              <div class="form-group">
                <label class="labelTitle">Ciudad:</label>
                <p style="font-size:20px;color:grey;font-weight:400;font-family:Lato;text-transform: uppercase;">{{ admission_request_update.admission_information.workplace_attributes.adress_attributes.city }}</p>
              </div>
            </div>
            <div class="py-5">
              <div class="form-group">
                <label class="labelTitle">Estado:</label>
                <p style="font-size:20px;color:grey;font-weight:400;font-family:Lato;text-transform: uppercase;">{{ admission_request_update.admission_information.workplace_attributes.adress_attributes.state }}</p>
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20  gap-y-4">
            <div>
              <div class="form-group">
                <label class="labelTitle">¿Tiene o ha tenido algún cargo público?</label>
                <div class="input-group">
                  <input type="radio" name="rdbHasHadPublicPosition" v-bind:value="true" v-model="has_had_public_positions" v-on:change="changePublicOffice('titular', 'has_had_public_positions', has_had_public_positions)" required/> SI
                  <input type="radio" name="rdbHasHadPublicPosition" v-bind:value="false" v-model="has_had_public_positions" v-on:change="changePublicOffice('titular', 'has_had_public_positions', has_had_public_positions)" style="margin-left:20px"/> NO <br>
                </div>
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20  gap-y-4" v-show="has_had_public_positions == true">
            <div>
              <div class="form-group">
                <label class="labelTitle">Cargo público</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-on:change="changePublicOffice('titular', 'positions_name', positions_name)"
                minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,50}" id="cargopublico" v-model="positions_name">
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Periodo de:</label>
                <!-- <input type="text" id="datepickerStartDatePublicPosition"
                 class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="start_date_public_positions"> -->
                <VueDatePickerNew
                  teleport-center
                  :max-date="today"
                  :clearable="false"
                  v-model="start_date_public_positions"
                  :enable-time-picker="false"
                  dark
                  :flow="['year', 'month', 'calendar']"
                  format="dd/MM/yyyy"
                  model-type="dd/MM/yyyy"
                  locale="es"
                  cancelText="Cancelar"
                  selectText="Seleccionar"
                />
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">a:</label>
                <!-- <input type="text" id="datepickerEndDatePublicPosition"
                  class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="end_date_public_positions"> -->
                <VueDatePickerNew
                  teleport-center
                  :max-date="today"
                  :clearable="false"
                  v-model="end_date_public_positions"
                  :enable-time-picker="false"
                  dark
                  :flow="['year', 'month', 'calendar']"
                  format="dd/MM/yyyy"
                  model-type="dd/MM/yyyy"
                  locale="es"
                  cancelText="Cancelar"
                  selectText="Seleccionar"
                />
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20  gap-y-4">
            <div>
              <div class="form-group">
                <label class="labelTitle">¿Algún familiar ha tenido cargo público?</label>
                <div class="input-group">
                  <input type="radio" name="rdbHasHadPublicPositionFamily" v-bind:value="true" v-model="has_had_public_positions_familiar" v-on:change="changePublicOffice('familiar', 'has_had_public_positions', has_had_public_positions_familiar)" required/> SI
                  <input type="radio" name="rdbHasHadPublicPositionFamily" v-bind:value="false" v-model="has_had_public_positions_familiar" v-on:change="changePublicOffice('familiar', 'has_had_public_positions', has_had_public_positions_familiar)" style="margin-left:20px"/> NO <br>
                </div>
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20  gap-y-4" v-show="has_had_public_positions_familiar == true">
            <div>
              <div class="form-group">
                <label class="labelTitle">Cargo público</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-on:change="changePublicOffice('familiar', 'positions_name', positions_name_familiar)"
                minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,50}" id="cargopublico" v-model="positions_name_familiar">
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Periodo de:</label>
                <!-- <input type="text" id="datepickerStartDatePublicPositionFamiliar" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="start_date_public_positions_familiar"> -->
                <VueDatePickerNew
                  teleport-center
                  :max-date="today"
                  :clearable="false"
                  v-model="start_date_public_positions_familiar"
                  :enable-time-picker="false"
                  dark
                  :flow="['year', 'month', 'calendar']"
                  format="dd/MM/yyyy"
                  model-type="dd/MM/yyyy"
                  locale="es"
                  cancelText="Cancelar"
                  selectText="Seleccionar"
                />
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">a:</label>
                <!-- <input type="text" id="datepickerEndDatePublicPositionFamiliar" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="end_date_public_positions_familiar"> -->
                <VueDatePickerNew
                  teleport-center
                  :max-date="today"
                  :clearable="false"
                  v-model="end_date_public_positions_familiar"
                  :enable-time-picker="false"
                  dark
                  :flow="['year', 'month', 'calendar']"
                  format="dd/MM/yyyy"
                  model-type="dd/MM/yyyy"
                  locale="es"
                  cancelText="Cancelar"
                  selectText="Seleccionar"
                />
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-4 grid grid-cols-1 gap-x-20 py-10">
            <div class="md:col-span-4">
              <button class="buttonSave" style="float:right;">Guardar</button>            
              <input type="button" value="Cancelar" class="buttonWithoutBackground" @click="cancelDatosLaborales('cabsa_employee')" style="float:right;margin-right:10px;">
            </div>
          </div>
        </form>
      </template>
    </Modal>

    <Modal v-show="showModalInfoEconomicaYAhorro" :widthContainer="'60%'">
      <template v-slot:header>
        <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;">Información Económica y Ahorro</p>
      </template>
      <template v-slot:body>
        <form @submit="updateInfoEconomicaYAhorro">
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20">
            <div>
              <div class="form-group">
                <label class="labelTitle mt-2">Ingreso bruto mensual:</label>
                <div class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex">
                  <span tabIndex="-1" class="px-1" style="background-color:#ffffff;">$</span>
                  <input type="numeric" class="w-full outline-none"
                  onkeypress="if(this.value.length==10) return false;return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" 
                  v-money="money" v-model.lazy="monthly_income" required>
                </div>
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Liquidez mensual:</label>
                <div class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex">
                  <span tabIndex="-1" class="input-group-addon px-1" style="background-color:#ffffff;">$</span>
                  <input type="numeric" class="w-full outline-none"
                  onkeypress="if(this.value.length==10) return false;return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                  v-money="money" v-model.lazy="liquidity" required>
                </div>
              </div>
            </div>
            
            <div>
              <div class="form-group">
                <label class="labelTitle">Importe de ahorro:</label>
                <div class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex">
                  <span tabIndex="-1" class="input-group-addon px-1" style="background-color:#ffffff;">$</span>
                  <input type="numeric" class="w-full outline-none"
                  v-model.lazy="this.saving_amount" required>
                </div>
              </div>
            </div>
            <div class="">
              <div class="form-group">
                <label class="labelTitle">Plazo:</label>
                <div class="input-group" style="margin-top:-5px;">
                    <input type="radio" v-bind:value="true" name="rbPlazo" v-model="undefinedTerm" v-on:click="admission_request_update.admission_information.saving_registration_attributes.number_of_contribution = 0" required /> Indefinido
                    <!-- <input type="radio" v-bind:value="false" name="rbPlazo" v-model="undefinedTerm" v-on:click="admission_request_update.admission_information.saving_registration_attributes.number_of_contribution = 0" style="margin-left:10px"/> Otro -->
                </div>
              </div>
            </div>
             <div class="">
              <div class="form-group">
                <label class="labelTitle">Periodo de pago:</label>
                <div class="input-group" style="margin-top:-5px;">
                  <label>{{admission_request_update.admission_information.saving_registration_attributes.payment_frecuency.toString().toUpperCase()}}</label>
                </div>
              </div>
            </div>
            <!-- <div>
              <div class="form-group" v-if="admission_request_update.admission_information.saving_registration_attributes.number_of_contribution == '1'">
                <label class="labelTitle">Inicio del Ahorro:</label>
                <input type="text" id="datepickerStartDateSaving" class="form-control inputText" style="z-index:1" hidden
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="start_date_saving" @change="getYearsSaving(start_date_saving, end_date_saving)" required>
              </div>
            </div>
            <div>
              <div class="form-group" v-if="admission_request_update.admission_information.saving_registration_attributes.number_of_contribution == '1'">
                <label class="labelTitle">Final del Ahorro:</label>
                <input type="text" id="datepickerEndDateSaving" class="form-control inputText" style="z-index:1" hidden
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="end_date_saving" required>
              </div>
            </div> -->
            <div class="" v-show="false">
              <div class="form-group" v-if="!undefinedTerm">
                <label class="labelTitle">Plazo de ahorro:</label>
                <div class="input-group" style="width:210px">
                  <input type="number" class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex"
                  min="1" maxlength="2" pattern="[0-9]" id="term" oninput="validity.valid||(value='');" v-model="admission_request_update.admission_information.saving_registration_attributes.number_of_contribution" required>
                  <span tabIndex="-1" class="input-group-addon" style="background-color:#ffffff; height:30px; margin-top:0px;">Años</span>
                </div>
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20 gap-y-5 my-5">
            <div class="form-group">
              <label class="labelTitle">Método de descuento:</label>
              <select class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex" ref="stateSelected" required v-model="admission_request_update.admission_information.saving_registration_attributes.payment_method" v-on:change="changeDiscountMethod()">
                <option value="NÓMINA">NÓMINA</option>
                <option value="DOMICILIACIÓN">DOMICILIACIÓN</option>
              </select>
            </div>
          </div>
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20 gap-y-5 my-5" v-if="admission_request_update.admission_information.saving_registration_attributes.payment_method == 'DOMICILIACIÓN'">
            <div class="form-group">
              <label class="labelTitle">Tipo de cuenta:</label>
                <div class="input-group">
                    <input type="radio" value="cuenta clabe" name="rbTipoCuenta" v-model="keyType" v-on:change="changeBankAccountAttributes('account_type',keyType)" required /> Cuenta Clabe
                    <input type="radio" value="numero de tarjeta" name="rbTipoCuenta" v-model="keyType" v-on:change="changeBankAccountAttributes('account_type',keyType)" style="margin-left:10px"/> Número de tarjeta
                </div>
            </div>
            <div class="form-group">
              <label class="labelTitle">Banco:</label>
              <select class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex" ref="stateSelected" v-on:change="changeBankAccountAttributes('bank_name',bank)" required v-model="bank">
                <option :value="item.name" v-for="item in list_banks" :key="item.id">{{item.name}}</option>
              </select>
            </div>
            <div class="form-group" v-if="keyType == 'numero de tarjeta'">
              <label class="labelTitle">Número de Tarjeta:</label>
  <input type="text" class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex" v-maska="this.admission_request_update.admission_information.bank_account_attributes[0].bank_name != 'AMERICAN EXPRESS'?'####-####-####-####':'#####-#####-#####'" v-on:change="changeBankAccountAttributes('account_number',cardNumber)"
              v-model="cardNumber" required>
            </div>
            <div class="form-group" v-if="keyType == 'cuenta clabe'">
              <label class="labelTitle">Cuenta Clabe:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex" v-maska="'##################'" v-on:change="changeBankAccountAttributes('account_number',keyAccount)" v-model="keyAccount" required>
            </div>
            <div v-if="keyType == 'numero de tarjeta'">
              <label class="labelTitle">Fecha de expiración:</label>
              <div class="grid grid-cols-2 gap-4">
                <div class="w-full">
                  <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 px-5 focus:border-cherryColor flex" v-on:change="changeBankAccountAttributes('expiration_month',expirationMonth)" required
                    v-model="expirationMonth">
                    <option value="" selected="true" disabled="disabled">MM</option>
                    <option v-for="(item, key) in list_months" :value="item" :key="key">{{ item }}</option>
                  </select>
                </div>
                <div class="w-full">
                  <select name="select" class="outline-none shadow-md border-2 rounded-lg py-2 px-5 focus:border-cherryColor flex" v-on:change="changeBankAccountAttributes('expiration_year',expirationYear)" required
                    v-model="expirationYear">
                    <option value="" selected="true" disabled="disabled">AA</option>
                    <option v-for="(item, key) in list_years" :value="item" :key="key">{{ item }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-4 grid grid-cols-1 gap-x-20">
            <div class="md:col-span-4">
              <button class="buttonSave" style="float:right;">Guardar</button>            
              <input type="button" value="Cancelar" class="buttonWithoutBackground" @click="cancelInfoEconomica()" style="float:right;margin-right:10px;">
            </div>
          </div>
        </form>
      </template>
    </Modal>

    <Modal v-show="showModalBeneficiarios" :heightContainer="'70%'" :widthContainer="'60%'">
      <template v-slot:header>
        <p style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;">Datos de Beneficiarios</p>
      </template>
      <template v-slot:body>
        <form @submit="addBeneficiarie">
          <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-x-20">
            <div>
              <div class="form-group">
                <label class="labelTitle">Apellido Paterno:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex"
                minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{3,30}" id="paternal_name_beneficiarie" v-model="paternal_name_beneficiarie">
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Apellido Materno:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex"
                minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{3,30}" id="maternal_name_beneficiarie" v-model="maternal_name_beneficiarie">
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Primer Nombre:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex"
                minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{3,30}" id="name_beneficiarie" v-model="name_beneficiarie" required>
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Segundo Nombre:</label>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex"
                minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{3,30}" id="middle_name_beneficiarie" v-model="middle_name_beneficiarie">
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Fecha de Nacimiento:</label>
                <!-- <input type="text" id="datepickerBirthDateBeneficiarie" class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex" style="z-index:1"
                  minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="birthday_beneficiarie" required> -->
                <VueDatePickerNew
                  teleport-center
                  :max-date="today"
                  :clearable="false"
                  v-model="birthday_beneficiarie"
                  :enable-time-picker="false"
                  dark
                  :flow="['year', 'month', 'calendar']"
                  format="dd/MM/yyyy"
                  model-type="dd/MM/yyyy"
                  locale="es"
                  cancelText="Cancelar"
                  selectText="Seleccionar"
                />
              </div>
            </div>
            <div>
              <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px">
                <label class="labelTitle">Porcentaje:</label>
                <div class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex" style="width:210px">
                  <input type="number" class=" w-5/6"  style="outline:none"
                  onKeyPress="if(this.value.length==3) return false;" v-on:change="percentage_beneficiarie=changePercentage(percentage_beneficiarie)" v-model="percentage_beneficiarie" required>
                  <span tabIndex="-1" class="input-group-addon w-1/6" style="background-color:#ffffff; height:30px; margin-top:0px;">%</span>
                </div>
              </div>
            </div>
            <div>
              <div class="form-group">
                <label class="labelTitle">Parentesco:</label> <br>
                <select class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 focus:border-cherryColor flex" required v-model="relationship_beneficiarie">
                  <option value="CONYUGE">Conyuge</option>
                  <option value="HIJO">Hijo</option>
                  <option value="PADRE">Padre</option>
                  <option value="MADRE">Madre</option>
                  <option value="HERMANO">Hermano</option>
                  <option value="ABUELO">Abuelo</option>
                  <option value="TIO">Tio</option>
                  <option value="PAREJA">Pareja</option>
                  <option value="SOBRINO">Sobrino</option>
                </select>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-3 gap-x-20">
            <div class="col-span-3">
              <button class="buttonSave" style="margin-left:10px;float:right;">{{ id_beneficiarie==0 ? 'Agregar' : 'Editar' }}</button>
              <input type="button" v-show="id_beneficiarie!=0" value="Cancelar Edición" class="buttonWithoutBackground" @click="cancelModifyBeneficiarie()" style="margin-left:10px;float:right;">
            </div>
          </div>
          <div class="grid grid-cols-3 gap-x-20" style="margin-top:10px;">
            <div class="col-span-3">
              <table class="width200">
                <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Apellidos</th>
                  <th>Fec. de Nac</th>
                  <th>%</th>
                  <th>Parentesco</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="obj in list_beneficiaries" :key="obj.id">
                    <td style=""><p style="text-transform: uppercase;">{{obj.name+" "+obj.middle_name}}</p></td>
                    <td style=""><p style="text-transform: uppercase;">{{obj.paternal_name+" "+obj.maternal_name}}</p></td>
                    <td style=""><p>{{ this.datetimeValidatorObjectClass.normalizeDateFormat(obj.birthday) }}</p></td>
                    <td style=""><p>{{obj.percentage}}%</p></td>
                    <td style=""><p style="text-transform: uppercase;">{{obj.relationship}}</p></td>
                    <td style="">
                      <img height="35" width="35" src="../../../assets/Cuenta/edit-icon.svg" v-on:click="modifyBeneficiarie(obj.id)" style="float:left;margin-right:5px;cursor: pointer;">
                      <img height="35" width="35" src="../../../assets/Cuenta/delete-icon.svg" v-on:click="removeBeneficiarie(obj.name+obj.middle_name+obj.paternal_name+obj.maternal_name,obj.birthday,obj.percentage, false)" style="cursor: pointer;">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="grid grid-cols-4 gap-x-20">
            <div class="col-span-4">
              <input type="button" value="Guardar" class="buttonSave" @click="updateDatosBeneficiarios()" style="float:right;">       
              <input type="button" value="Cancelar" class="buttonWithoutBackground" @click="cancelBeneficiarios()" style="float:right;margin-right:10px;">
            </div>
          </div>
        </form>
      </template>
    </Modal>
    
    <div class="2xl:col-span-6 md:col-span-6">
      <p style="font-size:20px;color:#174A84;font-weight:700;font-family:Lato;color:grey">Revisión de Solicitudes de Ingreso - {{ $filters.formatCamelize(`${admission_request.admission_information.paternal_name} ${admission_request.admission_information.maternal_name} ${admission_request.admission_information.name} ${admission_request.admission_information.middle_name}`) }}, R.F.C. {{ admission_request.admission_information.rfc }}</p>
      <div v-if="admission_request.promoter_name" class="mt-3">
        <b style="font-size: 20px; color: grey; font-weight: 700; font-family: Lato;">Referido por: {{ $filters.formatCamelize(admission_request.promoter_name) }}</b>
      </div>
    </div>
    <div class="col-span-6  flex justify-start md:justify-end" style="text-align:left;">
      <div class="grid gap-3 grid-cols-1 md:flex-wrap md:grid md:grid-cols-3">
        <div v-if="!(requestStatus == 'aceptada' || requestStatus == 'rechazada')">
          <input type="button" class="buttonWithoutBackground" value="Cancelar Solicitud de Ingreso" style="" v-on:click="showModalCancelRequest = true"/>
        </div>
        <div v-if="!(requestStatus == 'aceptada' || requestStatus == 'rechazada')">
          <input type="button" class="buttonWithoutBackground" value="Rechazar Solicitud de Ingreso" style="" v-on:click="showModalRejectRequest = true"/>
        </div>
        <div v-if="!(requestStatus == 'aceptada' || requestStatus == 'rechazada')">
          <input type="button" class="buttonSave" value="Aceptar Solicitud de Ingreso" style="" v-on:click="acceptRequest()"/>
        </div>
      </div>
    </div>
    <div v-if="requestStatus == 'rechazada'"  class="2xl:col-span-2 md:col-span-3 2xl:text-right md:text-right">
      <p style="font-size:20px;font-weight:700;font-family:Lato;color:#C10502;">SOLICITUD RECHAZADA</p>
    </div>
    <div v-if="requestStatus == 'aceptada'"  class="2xl:col-span-2 md:col-span-3 2xl:text-right md:text-right">
      <p style="font-size:20px;font-weight:700;font-family:Lato;color:#10C102;">SOLICITUD ACEPTADA</p>
    </div>
  </div>

  <div class="lg:grid lg:grid-cols-4 lg:grid-flow-col grid grid-cols-1 gap-10 p-5" style="">
    <div class="col-span-2" style="text-align:left;">
      <div class="2xl:grid 2xl:grid-cols-12 md:grid md:grid-cols-2 grid grid-cols-1 gap-4">
        <div class="2xl:col-span-3">
          <input type="button" class="buttonBorderBlur" value="Datos Personales" style=""
            v-bind:style="{
              backgroundColor: buttonSelected == 'personalInfo' ? activeColorBackground : inactiveColorBackground, 
              color: buttonSelected == 'personalInfo' ? activeColorText : inactiveColorText
            }"
            v-on:click="changeButtonSelected('personalInfo')"/>
        </div>
        
        <div class="2xl:col-span-3" v-if="['businessman','educator','student','retired','health_worker'].includes(clientClassification)">
          <input type="button" class="buttonBorderBlur" value="Información Laboral" style=""
          v-bind:style="{
            backgroundColor: buttonSelected == 'workInfo' ? activeColorBackground : inactiveColorBackground, 
            color: buttonSelected == 'workInfo' ? activeColorText : inactiveColorText
          }"
          v-on:click="changeButtonSelected('workInfo')"/>
        </div>
        <div class="2xl:col-span-3" v-if="clientClassification == 'cabsa_employee'">
          <input type="button" class="buttonBorderBlur" value="Información Laboral" style=""
          v-bind:style="{
            backgroundColor: buttonSelected == 'workInfoMember' ? activeColorBackground : inactiveColorBackground, 
            color: buttonSelected == 'workInfoMember' ? activeColorText : inactiveColorText
          }"
          v-on:click="changeButtonSelected('workInfoMember')"/>
        </div>
        <div class="2xl:col-span-4">
          <input type="button" class="buttonBorderBlur" value="Información Económica y Ahorro" style=""
          v-bind:style="{
            backgroundColor: buttonSelected == 'economicalInfo' ? activeColorBackground : inactiveColorBackground, 
            color: buttonSelected == 'economicalInfo' ? activeColorText : inactiveColorText
          }"
          v-on:click="changeButtonSelected('economicalInfo')"/>
        </div>
        <div class="2xl:col-span-2">
          <input type="button" class="buttonBorderBlur" value="Beneficiarios" style=""
          v-bind:style="{
            backgroundColor: buttonSelected == 'beneficiareInfo' ? activeColorBackground : inactiveColorBackground, 
            color: buttonSelected == 'beneficiareInfo' ? activeColorText : inactiveColorText
          }"
          v-on:click="changeButtonSelected('beneficiareInfo')"/>
        </div>
      </div>
    </div>
    <div class="col-span-2" id="infoContainer">
      Número de solicitud: {{this.getRequestIdFromParams()}}
      <div style="height:433px;overflow-y: scroll;">
        <div id="personalInfo">
          <div class="" style="padding:10px;background:#fff;border-width: 1px;border-style: solid;border-color:#FFC300;border-radius:7px;">
            <p style="font-size:16px;color:#810042;font-family:lato;font-weight:700;">Datos Personales</p>
            <div class="grid grid-cols-1">
              <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-4">
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">{{ $filters.formatCamelize(`${admission_request.admission_information.paternal_name} ${admission_request.admission_information.maternal_name} ${admission_request.admission_information.name} ${admission_request.admission_information.middle_name}`) }}</p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Género: <span style="font-weight:400">{{ $filters.formatCamelize(admission_request.admission_information.gender) }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Fecha nac: <span style="font-weight:400">{{ this.datetimeValidatorObjectClass.normalizeDateFormat(admission_request.admission_information.birthday) }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">R.F.C.: <span style="font-weight:400">{{ admission_request.admission_information.rfc }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">C.U.R.P.: <span style="font-weight:400">{{ admission_request.admission_information.curp }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Identificación: <span style="font-weight:400">{{ `${admission_request.admission_information.identification_type}, No. ${admission_request.admission_information.identification_number}` }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Teléfono: <span style="font-weight:400">{{ $filters.formatPhone(phone_datos_personales) }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Celular: <span style="font-weight:400">{{ $filters.formatPhone(cellphone_datos_personales) }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Correo electrónico: <span style="font-weight:400">{{ email_datos_personales }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Lugar de nacimiento: <span style="font-weight:400">{{admission_request.admission_information.city_birth != '' ? $filters.formatCamelize(admission_request.admission_information.city_birth + ',') : ''}}  {{ $filters.formatCamelize(admission_request.admission_information.municipality) }}, {{ $filters.formatCamelize(admission_request.admission_information.state) }}, {{ $filters.formatCamelize(admission_request.admission_information.country_birth) }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Estado civil: <span style="font-weight:400">{{ $filters.formatCamelize(admission_request.admission_information.marital_status) }}</span></p>
                </div>
              </div>
              <div>
                <div v-show="admission_request_review.admission_request_review.review_information.personal_information.validated">
                  <p style="vertical-align:bottom;float:right;font-family:lato;color:#85c875;font-weight:700;font-size:13px;">Validado</p>
                  <img src="../../../assets/Analyst/validado.svg" style="float:right;margin-right:5px;" @click="validateInfo('personal_information', false)" alt="">
                </div>
                <div v-show="!admission_request_review.admission_request_review.review_information.personal_information.validated && (requestStatus != 'aceptada' && requestStatus != 'rechazada')">
                  <input type="button" class="buttonSave" value="Validar" style="float: right;"
                      v-on:click="validateInfo('personal_information', true)"/>
                  <input type="button" class="buttonWithoutBackground" value="Editar" style="float: right;margin-right:10px;"
                        v-on:click="openModalDatosPersonales()"/>
                </div>
              </div>
            </div>
          </div>
          <div class="" style="padding:10px;background:#fff;border-width: 1px;border-style: solid;border-color:#FFC300;border-radius:7px; margin-top:25px;">
            <p style="font-size:16px;color:#810042;font-family:lato;font-weight:700;">Datos de Dirección</p>
            <div class="grid grid-cols-1">
              <div class="md:grid md:grid-cols-2 grid grid-cols-1 gap-4">
                <div class="md:col-span-2">
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:400;">Calle {{ $filters.formatCamelize(admission_request.admission_information.adress_attributes.street)}}. Num Int. {{admission_request.admission_information.adress_attributes.int_number}}. Num Ext. {{admission_request.admission_information.adress_attributes.ext_number}}, Colonia {{ $filters.formatCamelize(admission_request.admission_information.adress_attributes.suburb) }} C.p. {{admission_request.admission_information.adress_attributes.postal_code}}.</p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">{{ $filters.formatCamelize(`Entre Calle ${admission_request.admission_information.adress_attributes.between_street_one} y Calle ${admission_request.admission_information.adress_attributes.between_street_two}.`) }}</p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">{{ `${admission_request.admission_information.adress_attributes.city == "N/A" ? "" : $filters.formatCamelize(admission_request.admission_information.adress_attributes.city+',') }` }} {{ $filters.formatCamelize(`${admission_request.admission_information.adress_attributes.municipality}, ${admission_request.admission_information.adress_attributes.state}, ${admission_request.admission_information.adress_attributes.country}`) }}</p>
                </div>
                <div v-if="!['health_worker'].includes(clientClassification)">
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Tipo de Vivienda: <span style="font-weight:400">{{ $filters.formatCamelize(admission_request.admission_information.residence_status) }}</span></p>
                </div>
                <div v-if="!['health_worker'].includes(clientClassification)">
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Inicio de Residencia: <span style="font-weight:400">{{ this.datetimeValidatorObjectClass.normalizeDateFormat(admission_request.admission_information.adress_attributes.residence_start) }}</span></p>
                </div>
              </div>
              <div>
                <div v-show="admission_request_review.admission_request_review.review_information.adress_information.validated">
                  <p style="vertical-align:bottom;float:right;font-family:lato;color:#85c875;font-weight:700;font-size:13px;">Validado</p>
                  <img src="../../../assets/Analyst/validado.svg" style="float:right;margin-right:5px;" @click="validateInfo('adress_information', false)" alt="">
                </div>
                <div v-show="!admission_request_review.admission_request_review.review_information.adress_information.validated  && (requestStatus != 'aceptada' && requestStatus != 'rechazada')">
                  <input type="button" class="buttonSave" value="Validar" style="float: right;"
                      v-on:click="validateInfo('adress_information', true)"/>
                      
                  <input type="button" class="buttonWithoutBackground" value="Editar" style="float: right;margin-right:10px;"
                        v-on:click="showModalDatosPersonalesDireccion = true"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="workInfo" v-if="['businessman','educator','student','retired','health_worker'].includes(clientClassification)">
          <div class="" style="padding:10px;background:#fff;border-width: 1px;border-style: solid;border-color:#FFC300;border-radius:7px; margin-top:25px;">
            <p style="font-size:16px;color:#810042;font-family:lato;font-weight:700;">Datos Laborales</p>
            <div class="grid grid-cols-1">
              <div class="md:grid md:grid-cols-2 grid grid-cols-1 gap-4">
                <div v-if="admission_request_update.admission_information.work_info_attributes.laboral_status == 'ACTIVO'">
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">C.P.: <span style="font-weight:400">{{ admission_request.admission_information.workplace_attributes.adress_attributes.postal_code }}</span></p>
                </div>
                <div  v-if="['businessman','educator','student','retired'].includes(clientClassification)">
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Estatus: <span style="font-weight:400">{{ admission_request_update.admission_information.work_info_attributes.laboral_status }}</span></p>
                </div>
                <div v-if="false">
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">C.T.: <span style="font-weight:400">{{ addUpperCase(admission_request_update.admission_information.workplace_attributes.center_key) }}</span></p>
                </div>
                <div v-if="false">
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Clave Presupuestal: <span style="font-weight:400">{{ addUpperCase(admission_request_update.admission_information.work_info_attributes.employee_number[0]) }}</span></p>
                </div>
                <div v-if="admission_request_update.admission_information.work_info_attributes.laboral_status == 'ACTIVO'">
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">{{ `${admission_request.admission_information.workplace_attributes.adress_attributes.city == 'N/A' ? "" : $filters.formatCamelize(admission_request.admission_information.workplace_attributes.adress_attributes.city+',')}`}} {{$filters.formatCamelize(`${admission_request.admission_information.workplace_attributes.adress_attributes.state}, ${admission_request.admission_information.workplace_attributes.adress_attributes.country}`) }}</p>
                </div>
                <div v-if="admission_request_update.admission_information.work_info_attributes.laboral_status == 'ACTIVO'">
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Fecha de ingreso: <span style="font-weight:400">{{ this.datetimeValidatorObjectClass.normalizeDateFormat(admission_request.admission_information.work_info_attributes.active_since) }}</span></p>
                </div>
              </div>
              <div>
                <div v-show="admission_request_review.admission_request_review.review_information.workplace_information.validated">
                  <p style="vertical-align:bottom;float:right;font-family:lato;color:#85c875;font-weight:700;font-size:13px;">Validado</p>
                  <img src="../../../assets/Analyst/validado.svg" style="float:right;margin-right:5px;" @click="validateInfo('workplace_information', false)" alt="">
                </div>
                <div v-show="!admission_request_review.admission_request_review.review_information.workplace_information.validated  && (requestStatus != 'aceptada' && requestStatus != 'rechazada')">
                  <input type="button" class="buttonSave" value="Validar" style="float: right;"
                      v-on:click="validateInfo('workplace_information', true)"/>
                  <input type="button" class="buttonWithoutBackground" value="Editar" style="float: right;margin-right:10px;"
                        v-on:click="openModalDatosLaborales('educator')"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="workInfoMember" v-if="clientClassification == 'cabsa_employee'">
          <div class="" style="padding:10px;background:#fff;border-width: 1px;border-style: solid;border-color:#FFC300;border-radius:7px; margin-top:25px;">
            <p style="font-size:16px;color:#810042;font-family:lato;font-weight:700;">Datos Laborales</p>
            <div class="grid grid-cols-1">
              <div class="md:grid md:grid-cols-2 grid grid-cols-1 gap-4">
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:700;">C.P.: <span style="font-weight:400">{{ admission_request.admission_information.workplace_attributes.adress_attributes.postal_code }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:700;">Ocupación Principal: <span style="font-weight:400">{{ $filters.formatCamelize(admission_request.admission_information.work_info_attributes.principal_occupation) }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:700;">Clave/Número de empleado: <span style="font-weight:400">{{admission_request.admission_information.work_info_attributes.employee_number[0]}}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:700;">{{ `${admission_request.admission_information.workplace_attributes.adress_attributes.city == 'N/A' ? "" : $filters.formatCamelize(admission_request.admission_information.workplace_attributes.adress_attributes.city+',') }` }} {{ $filters.formatCamelize(`${admission_request.admission_information.workplace_attributes.adress_attributes.state}, ${admission_request.admission_information.workplace_attributes.adress_attributes.country}`) }}</p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:700;">Fecha de ingreso: <span style="font-weight:400">{{ this.datetimeValidatorObjectClass.normalizeDateFormat(admission_request.admission_information.work_info_attributes.active_since) }}</span></p>
                </div>
              </div>
              <div>
                <div v-show="admission_request_review.admission_request_review.review_information.workplace_information.validated">
                  <p style="vertical-align:bottom;float:right;font-family:lato;color:#85c875;font-weight:700;font-size:13px;">Validado</p>
                  <img src="../../../assets/Analyst/validado.svg" style="float:right;margin-right:5px;" @click="validateInfo('workplace_information', false)" alt="">
                </div>
                <div v-show="!admission_request_review.admission_request_review.review_information.workplace_information.validated  && (requestStatus != 'aceptada' && requestStatus != 'rechazada')">
                  <input type="button" class="buttonSave" value="Validar" style="float: right;"
                      v-on:click="validateInfo('workplace_information', true)"/>
                  <input type="button" class="buttonWithoutBackground" value="Editar" style="float: right;margin-right:10px;"
                        v-on:click="openModalDatosLaborales('cabsa_employee')"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div id="economicalInfo">
          <div class="" style="padding:10px;background:#fff;border-width: 1px;border-style: solid;border-color:#FFC300;border-radius:7px; margin-top:25px;">
            <p style="font-size:16px;color:#810042;font-family:lato;font-weight:700;">Información Económica y Ahorro</p>
            <div class="grid grid-cols-1">
              <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-4">
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Ingreso Bruto Mensual: <br /><span style="font-weight:400">{{ $filters.formatCurrency(admission_request.admission_information.salary_info_attributes.monthly_income) }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Liquidez Mensual: <br /><span style="font-weight:400">{{ $filters.formatCurrency(admission_request.admission_information.salary_info_attributes.liquidity) }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Periodo de Ingreso: <br /><span style="font-weight:400">{{admission_request.admission_information.salary_info_attributes.income_frecuency}}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Importe de Ahorro: <br /><span style="font-weight:400">{{ $filters.formatCurrency(admission_request.admission_information.saving_registration_attributes.amount) }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Método de descuento: <br /><span style="font-weight:400">{{ $filters.formatCamelize(admission_request_update.admission_information.saving_registration_attributes.payment_method) }}</span></p>
                </div>
                <div>
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Plazo: <br /><span style="font-weight:400">{{ undefinedTerm == true ? 'Indefinido' : 'Otro' }}</span></p>
                </div>
                <div v-show="false">
                  <p style="font-size:12px;color:grey;font-family:lato;font-weight:900;">Plazo de Ahorro: <br /><span style="font-weight:400"> {{ admission_request.admission_information.saving_registration_attributes.number_of_contribution }}</span></p>
                </div>
              </div>
              <div>
                <div v-show="admission_request_review.admission_request_review.review_information.economical_information.validated">
                  <p style="vertical-align:bottom;float:right;font-family:lato;color:#85c875;font-weight:700;font-size:13px;">Validado</p>
                  <img src="../../../assets/Analyst/validado.svg" style="float:right;margin-right:5px;" @click="validateInfo('economical_information', false)" alt="">
                </div>
                <div v-show="!admission_request_review.admission_request_review.review_information.economical_information.validated  && (requestStatus != 'aceptada' && requestStatus != 'rechazada')">
                  <input type="button" class="buttonSave" value="Validar" style="float: right;"
                      v-on:click="validateInfo('economical_information', true)"/>
                  <input type="button" class="buttonWithoutBackground" value="Editar" style="float: right;margin-right:10px;"
                        v-on:click="openModalInfoEconomicaYAhorro()"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="beneficiareInfo">
          <div class="" style="padding:10px;background:#fff;border-width: 1px;border-style: solid;border-color:#FFC300;border-radius:7px; margin-top:25px;">
            <p style="font-size:16px;color:#810042;font-family:lato;font-weight:700;">Beneficiarios</p>
            <div class="grid grid-cols-1">
              <div class="md:grid md:grid-cols-6 grid grid-cols-1 gap-x-10" v-for="item in admission_request.admission_information.beneficiaries_attributes" :key="'B'+item.id">
                <div class="md:col-span-3">
                  <p style="font-size:12px;color:#810042;font-family:lato;font-weight:900;">{{ $filters.formatCamelize(`${item.name} ${item.middle_name} ${item.paternal_name} ${item.maternal_name}`) }}</p>
                </div>
                <div>
                  <p style="font-size:12px;color:#810042;font-family:lato;font-weight:900;">{{ this.datetimeValidatorObjectClass.normalizeDateFormat(item.birthday) }}</p>
                </div>
                <div>
                  <p style="font-size:12px;color:#810042;font-family:lato;font-weight:900;">{{ $filters.formatCamelize(item.relationship) }}</p>
                </div>
                <div>
                  <p style="font-size:12px;color:#810042;font-family:lato;font-weight:900;">{{ item.percentage }}%</p>
                </div>
              </div>
              <div>
                <div v-show="admission_request_review.admission_request_review.review_information.beneficiaries_information.validated">
                  <p style="vertical-align:bottom;float:right;font-family:lato;color:#85c875;font-weight:700;font-size:13px;">Validado</p>
                  <img src="../../../assets/Analyst/validado.svg" style="float:right;margin-right:5px;" @click="validateInfo('beneficiaries_information', false)" alt="">
                </div>
                <div v-show="!admission_request_review.admission_request_review.review_information.beneficiaries_information.validated  && (requestStatus != 'aceptada' && requestStatus != 'rechazada')">
                  <input type="button" class="buttonSave" value="Validar" style="float: right;"
                      v-on:click="validateInfo('beneficiaries_information', true)"/>
                  <input type="button" class="buttonWithoutBackground" value="Editar" style="float: right;margin-right:10px;"
                        v-on:click="showModalBeneficiarios = true"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DocumentControlList
      ref="documentControlRef" 
      :documentsList="documentsControlListRequired" 
      @sendMissingDocuments="send($event)"
      :key="refresh" />
    
    <div class="lg:col-span-1 col-span-2" style="padding:10px;background:#fff;border-width: 1px;border-style: solid;border-color:#FFC300;border-radius:7px;overflow-y: scroll;height: calc(62vh);min-height:62vh;">
      <p style="font-size:16px;color:#810042;font-family:lato;font-weight:700;">Observaciones Adicionales</p>
      <div class="flex mb-8">
        <input :disabled="requestStatus == 'aceptada' || requestStatus == 'rechazada'" type="button" :class="{'border w-64 m-1 bg-transparent rounded-lg px-4 border-green font-green': !tabs.history, 'buttonSave w-64 m-1': tabs.history}" value="Historial de Observaciones" style="margin-top:10px;float:right;margin-right:5px;"
                v-on:click="tabs.history = true; tabs.message = false;" />
        <input :disabled="requestStatus == 'aceptada' || requestStatus == 'rechazada'" type="button" :class="{'border w-64 m-1 bg-transparent rounded-lg px-4 border-green font-green': !tabs.message, 'buttonSave w-64 m-1': tabs.message}" value="Historial de Correos" style="margin-top:10px;float:right;margin-right:5px;"
                v-on:click="tabs.message = true; tabs.history = false;" />
      </div>
      <!-- Tab History -->
      <div v-if="tabs.history" class="grid grid-cols-1 gap-4" style="font-family:Lato;">
        <div class="md:grid md:grid-cols-12 grid grid-cols-1 gap-4" v-for="item in list_observation_history.slice(0, 5)" :key="'C'+item.id"> 
            <div class="col-span-5" style="background:#fff;border-width: 1px;border-style: solid;border-color:#85C875;border-radius:7px;box-shadow: 0 0 5px #9ecaed;color:#fff;background-color:#66bb6a;padding:2px;display:flex;align-items:center;text-align:center;">
              <p style="font-size:13px;font-weight:700;width:100%;">{{ $filters.formatCurrentDateName(item.added_comment_date) }} <br /> <span style="font-size:10px;width:100%;">{{ $filters.formatCurrentDateHour(item.added_comment_date)}} <br />  Hrs</span></p>
            </div>
            <div class="col-span-7" style="background:#fff;border-width: 1px;border-style: solid;border-color:#85C875;border-radius:7px;box-shadow: 0 0 5px #9ecaed;padding:5px;">
              <p style="background-color: #f98b04;color:#fff;font-size:15px;font-weight:bold;border-radius:5px;padding:2px 5px 2px 5px;">Confirmar Datos de socio</p>
              <p class="break-words" style="font-size:13px;font-weight:700;color:#174A84;font-size:12px;">{{ item.comment }}</p>
              <p style="font-size:13px;font-weight:700;color:#174A84;font-size:12px;text-align:right;">{{ $filters.formatCamelize(`${name_associate} ${paternal_name_associate}`) }}</p>
            </div>
        </div>
      </div>
      <div v-if="!(requestStatus == 'aceptada' || requestStatus == 'rechazada') && tabs.history" class="grid grid-cols-2 flex" style="font-family:Lato;">
          <div class="grid">
            <input :disabled="requestStatus == 'aceptada' || requestStatus == 'rechazada'" type="button" class="buttonSave w-auto m-1 " value="Ver historial" style="margin-top:10px;float:right;margin-right:5px;"
                v-on:click="showModalObservationHistory = true" />
          </div>
          <div class="grid">
            <input :disabled="requestStatus == 'aceptada' || requestStatus == 'rechazada'" type="button" class="buttonSave w-auto m-1 " value="Añadir observación" style="margin-top:10px;float:right;"
                  v-on:click="showModalObservationHistoryAdd = true"/>
          </div>
      </div>
      <!-- End Tab History -->
      <!-- Tab Message -->
        <div v-if="tabs.message" class="grid grid-cols-1 gap-4" style="font-family:Lato;">
          <div @click="showModalHistory = true; itemMailHistory = index;" class="md:grid md:grid-cols-12 grid grid-cols-1 gap-4" v-for="(item, index) in listMailHistory" :key="'C'+index"> 
              <div class="col-span-2" style="background:#fff;border-width: 1px;border-style: solid;border-color:#85C875;border-radius:7px;box-shadow: 0 0 5px #9ecaed;color:#fff;background-color:#66bb6a;padding:2px;display:flex;align-items:center;text-align:center;">
                <p style="font-size:13px;font-weight:700;width:100%;">{{ $filters.formatCurrentDateName(item.created_at) }} <br /> <span style="font-size:10px;width:100%;">{{ $filters.formatCurrentDateHour(item.created_at)}} <br />  Hrs</span></p>
              </div>
              <div class="col-span-10" style="background:#fff;border-width: 1px;border-style: solid;border-color:#85C875;border-radius:7px;box-shadow: 0 0 5px #9ecaed;padding:5px;">
                <p :class="{'bg-active': listMailHistory.length-1 == index, 'bg-inactive': listMailHistory.length-1 != index}" style="color:#fff;font-size:15px;font-weight:bold;border-radius:5px;padding:2px 5px 2px 5px;">Documentos faltantes</p>
                <p v-show="documents.status" v-for="(documents, i) in item.body.missing_documents" :key="i" style="font-size:13px;font-weight:700;color:#174A84;font-size:12px;">- {{ documents.name }}</p>
                <p style="font-size:13px;font-weight:700;color:#174A84;font-size:12px;text-align:right;">Enviado por: {{ $filters.formatCamelize(`${name_associate} ${paternal_name_associate}`) }}</p>
              </div>
          </div>
        </div>

      <!-- End Tab History -->
    </div>
    <div class="row-span-3 col-span-2" style="background:#fff;border-width: 1px;border-style: solid;border-color:#FFC300;border-radius:7px;">
      <div style="padding:10px;">
        <p class="py-2" style="font-size:20px;font-weight:700;font-family:Lato;color:#810042;">Documentos del socio</p>
        <div style="" class="md:grid md:grid-cols-2">
          <div class="form-group">
            <input type="button" class="buttonSave" value="Cargar documentos faltantes" v-on:click="redirect('/analyst/cuenta/cargar-documentos-dinamica/'+getRequestIdFromParams())"/>
          </div>
        </div>
        <p class="py-2" style="font-size:14px;font-weight:700;font-family:Lato;color:#810042;">En caso de no visualizar el documento, puede descargarlo desde opción "Descargar documento"</p>

      </div>

      <div style="margin-top:10px;">
        <DocumentsViewer :document_list="documentsList" :document_text="recorderScript" :classes="documentsViewerClasses" v-show="!documentsIsLoading"/>
        <p v-show="documentsIsLoading" class="mx-4"> Cargando documentos... </p>
      </div>
    </div>
  </div>
  <div v-if="(!(requestStatus == 'rechazada') || (requestStatus == 'aceptada' && confirmedDocuments.total == confirmedDocuments.confirmed ))" class="grid grid-cols-1 my-10">
    <div class="mx-auto">
      <input type="button" class="buttonSave" value="Guardar Revisión" v-on:click="createReview(true)"/>
    </div>
  </div>
</div>
</template>

<script>

import Loading from '@/components/Loading/VueLoading.vue'
import Modal from '@/components/Modal/Modal.vue'
import moment from "moment";
import {VMoney} from 'v-money'
// import "@/assets/css/tailwind.css";
import Video from '@/classes/VideoRecorder';
import {getTokenDecoden} from "@/helpers/tokenauth";
import AdmissionRequest from '@/classes/AdmissionRequest';
import Affiliated from "@/classes/Affiliated";
import Checkbox from "@/components/Forms/Checkbox/Chek_box.vue";
import Swal from 'sweetalert2';
import Document from '@/classes/Document';
import Category from '@/classes/Category';
import DatetimeValidator from '@/classes/DatetimeValidator';
import HousingType from '@/classes/HousingType';
import PaymentIntent from '@/classes/PaymentIntent';

const documentsType = {
  "IO": "IDENTIFICACIÓN",
  "CURP": "CURP",
  "CD": "COMPROBANTE DE DOMICILIO",
  "CI": "COMPROBANTE DE INGRESOS",
  "EC": "ESTADO DE CUENTA",
  "AUDSCTOAHORRO": "AUTORIZO DE DESCUENTO",
  "RFCFIEL": "CONSTANCIA DE SITUACIÓN FISCAL",
  "AUVG": "AUTENTICACIÓN POR VIDEOGRABACIÓN",
  "RPPSOCIAL":"PAGO DE PARTE SOCIAL",
  "AD":"AUTORIZO DE DESCUENTO",
  "RFC":"CONSTANCIA DE SITUACIÓN FISCAL",
  "SI":"SOLICITUD DE INGRESO",
  "AHORRO":"SOLICITUD DE INGRESO",
  "AN": "ACTA DE NACIMIENTO"
};

const clientClassifications = {
  "cabsa_employee": "cabsa_member",
  "educator": "member",
};

export default {
  data() {
  return {
      documentsControlListRequired: [],
      documentControlRef: null,
      refresh: 0,
      documentsIsLoading: true,
      documentsViewerClasses: {
        'forms': 'my-4',
        'controls': 'shadow-md border-2 rounded-lg py-2 px-5 mt-2 w-full color-cherry-border',
        'document': 'shadow-md border-2 rounded-lg py-3 px-2 mt-2 w-full color-cherry-border',
        'container': 'my-4 mx-3'
      },
      formatsBase64:{
        'jpg': 'data:image/jpg;base64',
        'jpeg': 'data:image/jpeg;base64',
        'png': 'data:image/png;base64',
        'pdf': 'data:application/pdf;base64',
        'mp4': 'data:video/mpeg;base64',
      },
      documentsList: [],
      documentObjectClass: null,
      categoryObjectClass: null,
      datetimeValidatorObjectClass: null,
      housingTypeObjectClass: null,
      listCategories:[],
      listHousingTypes:[],
      promoter_email_check:[],//Correo del promotor
      listMailHistory: [],
      itemMailHistory: null,
      tabs: {
        history: true,
        message: false
      },
      listBusinessActivities: [],
      affiliatedObjectsClass: null,
      displayInput: true,
      ccapi: process.env.VUE_APP_CCAPI,
      business: process.env.VUE_APP_BUSINESS_FONCABSA,
      multiApi:process.env.VUE_APP_MULTIAPI,
      activeColorBackground: '#810042',
      inactiveColorBackground: '#FFF',
      activeColorText: '#FFF',
      inactiveColorText: '#810042',
      isLoading: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      apiToken: "",
      personId: "",
      userId: "",
      paternal_name_associate: "",
      maternal_name_associate: "",
      name_associate: "",
      admissionRequestReviewId: "",
      admissionRequestObject: null,
      clientClassification: "",
      showModalComments: false,
      showModalCommentsReview: false,
      showModalDatosPersonales: false,
      showModalDatosPersonalesDireccion: false,
      showModalDatosLaborales: false,
      showModalDatosLaboralesMiembros: false,
      showModalInfoEconomicaYAhorro: false,
      showModalBeneficiarios: false,
      showModalObservationHistory: false,
      showModalObservationHistoryAdd: false,
      showModalHistory: false,
      showModalRejectRequest: false,
      showModalCancelRequest: false,
      checkboxSelected: "",
      minYearDatePicker:"",
      buttonSelected: "personalInfo",
      documentSelected: "",
      documentNameSelected: "",
      countDocumentsByType: 0,
      documentExtension: "",
      videoAuthenticationSource: "",
      image: "",
      list_colonys_datos_personales: [],
      list_colonys_datos_laborales: [],
      list_beneficiaries: [],
      list_observation_history: [],
      list_banks: [],
      hasHadPublicPosition: "",
      id_beneficiarie: 0,
      paternal_name_beneficiarie: "",
      maternal_name_beneficiarie: "",
      name_beneficiarie: "",
      middle_name_beneficiarie: "",
      birthday_beneficiarie: "",
      percentage_beneficiarie: "",
      relationship_beneficiarie: "",
      list_cities_nac: [],
      list_municipalities_nac: [],
      list_states_nac: [],
      phone_datos_personales: "",
      cellphone_datos_personales: "",
      email_datos_personales: "",
      phone_datos_laborales_update: "",
      phone_datos_personales_update: "",
      cellphone_datos_personales_update: "",
      email_datos_personales_update: "",
      extension_datos_laborales_update: "",
      checkAutorizoDescuento: false,
      checkIdentificacionOficial: false,
      checkCurp: false,
      checkComprobanteDomicilio: false,
      checkComprobanteIngresos: false,
      checkRfc: false,
      minYearDatePicker: "",
      maxDate:"",
      birthday_datos_personales: "",
      active_since_datos_laborales: "",
      has_had_public_positions: "",
      has_had_public_positions_familiar: "",
      positions_name: "",
      positions_name_familiar: "",
      start_date_public_positions: "",
      end_date_public_positions: "",
      start_date_public_positions_familiar: "",
      end_date_public_positions_familiar: "",
      start_date_saving: "",
      end_date_saving: "",
      comment: "",
      comment_review: "",
      comment_history: "",
      title_history: "",
      reason_rejection: "",
      comment_rejected: "",
      reason_cancel: "",
      comment_cancel: "",
      isCityNacRequired: true,
      isCityRequired: true,
      gender_spouse: "",
      rfc_spouse: "",
      name_spouse: "",
      middle_name_spouse: "",
      paternal_name_spouse: "",
      maternal_name_spouse: "",
      liquidity: "",
      monthlyLiquidity: "",
      terms_saving: "",
      terms_saving_update: "",
      undefinedTerm: true,
      documentsToSee: [],
      keyType: "",
      keyAccount: "",
      cardNumber: "",
      expirationMonth: "",
      expirationYear: "",
      bank: "",
      occupation_list: [],
      list_months: [],
      list_years: [],
      videoClassObject: null,
      recorderScript: "",
      paymentFrecuency: "QUINCENAL",
      clientFullName: "",
      requestStatus: "",
      documents: {
        IO: [],
        CD: [],
        CURP: [],
        CI: [],
        EC: [],
        RFCFIEL: [],
        AUDSCTOAHORRO: [],
        AUVG: [],
        RPPSOCIAL: [],
        SI: [],
        AD: [],
        RFC: [],
        AHORRO: [],
        AN: []
      },
      admission_request: {
        id:"",
        admission_information:{
          rfc:"",
          city:"",
          curp:"",
          name:"",
          state:"",
          gender:"",
          regime:"",
          status:"",
          street:"",
          suburb:"",
          country:"",
          birthday:"",
          latitude:"",
          longitude:"",
          city_birth:"",
          ext_number:"",
          int_number:"",
          middle_name:"",
          nationality:"",
          postal_code:"",
          state_birth:"",
          municipality:"",
          country_birth:"",
          maternal_name:"",
          paternal_name:"",
          marital_status:"",
          education_level:"",
          residence_start:"",
          residence_status:"",
          adress_attributes:{
            city:"",
            state:"",
            status:"",
            street:"",
            suburb:"",
            country:"",
            latitude:"",
            longitude:"",
            ext_number:"",
            int_number:"",
            postal_code:"",
            municipality:"",
            residence_start:"",
            residence_status:"",
            between_street_one:"",
            between_street_two:""
          },
          spouse_attributes:{
            rfc:"",
            name:"",
            gender:"",
            middle_name:"",
            maternal_name:"",
            paternal_name: ""
          },
          between_street_one:"",
          between_street_two:"",
          municipality_birth:"",
          identification_type:"",
          work_info_attributes:{
            status:"",
            category:"",
            occupation:"",
            active_since:"",
            laboral_status:"",
            principal_occupation: "",
            employee_number:[
            ],
            business_name: "",
            supervisor: "",
          },
          workplace_attributes:{
            city:"",
            name:"",
            state:"",
            status:"",
            street:"",
            suburb:"",
            country:"",
            center_key:"",
            ext_number:"",
            int_number:"",
            postal_code:"",
            municipality:"",
            adress_attributes:{
              city:"",
              state:"",
              status:"",
              street:"",
              suburb:"",
              country:"",
              ext_number:"",
              int_number:"",
              postal_code:"",
              municipality:"",
              between_street_one:"",
              between_street_two:""
            },
            between_street_one:"",
            between_street_two:"",
            contact_info_attributes:{
            }
          },
          identification_number:"",
          salary_info_attributes:{
            liquidity:"",
            monthly_income:"",
            income_frecuency:""
          },
          contact_info_attributes:{
          },
          beneficiaries_attributes:{
          },
          public_office_attributes:{
          },
          saving_registration_attributes:{
            amount:"",
            status:"",
            date_of_end:"",
            date_of_start:"",
            payment_frecuency:"",
            number_of_contribution:"",
            payment_method: ""
          },
          bank_account_attributes: {}
        },
        status: "",
        acepted_date: "",
        persons_id: "",
        created_at: "",
        updated_at: "",
        associate_id: ""
      },
      admission_request_update: {
        id:"",
        admission_information:{
          rfc:"",
          city:"",
          curp:"",
          name:"",
          state:"",
          gender:"",
          regime:"",
          status:"",
          street:"",
          suburb:"",
          country:"",
          birthday:"",
          latitude:"",
          longitude:"",
          city_birth:"",
          ext_number:"",
          int_number:"",
          middle_name:"",
          nationality:"",
          postal_code:"",
          state_birth:"",
          municipality:"",
          country_birth:"",
          maternal_name:"",
          paternal_name:"",
          marital_status:"",
          education_level:"",
          residence_start:"",
          residence_status:"",
          adress_attributes:{
            city:"",
            state:"",
            status:"",
            street:"",
            suburb:"",
            country:"",
            latitude:"",
            longitude:"",
            ext_number:"",
            int_number:"",
            postal_code:"",
            municipality:"",
            residence_start:"",
            residence_status:"",
            between_street_one:"",
            between_street_two:""
          },
          spouse_attributes:{
            rfc:"",
            name:"",
            gender:"",
            middle_name:"",
            maternal_name:"",
            paternal_name: ""
          },
          between_street_one:"",
          between_street_two:"",
          municipality_birth:"",
          identification_type:"",
          work_info_attributes:{
            status:"",
            category:"",
            occupation:"",
            active_since:"",
            laboral_status:"",
            principal_occupation: "",
            employee_number:[
            ],
            business_name: "",
            supervisor: "",
          },
          workplace_attributes:{
            city:"",
            name:"",
            state:"",
            status:"",
            street:"",
            suburb:"",
            country:"",
            center_key:"",
            ext_number:"",
            int_number:"",
            postal_code:"",
            municipality:"",
            adress_attributes:{
              city:"",
              state:"",
              status:"",
              street:"",
              suburb:"",
              country:"",
              ext_number:"",
              int_number:"",
              postal_code:"",
              municipality:"",
              between_street_one:"",
              between_street_two:""
            },
            between_street_one:"",
            between_street_two:"",
            contact_info_attributes:{
            }
          },
          identification_number:"",
          salary_info_attributes:{
            liquidity:"",
            monthly_income:"",
            income_frecuency:""
          },
          contact_info_attributes:{
          },
          beneficiaries_attributes:{
          },
          public_office_attributes:{
          },
          saving_registration_attributes:{
            amount:"",
            status:"",
            date_of_end:"",
            date_of_start:"",
            payment_frecuency:"",
            number_of_contribution:"",
            payment_method: ""
          },
          bank_account_attributes: {}
        },
        status: "",
        acepted_date: "",
        persons_id: "",
        created_at: "",
        updated_at: "",
        associate_id: ""
      },
      admission_request_review: {
        token_auth:"",
        admission_request_review:{
          id: "",
          admission_request_id: "",
          start_date: "",
          finish_date:"",
          admission_request_review_id: "",
          review_information:{
            personal_information:{
              validated: "",
              associate: "",
              validation_date: "",
              corrections:{
              },
              comments: {}
            },
            adress_information:{
              validated: "",
              associate: "",
              validation_date:"",
              corrections:{
              },
              comments: {}
            },
            workplace_information:{
              validated: "",
              associate: "",
              validation_date:"",
              corrections:{
              },
              comments: {}
            },
            economical_information:{
              validated: "",
              associate: "",
              validation_date: "",
              corrections:{
              },
              comments: {}
            },
            beneficiaries_information:{
              validated: "",
              associate: "",
              validation_date: "",
              corrections:{
              },
              comments: {}
            },
            documents:{
              identification:{
                validated: false,
                associate: "",
                validation_date: "",
                corrections:{
                },
                comments: {}
              },
              proof_of_income:{
                validated: false,
                associate: "",
                validation_date: "",
                corrections:{
                },
                comments: {}
              },
              proof_of_residency:{
                validated: false,
                associate: "",
                validation_date: "",
                corrections:{
                },
                comments: {}
              },
              curp:{
                validated: false,
                associate: "",
                validation_date: "",
                corrections:{
                },
                comments: {}
              },
              bank_statement:{
                validated: false,
                associate: "",
                validation_date: "",
                corrections:{
                },
                comments: {}
              },
              rfc:{
                validated: false,
                associate: "",
                validation_date:"",
                corrections:{
                },
                comments: {}
              },
              discount_authorization: {
                validated: false,
                associate: "",
                validation_date:"",
                corrections:{
                },
                comments: {}
              },
              video_verification: {
                validated: false,
                associate: "",
                validation_date:"",
                corrections:{
                },
                comments: {}
              },
              enroll_payment_fee: {
                validated: false,
                associate: "",
                validation_date: "",
                corrections:{
                },
                comments: {}
              },
            },
            review_comments_attributes: {}
          }
        }
      },
      confirmedDocuments: { 
        total: 0,
        confirmed: 0
      },
      saving_amount: 0,
      residence_start:"",
      residence_status:"",
      today:moment().format("DD/MM/YYYY"),
      enrollment_fee_amount: 0,
      employer: "",
      paymentIntentObject: null,
      listPaymentIntent: [],
      clientPersonId: 0
    };
  },
  directives: {money: VMoney},
  filters:  {
    formatDate:  function(value)  {
      if  (!value)  {
        return  "";
      }
      var d = new Date(value)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [day, month, year].join('/');
    },
    camelize: function(str) {
      let response = ""
      if (str != "") {
        str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function(match) {
          return match.charAt(match.length-1).toUpperCase();
        });
        response = str.charAt(0).toUpperCase() + str.substring(1);
        return response.replace(/([A-Z])/g, ' $1').trim(); 
      }
      return "";
    },
    currency: function (value) {
      const options2 = { style: 'currency', currency: 'MXN' };
      //const formatter = new Intl.NumberFormat('es-MX', options2);
      const formatter = new Intl.NumberFormat('en-US', options2);

      if (value != "" && value != undefined) {
        var resultado = Number(value.toString().replace(/[^0-9.-]+/g,"")).toString();
      
        if(!value.toString().includes(".")){
            resultado = value.toString() + ".00"
        }
        
        if(!resultado.includes(",")){
            resultado = resultado.replace(",","")//formatter.format(value)
        }
        resultado = formatter.format(resultado)
        
        resultado = resultado.replace('MX$','')
        
        if (resultado =="NaN" || resultado < 0) {
            resultado = ""
        }
        return '$' + resultado;
      }
      return '$0.00';
    },
    uppercase: function (value) {
      if(value != "" && value != undefined){
          value = value.toUpperCase();
      }
      return value
    },
    phone: function (value) {
      return value.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    },
    formatDate: function(value) {
      if  (!value)  {
        return  "";
      }
      var d = new Date(value)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [day, month, year].join('/');
    },
    formatMoney: function (value){
      const options2 = { style: 'currency', currency: 'MXN' };
      
      const formatter = new Intl.NumberFormat('en-US', options2);

      var resultado = Number(value.replace(/[^0-9.-]+/g,"")).toString();
      
      if(!value.includes(".")){
          resultado = value + ".00"
      }

      if(!resultado.includes(",")){
          resultado = resultado.replace(",","")//formatter.format(value)
      }
      resultado = formatter.format(resultado)
      
      resultado = resultado.replace('MX$','')
      
      if (resultado =="NaN" || resultado < 0) {
          resultado = ""
      }

      return resultado
    },
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getCurrentDateName: function(date) {
      let self = this;
      let dateToday = new Date(date);
      let year = dateToday.toLocaleDateString('es-MX', { year: 'numeric' });
      let monthName = dateToday.toLocaleDateString('es-MX', { month: 'short' }).replaceAll(".", "");
      let month = String(dateToday.getMonth() + 1).padStart(2, '0');
      let day = dateToday.toLocaleDateString('es-MX', { day: 'numeric' });

      return `${day} ${monthName} ${year.substr(-2)}`
    },
    getCurrentDateHour: function(date) {
      let dateToday = new Date(date);
      return `${dateToday.getHours()}:${dateToday.getMinutes()}`
    },
  },
  name: 'Principal',
  components: {
    Loading,
    Modal,
    Checkbox
  },
  watch: {
    'admission_request_review.admission_request_review.review_information.documents': function(values){
      this.activateMailingButton();
    },
    'start_date_public_positions': function(){
      if (this.start_date_public_positions != "") {
        this.changePublicOffice('titular', 'start_date_public_positions', this.start_date_public_positions) 
      }
    },
    'end_date_public_positions': function(){
      if (this.end_date_public_positions != "") {
        this.changePublicOffice('titular', 'end_date_public_positions', this.end_date_public_positions)
      }
    },
    'start_date_public_positions_familiar': function(){
      if (this.start_date_public_positions_familiar != "") {
        this.changePublicOffice('familiar', 'start_date_public_positions', this.start_date_public_positions_familiar)
      }
    },
    'end_date_public_positions_familiar': function(){
      if (this.end_date_public_positions_familiar != "") {
        this.changePublicOffice('familiar', 'end_date_public_positions', this.end_date_public_positions_familiar)
      }
    },
    'admission_request_update.admission_information.state_birth': function() {
      const self = this
      if(this.admission_request_update.admission_information.state_birth == null){ return; }
      this.errors = ""
      this.axios.post(this.multiApi +"/Api/Catalogo/Sepomex/obtener_municipios_por_estado", {
        contenido: {
         estado: this.admission_request_update.admission_information.state_birth
        },
        withCredentials: true,
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          }
      },{
        auth: {
        username: "multicr",
        password: "*cabsa2018*"
      }}
      ).then(response => {
        self.list_municipalities_nac = response.data.municipios
      }).catch((error) => {
          
      });
    },
    'admission_request_update.admission_information.municipality_birth': function () {
      this.axios.post(this.multiApi +"/Api/Catalogo/Sepomex/obtener_ciudades_por_estado_y_municipio", {
          contenido: {
              estado: this.admission_request_update.admission_information.state_birth,
              municipio: this.admission_request_update.admission_information.municipality_birth
          },
          withCredentials: true,
          headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          }
      },{
          auth: {
          username: "multicr",
          password: "*cabsa2018*"
      }}
      ).then(response => {
        this.list_cities_nac = response.data.ciudades
        this.isCityNacRequired = true
        if (this.list_cities_nac.length == 0) {
          this.list_cities_nac = [{id:0,nombre: "N/A"}]
        }

        // if (this.list_cities_nac.length > 0) {
        //     this.isCityNacRequired = true
        // } else {
        //     this.isCityNacRequired = false
        // }
      }).catch((error) => {
          
      });
    },
    'admission_request_update.admission_information.public_office_attributes.has_had_public_positions': function (newValue) {
      let lt = newValue;
    },
    start_date_saving() {
      var swal = require('sweetalert2');
      if (this.start_date_saving != "" && this.end_date_saving != "") {
        this.getYearsSaving(this.orderDateByYearMonthDay(this.start_date_saving, '/'), this.orderDateByYearMonthDay(this.end_date_saving, '/'));
      }
    },
    end_date_saving() {
      var swal = require('sweetalert2');
      if (this.start_date_saving != "" && this.end_date_saving != "") {
        this.getYearsSaving(this.orderDateByYearMonthDay(this.start_date_saving, '/'), this.orderDateByYearMonthDay(this.end_date_saving, '/'));
      }
    }
  },
  async mounted() {  
    this.getStates();
    await this.getBanks();
    await this.getHistoryMissingDocuments();
  },
  async beforeMount() {
    this.affiliatedObjectsClass = new Affiliated();
    this.documentObjectClass = new Document();
    this.categoryObjectClass = new Category();
    this.datetimeValidatorObjectClass = new DatetimeValidator();
    this.housingTypeObjectClass = new HousingType();

    this.establisDateMaxToday();
    this.establishYearMinDatePicker();
    var self = this;

      console.log(this.admission_request)
    Array.prototype.max = function() {
      return Math.max.apply(null, this);
    };

    this.videoClassObject = new Video();
    this.removeLocalStorage("request_"+this.getRequestIdFromParams());
    this.removeLocalStorage("request_review_"+this.getRequestIdFromParams());
    await this.loadInfo();
    await this.getInfo();

    this.listCategories = this.categoryObjectClass.getByClientClasification(this.clientClassification)
    
    this.getYearsCard();
    this.getMonthsCard();
  },
  methods:{
    getToken(){
      return sessionStorage.getItem('login');
    },
    getCatalogBusinessActivities() {
      this.affiliatedObjectsClass
      .getBusinessActivities(sessionStorage.getItem("login"))
      .then((resp) => {
      const response = resp.data.response.data;
        this.listBusinessActivities = response? response: [];
      })
      .catch((err) => {
        console.log(err);
      });
    },
    send(missingDocuments){
      var swal = require('sweetalert2');  
      swal.fire({
        title: "Atención",
        icon: 'question',
        text: "¿Desea enviar un correo con los documentos faltantes?",
        showCancelButton: true,
        reverseButtons:true,
        confirmButtonColor: '#FEB72B',
        cancelButtonColor: '#FEB72B',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          swal.close();
          let contentBody="";
          console.log("this.admission_request.promoter_name; ", this.admission_request.promoter_name)
            if(this.admission_request.promoter_name!=""){
              contentBody+=`<div>
                              <input type="checkbox" id="id-check-box-promoter"/>
                              <label for="id-check-box-promoter">Enviar correo al promotor</label>
                            <div/>`;
            }
            contentBody+=` <div>
                              <input type="checkbox" id="id-check-box-client"/>
                              <label for="id-check-box-client">Enviar correo al cliente</label>
                            <div/>`;

            swal.close();
            if (result.isConfirmed) {
              swal.fire({
                title: "Documentación Faltante",
                input: "textarea",
                html: `${this.getStructureMissingDocumentsList(missingDocuments)} <br> ${contentBody}`,
                showCancelButton: true,
                reverseButtons:true,
                confirmButtonColor: '#FEB72B',
                cancelButtonColor: '#FEB72B',
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false,
                inputLabel: 'Observaciones:',
                inputAttributes: {
                  maxlength: 255,
                },
                inputValidator: (result) => {
                  //let promoter = document.getElementById("id-check-box-promoter");
                  let client = document.getElementById("id-check-box-client");
                  let message = "";
                  if(!client.checked){
                    message = "Favor de seleccionar un destinatario al momento de enviar el correo."
                  }
                  if(!result){
                    message = "Es necesario agregar una observación."
                  }
                  return message;
                },
                customClass: {
                  input: 'inline-flex custom-textarea',
                  inputLabel: 'inline-flex'
                },
                inputPlaceholder: 'Agrega tu observación aquí',
                 type: "input",
                inputType: "checkbox",        
              }).then((result) => {
                if (result.isConfirmed) {
                  let ischekedPrometer=false
                  let ischekedClient=false
                  if(document.getElementById("id-check-box-promoter") != undefined){
                    ischekedPrometer = document.getElementById("id-check-box-promoter").checked;
                  }
                  if(document.getElementById("id-check-box-client") != undefined){
                    ischekedClient = document.getElementById("id-check-box-client").checked;
                  }

                  if(ischekedPrometer){
                    this.promoter_email_check.push(this.admission_request.promoter_email);
                  }
                  if(ischekedClient){
                    this.promoter_email_check.push(this.email_datos_personales_update)
                  }

                  if(this.promoter_email_check.length == 0 && document.getElementById("id-check-box-promoter") == undefined && document.getElementById("id-check-box-client") == undefined){
                    this.promoter_email_check.push(this.email_datos_personales_update)
                  }
                  this.isLoading = true;
                  this.sendMissingDocumentsMessage($('#swal2-input').val(), missingDocuments);
                } 
              }); 
              this.isLoading = false;
            }else{
              this.sendMissingDocumentsMessage();
            }
        } 
      })    
      this.isLoading = false;
    },
    getStructureMissingDocumentsList(listDocuments){
      let htmlResult = `<ul class="list-disc list-inside" style="text-align: left;margin-left: auto;margin-right: auto;margin-bottom: 30px;">`
      listDocuments.forEach((doc) => {
       htmlResult += `<li class="my-2">${doc}</li>`;
      })
      htmlResult += '</ul>';
      console.log(htmlResult, listDocuments)
      return htmlResult;
    },
    async sendMissingDocumentsMessage(description = '', missingDocuments=''){
      
      const header = { "alg": "HS256", "typ": "JWT" };
      const encodedHeader = Buffer.from(JSON.stringify(header)).toString('base64');

      var html = {
        "html": this.bodyEmailReference(description, missingDocuments)
      };
      
      const encodedPayload = Buffer.from(JSON.stringify(html)).toString('base64');
      var jwtSecret = "u3E*aWShrxOv";
      const crypto = require('crypto');
      const signature = crypto.createHmac('sha256', jwtSecret).update(encodedHeader + '.' + encodedPayload).digest('base64') ;    
      this.tokenMessage = encodedHeader+"."+encodedPayload+"."+signature;

      var swal = require('sweetalert2'); 
      await this.axios.post(this.ccapi + `/${this.business}/send_mail`, {
        "business":this.business,
        "mail": {
            "business": this.business,
            "process_type": "envio documentos",
            "title": "Documentación faltante",
            "body_mail": this.tokenMessage,
            "mailing_list": this.promoter_email_check,
            "attachment_list": []
        }
      },{
        'Content-Type': 'application/json'
      }).then(response => {
        this.isLoading = false
        //this.cleanControls();
        this.registerHistoryMissingDocument(description, missingDocuments, this.bodyEmailReference(description, missingDocuments));

        var info = "El correo ha sido enviado existosamente"
        swal.fire({
          title: "Aviso",
          text: info,
          icon: "success",
          confirmButtonColor: '#FEB72B',
        });

      }).catch(error => {
        console.log("Error:", error);
        this.isLoading = false
        swal.fire({
          title: "Aviso",
          text: "Ocurrio un error al enviar el correo",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
      });
    },
    bodyEmailReference(description='', missingDocuments=''){
      var bodyEmail = `<!DOCTYPE html>
            <html lang="es">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Document</title>
                </head>
                <body>
                    <div style="background:#810042;height: 130px;display: flex;width: 100%;margin:0 auto;">
                        <div class="div_centrado" style="margin:0 auto;background:white;width:77%;height:640px;margin-top:50px;border-radius:14px; text-align: center;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
                            <p style="margin-top:40px;font-family:Roboto,Arial;color:#8e8e8e;padding-left: 60px;padding-right: 60px;text-align: justify;">
                                Estimado(a) Sr(a,ita). ${ this.clientFullName.toUpperCase() }
                            </p>
                            <p style="margin-top:40px;font-family:Roboto,Arial;color:#8e8e8e;padding-left: 60px;padding-right: 60px;text-align: justify;">
                                Nos ponemos en contacto con usted con motivo de su solicitud de inscripción al Ahorro como Socio Cooperativista de FONCABSA, para informarle que hemos detectado que nos hacen falta los siguientes documentos para poder aceptar su solicitud:
                            </p>
                            ${this.getStructureMissingDocumentsList(missingDocuments)}
                            <p style="margin-top:30px;font-family:Roboto,Arial;color:#8e8e8e;padding-left:60px;padding-right:60px;text-align:justify">${description}</p>

                            <p style="margin-top:40px;font-family:Roboto,Arial;color:#8e8e8e;padding-left: 60px;padding-right: 60px;text-align: justify;">
                                Esperamos contar de su apoyo, enviándonos la documentación  solicitada a través de este medio o en nuestra plataforma <a href="www.foncabsa.com" target="_blank">www.foncabsa.com</a>   para lo cual requiere ingresar con su usuario y contraseña. 
                            </p>

                            <p style="margin-top:40px;font-family:Roboto,Arial;color:#8e8e8e;padding-left: 60px;padding-right: 60px;text-align: justify;">
                                Agradecemos su tiempo y atención a la presente.<br><br>
                                Quedamos a sus órdenes para cualquier comentario o duda al respecto.
                            </p>

                            <p style="margin-top:40px;font-family:Roboto,Arial;color:#8e8e8e;padding-left: 60px;padding-right: 60px;text-align: justify;">
                                Reciba atentos saludos.<br>
                                Equipo FONCABSA
                            </p>
                        </div>
                    </div>
                    <div style="background:#fcfcfc;height: 570px;display: flex;justify-content: center; width: 50%;margin:0 auto;">
                    </div>
                </body>
            </html>`;
            return bodyEmail
    },
    setterAdressPerson(){
      this.admission_request_update.admission_information.street = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.street);
      this.admission_request_update.admission_information.between_street_one = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.between_street_one);
      this.admission_request_update.admission_information.between_street_two = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.between_street_two);
      this.admission_request_update.admission_information.ext_number = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.ext_number);
      this.admission_request_update.admission_information.int_number = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.int_number);
      this.admission_request_update.admission_information.suburb = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.suburb);

      this.admission_request_update.admission_information.adress_attributes.street = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.street);
      this.admission_request_update.admission_information.adress_attributes.between_street_one = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.between_street_one);
      this.admission_request_update.admission_information.adress_attributes.between_street_two = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.between_street_two);
      this.admission_request_update.admission_information.adress_attributes.ext_number = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.ext_number);
      this.admission_request_update.admission_information.adress_attributes.int_number = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.int_number);
      this.admission_request_update.admission_information.adress_attributes.suburb = this.addUpperCase(this.admission_request_update.admission_information.adress_attributes.suburb)
    },
    Logout(){
      sessionStorage.clear();
      //localStorage.clear();
      window.location.href = "/"
    },
    async loadInfo(){
      var auth = getTokenDecoden();
      this.apiToken = sessionStorage.getItem("login");
      this.userId = auth.obj['id'];
      this.paternal_name_associate = auth.obj.personnel_info.paternal_name;
      this.maternal_name_associate = auth.obj.personnel_info.maternal_name;
      this.name_associate = auth.obj.personnel_info.name;
      this.personId = auth.obj.personnel_info['id'];
      this.admissionRequestObject = new AdmissionRequest();
      this.paymentIntentObject = new PaymentIntent();
    },
    getStates(){
      this.axios.post(this.multiApi +"/Api/Catalogo/Sepomex/obtener_estados", {
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      },{
        auth: {
        username: "multicr",
        password: "*cabsa2018*"
      }}
      ).then(response => {
        this.list_states_nac = response.data.Estados
      });
    },
    closeModalComments() {
      this.showModalComments = false;
      this.comment = "";
    },
    closeModalCommentsReview() {
      this.showModalCommentsReview = false;
      this.showModalCancelRequest= false
      this.comment = "";
    },
    closeModalCommentHistory() {
      this.showModalObservationHistoryAdd = false;
      this.title_history = "";
      this.comment_history ="";
    },
    moveScroll(selector){
      var container = $('#infoContainer'),
          scrollTo = $('#'+selector);

      container.animate({
        scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
      });
    },
    async getBanks() {
      await this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_all_banks`, {
        params:{
          token_auth: this.getToken()
        }
      }, { 'Content-Type': 'application/json' })
      .then(response => {
        this.list_banks = response.data.response.lista_bancos;
      }).catch(error => {
      });
    },
    async getCatalogOccupation() {
      var swal = require('sweetalert2');
      
      var hash_occupations = {
          token_auth: this.getToken(),
          client_type: this.clientClassification
        }
      await this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_catalogs_occupation_by_client_type`, {
        params:hash_occupations
      },
      { 'Content-Type': 'application/json' })
      .then(response => {
        if(response.data.response.lista_ocupaciones.length == 0){
          swal.fire({
            title: "Aviso",
            text: "No se encontraron ocupaciones por el tipo de cliente, favor de contactar a sistemas.",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          })
        }
        this.occupation_list = response.data.response.lista_ocupaciones
      }).catch(error => {
        swal.fire({
            title: "Aviso",
            text: "Ocurrio un error inesperado al consultar ocupaciones, favor de contactar a sistemas.",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          })
      });
    },
    getMonthsCard(){
      this.list_months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    },
    getYearsCard(){
      var years = [];
      var startYear = new Date().getFullYear()-1;
      var lastYear = new Date().getFullYear() + 5;
      
      while (parseInt(startYear) < parseInt(lastYear) ) {
          startYear++
          var year = startYear
          years.push(parseInt(year.toString().substring(2, 4)));
      }
      this.list_years = years
      return years;
    },
    getLastMonthsYear(){
      var months = [];
      var startMonth = 0
      if((new Date().getFullYear()).toString().substring(2, 4) == this.expirationYear){
        startMonth = new Date().getMonth();
      }
      var lastMonth = 12
      
      while (parseInt(startMonth) < parseInt(lastMonth) ) {
        startMonth++
        var monthSelect = ""
        if(startMonth < 10){ monthSelect = "0"+startMonth }else{ monthSelect = startMonth }
        months.push(monthSelect.toString());
      }
      return months;
    },
    changeDocument(document) {
      try {
        this.isLoading = true;
        this.documentNameSelected = "";
        let documentItem = this.documents[document].filter(doc => doc.document_type.toUpperCase() === document.toUpperCase())
        this.countDocumentsByType = documentItem.length;
        if (this.countDocumentsByType < 1) {
          this.image = "";
          PDFObject.embed("", "#pdfViewer");
          this.documentSelected = "";
          this.documentNameSelected = "";
          this.isLoading = false;
          return;
        }
        if (this.countDocumentsByType <=1) {
          
          this.documentExtension = documentItem[0].extension;
          if (this.documentExtension == "jpg" || this.documentExtension == "jpeg" || this.documentExtension == "png") {
            PDFObject.embed("", "#pdfViewer");
            this.image = documentItem[0].url;
          } else if (this.documentExtension == "mp4") {
            this.videoAuthenticationSource = documentItem[0].url;
          } else {
            PDFObject.embed(documentItem[0].url, "#pdfViewer");
          }
        } else {
          this.documentExtension = "";
          PDFObject.embed("", "#pdfViewer");
        }

        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    changeDocumentName(document_name){
      try {
        let documentItem = this.documents[this.documentSelected].filter(doc => doc.document_name.toUpperCase() === document_name.toUpperCase())
        
        this.documentExtension = documentItem[0].extension;
        if (this.documentExtension == "jpg" || this.documentExtension == "jpeg" || this.documentExtension == "png") {
          this.image = documentItem[0].url;
        } else {
          PDFObject.embed(documentItem[0].url, "#pdfViewer");
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    changeButtonSelected(value){
      this.buttonSelected = value;
      this.moveScroll(value);
    },
    openModal(checkboxSelected) {
      this.showModalComments = true;
      this.checkboxSelected = checkboxSelected;
    },
    openModalComments(checkboxSelected){
      this.showModalCommentsReview = true;
      this.checkboxSelected = checkboxSelected;
      
      if(this.admission_request_review.admission_request_review.review_information.documents[checkboxSelected]) {
        var comment = this.admission_request_review.admission_request_review.review_information.documents[checkboxSelected];
        this.comment_review = (comment.comments)? comment.comments: [];
        return;
      }

      this.comment = "";
    },
    openModalInfoEconomicaYAhorro() {
      this.liquidity = this.formatMoney(this.admission_request_update.admission_information.salary_info_attributes.liquidity.toString())
      this.monthly_income = this.formatMoney(this.admission_request_update.admission_information.salary_info_attributes.monthly_income.toString())

      this.start_date_saving = this.admission_request_update.admission_information.saving_registration_attributes.date_of_start != "" ? this.orderDateByDayMonthYear(this.admission_request_update.admission_information.saving_registration_attributes.date_of_start, "/") : "";
      this.end_date_saving = this.admission_request_update.admission_information.saving_registration_attributes.date_of_end != "" ? this.orderDateByDayMonthYear(this.admission_request_update.admission_information.saving_registration_attributes.date_of_end, "/") : "";

      if (this.admission_request_update.admission_information.saving_registration_attributes.payment_method == "DOMICILIACIÓN") {
        let bank_account_attributes_array = this.convertListToArray(this.admission_request_update.admission_information.bank_account_attributes);
        this.bank = bank_account_attributes_array[0].bank_name;
        this.keyType = bank_account_attributes_array[0].account_type
        this.cardNumber = this.keyType == "numero de tarjeta" ? this.includeDashesCardNumber(bank_account_attributes_array[0].account_number,this.bank) : ""
        this.keyAccount = this.keyType == "cuenta clabe" ? bank_account_attributes_array[0].account_number : ""
        this.expirationYear = bank_account_attributes_array[0].expiration_year;
        this.expirationMonth = bank_account_attributes_array[0].expiration_month;
      } else {
        this.keyType = ""
        this.cardNumber = ""
        this.keyAccount = ""
        this.expirationYear = ""
        this.expirationMonth = ""
        this.bank = ""
      }
      this.showModalInfoEconomicaYAhorro = true
    },
    openModalDatosLaborales(clientClassification) {
      // this.start_date_public_positions = this.admission_request_update.admission_information.public_office_attributes.start_date_public_positions != "" ? this.orderDateByDayMonthYear(this.admission_request_update.admission_information.public_office_attributes.start_date_public_positions) : "";
      // this.end_date_public_positions = this.admission_request_update.admission_information.public_office_attributes.end_date_public_positions != "" ? this.orderDateByDayMonthYear(this.admission_request_update.admission_information.public_office_attributes.end_date_public_positions) : "";
      let public_office_attributes_array = this.convertListToArray(this.admission_request.admission_information.public_office_attributes);

      this.has_had_public_positions = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions
      this.positions_name = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].positions_name
      this.start_date_public_positions = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].start_date_public_positions) : "";
      this.end_date_public_positions = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].end_date_public_positions) : "";

      this.has_had_public_positions_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions
      this.positions_name_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].positions_name
      this.start_date_public_positions_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].start_date_public_positions) : "";
      this.end_date_public_positions_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].end_date_public_positions) : "";
      this.setContactInfoDatosLaboralesUpdate();

      if (["businessman","educator"].includes(clientClassification)) {
        this.showModalDatosLaborales = true;  
      } else if (clientClassification == "cabsa_employee") {
        this.showModalDatosLaboralesMiembros = true;  
      }
      
    },
    convertListToArray(list) {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }

      for (let i = 0; i < index.length; i++) {
        array.push(list[index[i]]);
      }

      return array;
    },
    getRequestIdFromParams(){
      return this.$route.params.requestId;
    },
    getTotalConfirmedDocuments(documents){
      var documentsArray = Object.values(documents);
      var totalDocuments = documentsArray.length;
      
      var confirmedDocuments = documentsArray.filter(e => e.validated);

      return {
        total: totalDocuments,
        confirmed: confirmedDocuments
      };
    },
    getCreatedReview() {
      this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_review_by_request`, {
          params:{
            token_auth: this.getToken(),
            admission_request_id: this.getRequestIdFromParams()
          }
        }, { 'Content-Type': 'application/json' })
        .then(response => {
          //TODO Probar que si el admission request review global falla si se elimina el admission request review local
          let local_storage_request_review = this.getFromLocalStorage("request_review_"+this.getRequestIdFromParams());
          let review_information = response.data.response.admission_request_review.review_information;

          if (local_storage_request_review != null) {
            this.confirmedDocuments = this.getTotalConfirmedDocuments(local_storage_request_review.admission_request_review.review_information.documents);
            this.admission_request_review = local_storage_request_review;
          } else {
            this.admission_request_review = {
              token_auth:"",
              admission_request_review: {
                id: this.userId,
                admission_request_id:"",
                start_date:"",
                finish_date:"",
                admission_request_review_id:"",
                review_information
              }
            }
          }
          this.list_observation_history = this.convertListToArray(this.admission_request_review.admission_request_review.review_information.review_comments_attributes);
          this.setToLocalStorage("request_review_"+this.getRequestIdFromParams(), this.admission_request_review);
          this.admissionRequestReviewId = response.data.response.admission_request_review.id;
          
          if(Object.keys(this.admission_request_review.admission_request_review.review_information.documents).indexOf("enroll_payment_fee")==-1){
            this.admission_request_review.admission_request_review.review_information.documents.enroll_payment_fee = {
              associate: "",
              comments: {},
              corrections: {},
              validated: true,
              validation_date: ""
            };
          }
          
        }).catch(error => {
          let local_storage_request_review = this.getFromLocalStorage("request_review_"+this.getRequestIdFromParams());
          if (local_storage_request_review != null) {
            this.admission_request_review = local_storage_request_review;
            this.list_observation_history = this.convertListToArray(this.admission_request_review.admission_request_review.review_information.review_comments_attributes);
          } else {
            this.admission_request_review.admission_request_review.id = this.userId;
          }
          this.setToLocalStorage("request_review_"+this.getRequestIdFromParams(), this.admission_request_review);
        }); ;
    },
    getRequestStatus(admissionRequest) {
      let status = "";
      if (admissionRequest.status == "rechazada") {
        status = admissionRequest.status;
      }
      if (admissionRequest.status == "aceptada") {
        status = admissionRequest.status;
      }
      
      return status;
    },
    async getInfo() {
      var swal = require('sweetalert2');
      this.isLoading = true;
      //TODO eliminar código de consulta de local storage en refactorizacion de código
      //let admission_request_localstorage = this.getFromLocalStorage("request_"+this.getRequestIdFromParams());

      //if (admission_request_localstorage == null) {
        this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_request_by_id`, {
          params:{
            token_auth: this.getToken(),
            admission_request_id: this.getRequestIdFromParams()
          }
        }, { 'Content-Type': 'application/json' })
        .then(response => {
          this.admission_request = response.data.response.admission_request;
          
          let status = this.getRequestStatus(this.admission_request);

          this.clientPersonId = this.admission_request.foncabsa_person_id
          
          this.paymentIntentObject.getPaymentIntent(sessionStorage.getItem("login"), this.clientPersonId).then((resp)=>{
            this.listPaymentIntent = resp.data.response.data
            this.pendingToAceptedRequestWithPayment();
          });

          this.admission_request.admission_information.saving_registration_attributes.date_of_start = this.getCurrentDate();
          this.admission_request.admission_information.saving_registration_attributes.date_of_end = this.getCurrentDate();

          this.admission_request_update = JSON.parse(JSON.stringify(this.admission_request));

          this.saving_amount = this.admission_request_update.admission_information.saving_registration_attributes.amount.toString().replaceAll(".00","")
          
          this.clientClassification = this.admission_request_update.admission_information.client_classification;

          this.listCategories = this.categoryObjectClass.getByClientClasification(this.clientClassification)
          this.listHousingTypes = this.housingTypeObjectClass.getByClientClasification(this.clientClassification)

          if(this.admission_request.assisted_request){
            this.getCatalogBusinessActivities();
          }
          
          if(this.clientClassification != ''){
            if(["cabsa_employee","educator"].includes(this.clientClassification)){
              this.getCatalogOccupation();
            }
          }

          this.requestStatus = status;

          console.log("Datos del usuario ",this.admission_request )

          // if (status != "") {
          //   this.isLoading = false;
          //   swal.fire({
          //     title: "Aviso",
          //     text: "La solicitud ya ha sido " + status,
          //     icon: "info",
          //   }).then(function() {
          //     window.location.href = "/analyst/principal"
          //   });
          //   return;
          // }
          
          this.monthly_income = this.formatMoney(this.admission_request_update.admission_information.salary_info_attributes.monthly_income.toString());
          this.liquidity = this.formatMoney(this.admission_request_update.admission_information.salary_info_attributes.liquidity.toString());
          this.birthday_datos_personales = this.datetimeValidatorObjectClass.normalizeDateFormat(this.admission_request_update.admission_information.birthday);
          this.active_since_datos_laborales = this.datetimeValidatorObjectClass.normalizeDateFormat(this.admission_request_update.admission_information.work_info_attributes.active_since);

          //Datos laborales
          let public_office_attributes_array = this.convertListToArray(this.admission_request_update.admission_information.public_office_attributes);

          this.has_had_public_positions = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions
          this.positions_name = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].positions_name
          this.start_date_public_positions = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].start_date_public_positions) : "";
          this.end_date_public_positions = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].end_date_public_positions) : "";

          this.has_had_public_positions_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions
          this.positions_name_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].positions_name
          this.start_date_public_positions_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].start_date_public_positions) : "";
          this.end_date_public_positions_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].end_date_public_positions) : "";

          // this.start_date_public_positions = this.admission_request_update.admission_information.public_office_attributes.has_had_public_positions ? moment(this.admission_request_update.admission_information.public_office_attributes.start_date_public_positions).format("DD/MM/YYYY") : "";
          // this.end_date_public_positions = this.admission_request_update.admission_information.public_office_attributes.has_had_public_positions ? moment(this.admission_request_update.admission_information.public_office_attributes.end_date_public_positions).format("DD/MM/YYYY") : "";

          this.list_beneficiaries = this.getArrayBeneficiaries(this.admission_request_update.admission_information.beneficiaries_attributes);
          
          let contact_info_datos_personales = this.convertListToArray(this.admission_request.admission_information.contact_info_attributes);
          this.phone_datos_personales = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'telefono particular').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'telefono particular')[0].value : "";
          this.cellphone_datos_personales = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'celular').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'celular')[0].value : "";
          this.email_datos_personales = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'correo').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'correo')[0].value : "";
          this.phone_datos_personales_update = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'telefono particular').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'telefono particular')[0].value : "";
          this.cellphone_datos_personales_update = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'celular').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'celular')[0].value : "";
          this.email_datos_personales_update = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'correo').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'correo')[0].value : "";

          let contact_info_datos_laborales = this.convertListToArray(this.admission_request_update.admission_information.workplace_attributes.contact_info_attributes);
          this.phone_datos_laborales_update = contact_info_datos_laborales.filter(({contact_type}) => contact_type === 'telefono').length > 0 ? contact_info_datos_laborales.filter(({contact_type}) => contact_type === 'telefono')[0].value : "";
          this.extension_datos_laborales_update = contact_info_datos_laborales.filter(({contact_type}) => contact_type === 'telefono').length > 0 ? contact_info_datos_laborales.filter(({contact_type}) => contact_type === 'telefono')[0].extension : "";

          this.clientFullName = `${this.admission_request.admission_information.name} ${this.admission_request.admission_information.middle_name} ${this.admission_request.admission_information.paternal_name} ${this.admission_request.admission_information.maternal_name}`;

          this.gender_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.gender : "";
          this.rfc_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.rfc : "";
          this.name_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.name : "";
          this.middle_name_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.middle_name : "";
          this.paternal_name_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.paternal_name : "";
          this.maternal_name_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.maternal_name : "";
          
          this.undefinedTerm = this.admission_request_update.admission_information.saving_registration_attributes.number_of_contribution > 0 ? false : true;
          
          if (this.admission_request_update.admission_information.saving_registration_attributes.payment_method == "DOMICILIACIÓN" && 
           Object.keys(this.admission_request_update.admission_information.bank_account_attributes).length > 0) {
            let bank_account_attributes_array = this.convertListToArray(this.admission_request_update.admission_information.bank_account_attributes);
            this.bank = bank_account_attributes_array[0].bank_name;
            this.keyType = bank_account_attributes_array[0].account_type
            this.cardNumber = this.keyType == "numero de tarjeta" ? this.includeDashesCardNumber(bank_account_attributes_array[0].account_number, this.bank) : ""
            this.keyAccount = this.keyType == "cuenta clabe" ? bank_account_attributes_array[0].account_number : ""
            this.expirationYear = bank_account_attributes_array[0].expiration_year;
            this.expirationMonth = bank_account_attributes_array[0].expiration_month;
          } else {
            this.bank = ""
            this.keyType = ""
            this.cardNumber = ""
            this.keyAccount = ""
            this.expirationYear = ""
            this.expirationMonth = ""
          }

          this.residence_start = this.datetimeValidatorObjectClass.normalizeDateFormat(this.admission_request_update.admission_information.adress_attributes.residence_start)
          this.residence_status = this.admission_request_update.admission_information.residence_status.toString().toLowerCase();

          this.getInfoAdressByPostalCodeInformacionPersonal(this.admission_request_update.admission_information.adress_attributes.postal_code)
          this.getInfoAdressByPostalCodeDatosLaborales(this.admission_request_update.admission_information.workplace_attributes.adress_attributes.postal_code)
          
          this.setToLocalStorage("request_"+this.admission_request_update.id, this.admission_request);  
          this.getCreatedReview();

          this.documentsIsLoading = true;
          this.getDocuments().then((resp)=> {
            this.setDocumentsToSee(resp);
            this.setDocuments(resp);
            this.documentsList = this.generateStructureDocumentList(resp)
          }).finally(async ()=> {
            await this.getVideoAuthentication();
            await this.getRecorderScript();
            this.documentsIsLoading = false;
          });
          
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;
        }); 


        this.refresh = 0
        this.admissionRequestObject.getStructureDocumentsAdmissionRequest(sessionStorage.getItem("login"), this.getRequestIdFromParams(), this.admissionRequestObject.getRequiredIn(this.assistedRequest, this.clientClassification))
        .then(resp => {
          this.admission_request_review.admission_request_review.request_information = resp.data.response.data
          this.documentsControlListRequired = this.admission_request_review.admission_request_review.request_information.documents;
          this.refresh = 1
          console.log("this.admission_request_review.admission_request_review.request_information: ", this.admission_request_review.admission_request_review.request_information)
        })
    },
    getFromLocalStorage(item) {
      return JSON.parse(window.localStorage.getItem(item));
    },
    removeLocalStorage(item){
      localStorage.removeItem(item);
    },
    setToLocalStorage(item, json){
      window.localStorage.setItem(item, JSON.stringify(json));
    },
    async getDocuments() {
      let response = await this.documentObjectClass.downloadAllDocuments(this.getToken(), "documentos_alta_socios", this.getRequestIdFromParams()).then(resp => { return {status: true, message: resp.data.response.message, data: resp.data.response.documents } }).catch(err => { return {status: false, message: resp.data.response.message, data: resp.data.response.documents } });
            
      if(!response.status){
        this.showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', response.message);
        return null;
      }

      return response.data;
    },
    removeDuplicates(list){
      let newList = [];
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        
        let match = newList.filter(row => row.key == element.key);
        if(match<2){
          newList.push(element);
        }
      }

      return newList;
    },
    generateStructureDocumentList(documents){
      
      let newDocumentList = documents.map(element => {
        let match = this.getMatchByAbbreviaton(element.abbreviation, documents);
       
        let newStructure = this.generateDocumentContentStructure(match);
        return  { key: element.abbreviation, name: element.document_type, type: element.document_type, content:  (newStructure.length == 1)? newStructure[0]: newStructure};
      });
      
      this.documentsIsLoading = false;
      return this.removeDuplicates(newDocumentList);
    },
    generateDocumentContentStructure(list){
      return list.map(element => { 
        return { name: element.document_name, type: element.document_name, source: `${this.formatsBase64[element.extension]},${element.doument_base64}`, alt: element.document_name, extension: element.extension};
      })
    },
    getMatchByAbbreviaton(abbr, list){
      return list.filter(element => abbr == element.abbreviation);
    },
    showMessage(type, title, message, confirmButtonText = "OK", confirmButtonColor= "#FEB72B"){
      var Swal = require('sweetalert2');
      Swal.fire({
          title: title,
          html: message,
          icon: type,
          confirmButtonText: confirmButtonText,
          confirmButtonColor: confirmButtonColor
      });
    },
    async getVideoAuthentication() {
      var swal = require('sweetalert2');
      
      return await this.videoClassObject.getVideo(this.getToken(), this.getRequestIdFromParams()).then((response)=>{
        if(response.data.response.videos.length > 0){
          let abbreviation = "AUVG";

          let video = response.data.response.videos[0];
          this.documentsList.push({ key: abbreviation, name: "Autenticación por videograbación", type: "Autenticación por videograbación", content: { name: "Autenticación por videograbación", type: "Autenticación por videograbación", source: video.url, alt: 'videograbación', extension: video.extension}})

          this.setVideoToSee(abbreviation);
          this.setVideoInDocuments(abbreviation, response.data.response.videos[0]);
        }
        this.isLoading = false;
      }).catch((error) => {
        console.log("🚀 ~ file: RevisionSolicitudesIngreso.vue:4935 ~ returnthis.videoClassObject.getVideo ~ error", error)
        this.isLoading = false;
      });
    },
    setVideoToSee(abbreviation){
      this.documentsToSee.push(
        { name: documentsType[abbreviation], value: abbreviation }
      );
    },
    setVideoInDocuments(abbreviation, video) {
      this.documents[abbreviation].push({
        document_type: abbreviation,
        document_name: video.document_name,
        extension: video.extension,
        url: video.url
      });
    },
    setDocumentsToSee(documents) {
      try {
        for (let i = 0; i < documents.length; i++) {
          if (this.documentsToSee.length > 0) {
            let index = this.documentsToSee.findIndex(d => d.value === documents[i].abbreviation);
            if (index != -1) {
            continue;
            }
          }
          this.documentsToSee.push(
            { name: documentsType[documents[i].abbreviation], value: documents[i].abbreviation }
          );
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    setDocuments(documents){
      try {
        let b64Data = "";
        let contentType = "application/pdf";
        for (let i = 0; i < documents.length; i++) {
          b64Data = documents[i].doument_base64;
          let blob = this.b64toBlob(b64Data, contentType);
          let blobUrl = URL.createObjectURL(blob);
          let document_type = documents[i].abbreviation;

          this.documents[document_type].push({
            document_type: documents[i].abbreviation,
            document_name: documents[i].document_name,
            extension: documents[i].extension,
            url: blobUrl
          });
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    addUpperCase(value){
      if(value != "" && value != null && value != undefined){
        value = value.toUpperCase();
      }
      return value
    },
    formatDate(value) {
      if  (!value)  {
        return  "";
      }
      var d = new Date(value)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [day, month, year].join('/');
    },
    orderDateByDayMonthYear(date){
      return moment(date).format("DD/MM/YYYY");
    },
    establisDateMaxToday(){
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1; //January is 0!
      var yyyy = today.getFullYear();
      if(dd<10){
          dd='0'+dd
      } 
      if(mm<10){
          mm='0'+mm
      } 

      today = yyyy+'-'+mm+'-'+dd + 'T10:00:00.000';
      this.maxDate = today;
    },
    establishYearMinDatePicker() {
      var today = new Date();
      var yyyy = today.getFullYear()-100;

      this.minYearDatePicker = yyyy;
    },
    b64toBlob (b64Data, contentType='', sliceSize=512) {
      let byteCharacters = atob(b64Data);
      let byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      let blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    getArrayBeneficiaries(list) {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }

      for (let i = 0; i < index.length; i++) {
        list[index[i]].id = array.length + 1;
        array.push(list[index[i]]);
      }
      return array;
    },
    getFinalArrayList(array) {
      let finalArray = [];
      
      for (let i = 0; i < array.length; i++) {
        let dt = 
        finalArray.push({
          id: array[i].id,
          rfc: array[i].fonsnte_person.rfc,
          name: this.camelize(`${array[i].fonsnte_person.name} ${array[i].fonsnte_person.paternal_name}`),
          phone: array[i].fonsnte_person.contact_info.filter(({contact_type}) => contact_type === 'celular').length > 0 ? array[i].fonsnte_person.contact_info.filter(({contact_type}) => contact_type === 'celular')[0].value : "1111111111",
          email: array[i].fonsnte_person.contact_info.filter(({contact_type}) => contact_type === 'correo').length > 0 ? array[i].fonsnte_person.contact_info.filter(({contact_type}) => contact_type === 'correo')[0].value : "correo@gmail.com",
          savingAmount: array[i].saving_amount == "" ? "0" : array[i].saving_amount,
          occupation: this.camelize(array[i].fonsnte_person.public_office_name),
          date : this.formatDate(array[i].created_at),
          status: array[i].status
        })
      }
      return finalArray;
    },
    camelize(str) {
      let response = ""
      if (str != "") {
        str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function(match) {
          return match.charAt(match.length-1).toUpperCase();
        });
        response = str.charAt(0).toUpperCase() + str.substring(1);
        return response.replace(/([A-Z])/g, ' $1').trim(); 
      }
      return "";
    },
    saveComment() {
      var swal = require('sweetalert2');

      let currentArray = [];
      let currentList = this.admission_request_review.admission_request_review.review_information.documents[this.checkboxSelected].comments;
      let updatedList = "";

      if (this.comment.length < 20) {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "La observación debe de contener más de 19 caracteres",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });
        return;
      }

      if (this.comment == "") {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "Favor de escribir una observación",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });
        return;
      }
      currentArray = this.convertListToArray(currentList);

      currentArray.push({
        id: "",
        comment: this.comment,
      });
      
      updatedList = Object.assign({}, currentArray);
      this.admission_request_review.admission_request_review.review_information.documents[this.checkboxSelected].comments = updatedList;
      this.setToLocalStorage("request_review_"+this.getRequestIdFromParams(), this.admission_request_review);
      this.comment = "";
      this.showModalComments = false;
    },
    saveCommentHistory(title_history, comment_history) {
      var swal = require('sweetalert2');
      let currentArray = [];

      let currentList = this.admission_request_review.admission_request_review.review_information.review_comments_attributes;
      let allDate = this.getCurrentDateAndHour(new Date());
      let updatedList = "";

      if (title_history == "") {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "Favor de escribir un título",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });
        return false;
      }

      if (comment_history == "") {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "Favor de escribir un comentario",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });
        return false;
      }

      if (comment_history.length < 20) {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "La observación debe de contener más de 19 caracteres",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });
        return false;
      }

      
      currentArray = this.convertListToArray(currentList);
      
      currentArray.push({
        id: "",
        comment: comment_history,
        status: "activo",
        added_comment_date: allDate.date,
        fonsnte_user_id: this.userId,
      });


      updatedList = Object.assign({}, currentArray);
      this.admission_request_review.admission_request_review.review_information.review_comments_attributes = updatedList;
      this.list_observation_history = currentArray;
      this.setToLocalStorage("request_review_"+this.getRequestIdFromParams(), this.admission_request_review);
      this.cleanControlsAddComments();
      return true;
    },
    cancelDatosDireccion() {
      this.admission_request_update = JSON.parse(JSON.stringify(this.admission_request));
      this.getInfoAdressByPostalCodeInformacionPersonal(this.admission_request_update.admission_information.adress_attributes.postal_code);
      this.showModalDatosPersonalesDireccion = false;
    },
    cancelDatosPersonales() {
      this.admission_request_update = JSON.parse(JSON.stringify(this.admission_request));
      this.showModalDatosPersonales = false
    },
    cancelDatosLaborales(clientClassification) {
      this.active_since_datos_laborales = this.datetimeValidatorObjectClass.normalizeDateFormat(this.admission_request.admission_information.work_info_attributes.active_since)

      let public_office_attributes_array = this.convertListToArray(this.admission_request.admission_information.public_office_attributes);

      this.has_had_public_positions = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions
      this.positions_name = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].positions_name
      this.start_date_public_positions = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].start_date_public_positions) : "";
      this.end_date_public_positions = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].end_date_public_positions) : "";

      this.has_had_public_positions_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions
      this.positions_name_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].positions_name
      this.start_date_public_positions_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].start_date_public_positions) : "";
      this.end_date_public_positions_familiar = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions ? this.datetimeValidatorObjectClass.normalizeDateFormat(public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].end_date_public_positions) : "";

      // this.start_date_public_positions = this.orderDateByDayMonthYear(this.admission_request_update.admission_information.public_office_attributes.start_date_public_positions);
      // this.end_date_public_positions = this.orderDateByDayMonthYear(this.admission_request_update.admission_information.public_office_attributes.end_date_public_positions);
      this.admission_request_update = JSON.parse(JSON.stringify(this.admission_request));
      this.getInfoAdressByPostalCodeDatosLaborales(this.admission_request.admission_information.workplace_attributes.adress_attributes.postal_code);
      if (["businessman","educator"].includes(clientClassification)) {
        this.showModalDatosLaborales = false;  
      } else if (clientClassification == "cabsa_employee") {
        this.showModalDatosLaboralesMiembros = false;  
      }
    },
    cancelInfoEconomica() {
      this.admission_request_update = JSON.parse(JSON.stringify(this.admission_request));
      this.undefinedTerm = this.admission_request_update.admission_information.saving_registration_attributes.number_of_contribution > 0 ? false : true;
      this.showModalInfoEconomicaYAhorro = false;
    },
    cancelBeneficiarios() {
      this.admission_request_update = JSON.parse(JSON.stringify(this.admission_request));
      this.list_beneficiaries = this.getArrayBeneficiaries(this.admission_request_update.admission_information.beneficiaries_attributes);
      this.showModalBeneficiarios = false;
      this.cleanControls();
    },
    getInfoAdressByPostalCodeInformacionPersonal(postalCode) {
      if (postalCode != null && new String(postalCode).length == 5) {
        this.admission_request_update.admission_information.postal_code = postalCode
        this.getInfoAdressByPostalCode(new String(postalCode)).then((response) => {
          if (response.data.ERROR != undefined) {
            this.admission_request_update.admission_information.adress_attributes.municipality = "";
            this.admission_request_update.admission_information.adress_attributes.state = "";
            this.admission_request_update.admission_information.adress_attributes.city = "";
            this.admission_request_update.admission_information.municipality = "";
            this.admission_request_update.admission_information.state = "";
            this.admission_request_update.admission_information.city = "";
            return;
          }
          this.list_colonys_datos_personales = response.data.colonias
          this.admission_request_update.admission_information.adress_attributes.municipality = response.data.municipio.nombre != null ? response.data.municipio.nombre : ""
          this.admission_request_update.admission_information.adress_attributes.state = response.data.estado.nombre != null ? response.data.estado.nombre : ""
          this.admission_request_update.admission_information.municipality = response.data.municipio.nombre != null ? response.data.municipio.nombre : ""
          this.admission_request_update.admission_information.state = response.data.estado.nombre != null ? response.data.estado.nombre : ""

          if(response.data.ciudad.nombre != null){
            this.admission_request_update.admission_information.adress_attributes.city = response.data.ciudad.nombre;
            this.admission_request_update.admission_information.city = response.data.ciudad.nombre;
          }
          else{
            this.admission_request_update.admission_information.adress_attributes.city = "N/A"
            this.admission_request_update.admission_information.city = "N/A"
          }
        });
      } else {
        this.list_colonys_datos_personales = [];
        this.admission_request_update.admission_information.adress_attributes.suburb = "";
        this.admission_request_update.admission_information.adress_attributes.municipality = "";
        this.admission_request_update.admission_information.adress_attributes.state = "";
        this.admission_request_update.admission_information.adress_attributes.city = "";
        this.admission_request_update.admission_information.suburb = "";
        this.admission_request_update.admission_information.municipality = "";
        this.admission_request_update.admission_information.state = "";
        this.admission_request_update.admission_information.city = "";
      }
    },
    getInfoAdressByPostalCodeDatosLaborales(postalCode) {
      if (postalCode != null && new String(postalCode).length == 5) {
        console.log('Obteniendo código postal 1:', new String(postalCode).length );
        this.getInfoAdressByPostalCode(new String(postalCode)).then((response) => {
          if (response.data.ERROR != undefined) {
            this.admission_request_update.admission_information.workplace_attributes.adress_attributes.municipality = "";
            this.admission_request_update.admission_information.workplace_attributes.adress_attributes.state = "";
            this.admission_request_update.admission_information.workplace_attributes.adress_attributes.city = "";
            return;
          }
          this.list_colonys_datos_laborales = response.data.colonias
          this.admission_request_update.admission_information.workplace_attributes.adress_attributes.municipality = response.data.municipio.nombre != null ? response.data.municipio.nombre : ""
          this.admission_request_update.admission_information.workplace_attributes.adress_attributes.state = response.data.estado.nombre != null ? response.data.estado.nombre : ""

          if(response.data.ciudad.nombre != null){
            this.admission_request_update.admission_information.workplace_attributes.adress_attributes.city = response.data.ciudad.nombre;
          }
          else{
            this.admission_request_update.admission_information.workplace_attributes.adress_attributes.city = "N/A"
          }
        });
      } else {
        this.list_colonys_datos_laborales = [];
        this.admission_request_update.admission_information.workplace_attributes.adress_attributes.suburb = "";
        this.admission_request_update.admission_information.workplace_attributes.adress_attributes.municipality = "";
        this.admission_request_update.admission_information.workplace_attributes.adress_attributes.state = "";
        this.admission_request_update.admission_information.workplace_attributes.adress_attributes.city = "";
      }
    },
    getInfoAdressByPostalCode(postalCode){
      return this.axios.post(this.multiApi + "/Api/Catalogo/Sepomex/obtener_colonias", {
        contenido: {
          codigo_postal : postalCode,
          colonia : ""
        },
      }, 
      {
        auth: {
          username: "multicr",
          password: "*cabsa2018*",
        },
      },
      { 'Content-Type': 'application/json' })
      .then(response => {
        return response;
      }).catch(error => {
        return error;
      });
    },
    validateCP(postalCode) {
      var swal = require('sweetalert2');
      if (postalCode.toString() != "") {
        if (postalCode.toString() != null && postalCode.toString().length == 5) {
          this.axios.post(this.multiApi + "/Api/Catalogo/Sepomex/obtener_colonias", {
            contenido: {
              codigo_postal : postalCode.toString(),
              colonia : ""
            },
          }, 
          {
            auth: {
              username: "multicr",
              password: "*cabsa2018*",
            },
          },
          { 'Content-Type': 'application/json' })
          .then(response => {
            if (response.data.estado.nombre == "") {
              swal.fire({
                title: "Aviso",
                customClass: {
                  container: 'my-swal'
                },
                text: "Introduzca un código postal valido",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
            }
          }).catch(error => {
            swal.fire({
              title: "Aviso",
              customClass: {
                container: 'my-swal'
              },
              text: "Introduzca un código postal valido",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
          });
        } else {
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "Introduzca un código postal valido",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
        }
      }
    },
    convertToNumber(value) {
      let dt = Number(value.replace(/[^0-9.-]+/g,"")).toString();
      return Number(value.replace(/[^0-9.-]+/g,"")).toString();  
    },
    formatMoney(value){
      const options2 = { style: 'currency', currency: 'MXN' };
      
      const formatter = new Intl.NumberFormat('en-US', options2);

      var resultado = Number(value.replace(/[^0-9.-]+/g,"")).toString();
      
      if(!value.includes(".")){
          resultado = value + ".00"
      }

      if(!resultado.includes(",")){
          resultado = resultado.replace(",","")//formatter.format(value)
      }
      resultado = formatter.format(resultado)
      
      resultado = resultado.replace('MX$','')
      
      if (resultado =="NaN" || resultado < 0) {
          resultado = ""
      }

      return resultado
    },
    changePercentage(value){
      if(value.toString().includes('.')){
          value = parseFloat(value)
      }

      var value = parseInt(value)
      if(value < 1 || value > 100){
          value = 0
      }
      return value
    },
    getBeneficiarie(id){
      var obj_beneficiarie = null
      for (var i =0; i < this.list_beneficiaries.length; i++){
        if (this.list_beneficiaries[i].id === id) {
          obj_beneficiarie = this.list_beneficiaries[i]
          break;
        }
      }
      return obj_beneficiarie
    },
    modifyBeneficiarie(id){
      var obj_beneficiarie = this.getBeneficiarie(id);
      if(obj_beneficiarie != null){
        this.id_beneficiarie = obj_beneficiarie.id
        this.paternal_name_beneficiarie = obj_beneficiarie.paternal_name
        this.maternal_name_beneficiarie = obj_beneficiarie.maternal_name
        this.name_beneficiarie = obj_beneficiarie.name
        this.middle_name_beneficiarie = obj_beneficiarie.middle_name
        this.birthday_beneficiarie = this.datetimeValidatorObjectClass.normalizeDateFormat(obj_beneficiarie.birthday)
        this.percentage_beneficiarie = obj_beneficiarie.percentage
        this.relationship_beneficiarie = obj_beneficiarie.relationship.toUpperCase()
      }
    },
    cleanControls(){
      this.id_beneficiarie = 0;
      this.paternal_name_beneficiarie = "";
      this.maternal_name_beneficiarie = "";
      this.name_beneficiarie = "";
      this.middle_name_beneficiarie = "";
      this.birthday_beneficiarie = "";
      this.percentage_beneficiarie = "";
      this.relationship_beneficiarie = "";
    },
    cleanControlsRequestRejected(){
      this.reason_rejection = "";
      this.comment_rejected = "";
      this.showModalRejectRequest = false;
    },
    cleanControlsAddComments(){
      this.title_history = "";
      this.comment_history = "";
      this.showModalObservationHistoryAdd = false;
    },
    cleanControlsSpouseAttributes(){
      this.gender_spouse = "";
      this.rfc_spouse = "";
      this.name_spouse = "";
      this.middle_name_spouse = "";
      this.paternal_name_spouse = "";
      this.maternal_name_spouse = "";
    },
    cleanControlsSpouseAttributes(){
      this.start_date_public_positions = "";
      this.end_date_public_positions = "";
    },
    cancelModifyBeneficiarie() {
      this.cleanControls();
    },
    validateEmptyDateBeneficiarie(date){
      console.log("date:",date,date.toString());
      if (date.toString() == "" || date == null) {
          return true;
      }

      return false;
    },
    getMaxIdBeneficiarie() {
      let idArray = []
      if (this.list_beneficiaries.length > 0) {
          for (let i = 0; i < this.list_beneficiaries.length; i++) {
              idArray.push(this.list_beneficiaries[i].id);
          }
      } else {
          return 0
      }

      let max = idArray.max()
      return max
    },
    changeValueStateNac() {
      this.admission_request_update.admission_information.municipality = "";
      this.admission_request_update.admission_information.city_birth = "";
      this.list_cities_nac = []
    },
    changeValueMunicipalityNac() {
      this.admission_request_update.admission_information.city_birth = "";
    },
    addBeneficiarie(e){
      console.log("data:",this.birthday_beneficiarie);
      e.preventDefault();
      var swal = require('sweetalert2')
      this.isLoading = true
      if(this.percentage_beneficiarie == "0"){
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "El porcentaje del beneficiario no puede ser 0",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false
        return false;
      }

      if (this.validateEmptyDateBeneficiarie(this.birthday_beneficiarie)) {
        swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "Favor de seleccionar una fecha en Fecha de Nacimiento.",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false
        return false;
      }
      
      if(this.paternal_name_beneficiarie =="" && this.maternal_name_beneficiarie == ""){        
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text:
            "Favor de capturar por lo menos un apellido del beneficiario",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false
        return false;
      }

      var beneficiarie = {
        id: this.id_beneficiarie == 0 ? this.getMaxIdBeneficiarie() + 1 : this.id_beneficiarie,
        paternal_name: this.paternal_name_beneficiarie.trim(),
        maternal_name: this.maternal_name_beneficiarie.trim(),
        name: this.name_beneficiarie.trim(),
        middle_name: this.middle_name_beneficiarie != "" ? this.middle_name_beneficiarie.trim() : this.middle_name_beneficiarie,
        birthday: this.birthday_beneficiarie,
        percentage: this.percentage_beneficiarie,
        relationship: this.relationship_beneficiarie.toLowerCase(),
        status: "activo"
      }
      
      var addBeneficiariee = true
      if(this.getPercentageBeneficiarie() > 100){
        addBeneficiariee = false
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "El porcentaje entre todos los beneficiarios debe ser 100%",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false
        this.cleanControls();
      }

      if(addBeneficiariee){
        this.isLoading = false
        if (this.id_beneficiarie != 0) {
          this.modifyBeneficiarieArray(beneficiarie);
        } else {
          this.list_beneficiaries.push(beneficiarie);
          let list_beneficiaries_object = Object.assign({}, this.list_beneficiaries);
          this.admission_request_update.admission_information.beneficiaries_attributes = list_beneficiaries_object;
        }
        this.cleanControls();
      }
    },
    modifyBeneficiarieArray(beneficiare){
      let index = this.list_beneficiaries.findIndex(b => b.id === beneficiare.id);
      this.list_beneficiaries[index] = beneficiare

      let list_beneficiaries_object = Object.assign({}, this.list_beneficiaries);
      this.admission_request_update.admission_information.beneficiaries_attributes = list_beneficiaries_object;
    },
    getPercentageBeneficiarie(){
      var porcentage = 0
      if(this.list_beneficiaries.length > 0){
        for (let objBeneficiarie of this.list_beneficiaries) {
          if (objBeneficiarie.id != this.id_beneficiarie) {
            porcentage += parseInt(objBeneficiarie.percentage)
          }
        }
      }
      return porcentage + parseInt(this.percentage_beneficiarie)
    },
    removeBeneficiarie(fullName, dateBirth, percentage, isModify){
      var swal = require('sweetalert2')
      for (var i =0; i < this.list_beneficiaries.length; i++){
        if (this.list_beneficiaries[i].name+this.list_beneficiaries[i].middle_name+this.list_beneficiaries[i].paternal_name+
        this.list_beneficiaries[i].maternal_name == fullName && this.list_beneficiaries[i].birthday == dateBirth && 
        this.list_beneficiaries[i].percentage == percentage) 
        {   
          if(isModify){
              this.list_beneficiaries.splice(i,1);
          }
          else
          {
            swal.fire({
              title: "Aviso",
              customClass: {
                container: 'my-swal'
              },
              text: "¿Desea eliminar el beneficiario?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "SI",
              cancelButtonText: "NO",
              confirmButtonColor: '#FEB72B',
              cancelButtonColor: '#FEB72B',
            })
            .then((result) => {
              if (result.value) {
                this.list_beneficiaries.splice(i,1);
                this.cleanControls();
              }
            });
          }
          break;
        }
      }
    },
    removeMask(phone) {
      if (phone.includes("(")) {
        phone = phone.replace("(", "");
      }
      if (phone.includes(")")) {
        phone = phone.replace(")", "");
      }
      if (phone.includes("-")) {
        phone = phone.replace("-", "");
      }
      if (phone.includes(" ")) {
        phone = phone.replace(" ", "");
      }
      return phone;
    },
    orderDateByYearMonthDay(date, typeSplit){
      return moment(date.split(' ')[0].split(typeSplit).reverse().join("-")).format("YYYY-MM-DD");
    },
    setNewAdmissionRequest() {
      this.admission_request = JSON.parse(JSON.stringify(this.admission_request_update));
    },
    updateDatosPersonales(e) {
      e.preventDefault();
      var swal = require('sweetalert2');

      this.admission_request_update.admission_information.birthday = this.birthday_datos_personales

      let contact_info = this.convertListToArray(this.admission_request_update.admission_information.contact_info_attributes);
      let email = contact_info.find(c => c.contact_type === "correo").value;
      if(this.email_datos_personales_update != email){
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          html: "No es posible modificar el correo, favor de cancelar solicitud y dar de alta de nuevo.<br><br> También puede contactar al area de desarrollo.",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false;
        return
      }

      let telephone_number = contact_info.find(element => element.contact_type == "telefono particular");
      let cellphone_number = contact_info.find(element => element.contact_type == "celular");
      if(telephone_number && cellphone_number){
        if(telephone_number.value == cellphone_number.value){
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "El número de teléfono debe ser diferente al número de celular",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });

          this.isLoading = false;
          return
        }
      }

      if (this.phone_datos_personales_update != "") {
        if (this.phone_datos_personales_update.length < 10) {
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "El Teléfono debe contener 10 dígitos",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          this.isLoading = false;
          return
        }
      }

      if (this.cellphone_datos_personales_update.length < 10) {
        swal.fire({
          title: "Aviso",
            customClass: {
            container: 'my-swal'
          },
          text: "El celular debe contener 10 dígitos",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false;
        return
      }

      if (this.admission_request_update.admission_information.marital_status == 'CASADO(A)' || this.admission_request_update.admission_information.marital_status == 'CONCUBINATO') {
        var correctRfc = this.validateInputRfc(this.rfc_spouse)

        if (this.paternal_name_spouse == "" && this.maternal_name_spouse == "") {
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "Favor de capturar por lo menos un apellido en Datos del Cónyuge",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          this.isLoading = false;
          return;
        }

        if (!correctRfc) {
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "Favor de verificar el RFC del Cónyuge",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          this.isLoading = false;
          return;
        }
      }

      if (this.admission_request_update.admission_information.marital_status != 'CASADO(A)') {
        this.admission_request_update.admission_information.regime = "";
      }
      if (this.admission_request_update.admission_information.marital_status != 'CASADO(A)' && this.admission_request_update.admission_information.marital_status != 'CONCUBINATO') {
        delete this.admission_request_update.admission_information.spouse_attributes;
      } else {
        if (this.admission_request_update.admission_information.spouse_attributes == undefined) {
          this.admission_request_update.admission_information.spouse_attributes = {};
        }
        this.admission_request_update.admission_information.name = this.admission_request_update.admission_information.name.trim();
        this.admission_request_update.admission_information.middle_name = this.admission_request_update.admission_information.middle_name.trim();
        this.admission_request_update.admission_information.paternal_name = this.admission_request_update.admission_information.paternal_name.trim();
        this.admission_request_update.admission_information.maternal_name = this.admission_request_update.admission_information.maternal_name.trim();

        this.admission_request_update.admission_information.spouse_attributes.gender = this.gender_spouse.trim();
        this.admission_request_update.admission_information.spouse_attributes.rfc = this.rfc_spouse.trim();
        this.admission_request_update.admission_information.spouse_attributes.name = this.name_spouse.trim();
        this.admission_request_update.admission_information.spouse_attributes.middle_name = this.middle_name_spouse.trim();
        this.admission_request_update.admission_information.spouse_attributes.paternal_name = this.paternal_name_spouse.trim();
        this.admission_request_update.admission_information.spouse_attributes.maternal_name = this.maternal_name_spouse.trim();
      }
      this.setNewAdmissionRequest();
      this.setContactInfoDatosPersonales();
      this.showModalDatosPersonales = false;
      this.setToLocalStorage("request_"+this.admission_request_update.id, this.admission_request);
    },
    updateDatosPersonalesDireccion(e) {
      e.preventDefault();
      this.admission_request_update.admission_information.adress_attributes.street = this.admission_request_update.admission_information.adress_attributes.street.trim();
      this.admission_request_update.admission_information.street = this.admission_request_update.admission_information.street.trim();
      this.admission_request_update.admission_information.adress_attributes.between_street_one = this.admission_request_update.admission_information.adress_attributes.between_street_one.trim();
      this.admission_request_update.admission_information.adress_attributes.between_street_two = this.admission_request_update.admission_information.adress_attributes.between_street_two.trim();
      this.admission_request_update.admission_information.int_number = this.admission_request_update.admission_information.int_number.trim();

      this.admission_request_update.admission_information.adress_attributes.residence_start = this.datetimeValidatorObjectClass.normalizeDateFormat(this.residence_start);
      this.admission_request_update.admission_information.residence_start = this.datetimeValidatorObjectClass.normalizeDateFormat(this.residence_start);
      this.admission_request_update.admission_information.adress_attributes.residence_status = this.residence_status
      this.admission_request_update.admission_information.residence_status = this.residence_status

      this.setNewAdmissionRequest();
      this.getInfoAdressByPostalCodeInformacionPersonal(this.admission_request_update.admission_information.adress_attributes.postal_code);
      this.showModalDatosPersonalesDireccion = false;
      this.setToLocalStorage("request_"+this.admission_request_update.id, this.admission_request);
    },
    updateDatosLaborales(e) {
      e.preventDefault();
      var swal = require('sweetalert2');
      let list_Date_error = this.validateEmptyDate();

      if (list_Date_error.length > 0) {
        swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            html: `<p>Favor de seleccionar una fecha en: <br/>
                    <span style="font-weight: bold; color:red">${list_Date_error.join(", ")}</span>
                    </p>`,
            icon: "warning",
            confirmButtonColor: '#FEB72B',
        });

        this.isLoading = false
        return;
      }

      if (this.admission_request_update.admission_information.work_info_attributes.laboral_status == "ACTIVO") {
        if(this.validateAlphanumericValue(this.admission_request_update.admission_information.workplace_attributes.name)) {
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "La Escuela no puede contener sólo numeros",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          this.isLoading = false
          return
        }
      }
      if (this.phone_datos_laborales_update != "") {
        if (this.admission_request_update.admission_information.work_info_attributes.laboral_status == "ACTIVO") {
          if (this.phone_datos_laborales_update.length < 10) {
            swal.fire({
              title: "Aviso",
              customClass: {
                container: 'my-swal'
              },
              text: "El Teléfono debe contener 10 dígitos",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.isLoading = false
            return
          } 
        }
      }

      let public_office_attributes_array = this.convertListToArray(this.admission_request_update.admission_information.public_office_attributes);

      if(public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions == "1"){
        if(public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].positions_name != "") {
          if (public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].start_date_public_positions == "" || public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].end_date_public_positions == "") {
            swal.fire({
              title: "Aviso",
              customClass: {
                container: 'my-swal'
              },
              text: "Favor de capturar las fechas del Cargo Público del titular",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.isLoading = false
            return
          }
          if(this.validateAlphanumericValue(public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].positions_name)) {
            swal.fire({
              title: "Aviso",
              customClass: {
                container: 'my-swal'
              },
              text: "El Cargo Público  del familiar no puede contener sólo numeros",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.isLoading = false
            return
          }
        } else {
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "Favor de capturar el Cargo Público del titular",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          this.isLoading = false
          return
        }
      }

      if(public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions == "1"){
        if(public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].positions_name != "") {
          if (public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].start_date_public_positions == "" || public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].end_date_public_positions == "") {
            swal.fire({
              title: "Aviso",
              customClass: {
                container: 'my-swal'
              },
              text: "Favor de capturar las fechas del Cargo Público del familiar",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.isLoading = false
            return
          }
          if(this.validateAlphanumericValue(public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].positions_name)) {
            swal.fire({
              title: "Aviso",
              customClass: {
                container: 'my-swal'
              },
              text: "El Cargo Público del familiar no puede contener sólo numeros",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.isLoading = false
            return
          }
        } else {
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "Favor de capturar el Cargo Público del familiar",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          this.isLoading = false
          return
        }
      }
      
      this.admission_request_update.admission_information.workplace_attributes.name = this.admission_request_update.admission_information.workplace_attributes.name.trim();
      this.admission_request_update.admission_information.workplace_attributes.adress_attributes.street = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.street.trim();

      this.admission_request_update.admission_information.workplace_attributes.postal_code = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.postal_code;
      this.admission_request_update.admission_information.workplace_attributes.city = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.city;
      this.admission_request_update.admission_information.workplace_attributes.street = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.street;
      this.admission_request_update.admission_information.workplace_attributes.ext_number = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.ext_number;
      this.admission_request_update.admission_information.workplace_attributes.state = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.state;
      this.admission_request_update.admission_information.workplace_attributes.suburb = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.suburb;
      this.admission_request_update.admission_information.workplace_attributes.municipality = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.municipality;

      this.admission_request_update.admission_information.work_info_attributes.active_since = this.active_since_datos_laborales
      console.log("this.active_since_datos_laborales",this.active_since_datos_laborales)
      
      this.setNewAdmissionRequest();
      this.getInfoAdressByPostalCodeDatosLaborales(this.admission_request_update.admission_information.workplace_attributes.adress_attributes.postal_code);
      this.showModalDatosLaborales = false;
      this.setToLocalStorage("request_"+this.admission_request_update.id, this.admission_request);
    },
    updateDatosLaboralesMiembros(e) {
      e.preventDefault();
      var swal = require('sweetalert2');
      let list_Date_error = this.validateEmptyDate();

      if (list_Date_error.length > 0) {
        swal.fire({
            title: "Aviso",
              customClass: {
              container: 'my-swal'
            },
            html: `<p>Favor de seleccionar una fecha en: <br/>
                    <span style="font-weight: bold; color:red">${list_Date_error.join(", ")}</span>
                    </p>`,
            icon: "warning",
            confirmButtonColor: '#FEB72B',
        });

        this.isLoading = false
        return;
      }

      if (this.phone_datos_laborales_update.length > 0 && this.phone_datos_laborales_update.length < 10) {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "El Teléfono debe contener 10 dígitos",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false
        return
      }

      this.admission_request_update.admission_information.work_info_attributes.business_name = this.admission_request_update.admission_information.work_info_attributes.business_name.trim();
      
      if(this.admission_request.assisted_request){
        this.admission_request_update.admission_information.work_info_attributes.employee_number = this.admission_request_update.admission_information.work_info_attributes.employee_number.trim();
      }else{
        this.admission_request_update.admission_information.work_info_attributes.employee_number[0] = this.admission_request_update.admission_information.work_info_attributes.employee_number[0].trim();
      }

      this.admission_request_update.admission_information.work_info_attributes.supervisor = this.admission_request_update.admission_information.work_info_attributes.supervisor.trim();
      this.admission_request_update.admission_information.workplace_attributes.street = this.admission_request_update.admission_information.workplace_attributes.street.trim();

      this.admission_request_update.admission_information.workplace_attributes.postal_code = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.postal_code;
      this.admission_request_update.admission_information.workplace_attributes.city = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.city;
      this.admission_request_update.admission_information.workplace_attributes.street = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.street;
      this.admission_request_update.admission_information.workplace_attributes.ext_number = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.ext_number;
      this.admission_request_update.admission_information.workplace_attributes.state = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.state;
      this.admission_request_update.admission_information.workplace_attributes.suburb = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.suburb;
      this.admission_request_update.admission_information.workplace_attributes.municipality = this.admission_request_update.admission_information.workplace_attributes.adress_attributes.municipality;

      this.admission_request_update.admission_information.work_info_attributes.active_since = this.active_since_datos_laborales
      
      this.setNewAdmissionRequest();
      this.getInfoAdressByPostalCodeDatosLaborales(this.admission_request_update.admission_information.workplace_attributes.adress_attributes.postal_code);
      this.showModalDatosLaboralesMiembros = false;
      this.setToLocalStorage("request_"+this.admission_request_update.id, this.admission_request);
    },
    updateInfoEconomicaYAhorro(e) {
      e.preventDefault();
      var swal = require('sweetalert2');
      var monthlyIncome = parseFloat(this.monthly_income.includes(",") ? this.monthly_income.replaceAll(',','') : this.monthly_income)
      var monthlyLiquidity = parseFloat(this.liquidity.includes(",") ? this.liquidity.replaceAll(',','') : this.liquidity)

      if (!this.undefinedTerm) {
        if (this.admission_request_update.admission_information.saving_registration_attributes.number_of_contribution <= 0) {
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "El Plazo de Ahorro no puede ser 0",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return;
        }
      }
      if (this.clientClassification != "health_worker" && monthlyIncome == 0) {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "Ingreso bruto mensual no puede ser 0",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        return;
      }

      if (this.clientClassification != "health_worker" && monthlyLiquidity == 0) {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "Liquidez mensual no puede ser 0",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        return;
      }

      if(monthlyLiquidity > monthlyIncome){
        this.errors = []
        this.errors.push("Liquidez mensual no puede ser mayor que ingreso bruto")
        swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: this.errors,
            icon: "warning",
            confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false
        return false
      }

      if (this.admission_request_update.admission_information.saving_registration_attributes.payment_method == "DOMICILIACIÓN") {
        let bank_account_attributes_array = this.convertListToArray(this.admission_request_update.admission_information.bank_account_attributes);
        let index = 0;

        if (bank_account_attributes_array[index].account_type == "cuenta clabe") {
          if (bank_account_attributes_array[index].account_number.length < 18) {
            swal.fire({
              title: "Aviso",
              customClass: {
                container: 'my-swal'
              },
              text:
                "La Cuenta Clabe debe contener 18 dígitos",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.isLoading = false;
            return;
          }
        }
        
        const cardNumber = bank_account_attributes_array[index].account_number.toString().replaceAll("-", "");
        if (bank_account_attributes_array[index].account_type == "numero de tarjeta") {
          if (bank_account_attributes_array[index].bank_name == "AMERICAN EXPRESS" && cardNumber.length != 15) {
            swal.fire({
              title: "Aviso",
              customClass: {
                container: 'my-swal'
              },
              text:
                "El número de Tarjeta debe contener 15 dígitos",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.isLoading = false;
            return;
          }else if (bank_account_attributes_array[index].bank_name != "AMERICAN EXPRESS" && cardNumber.length < 16) {
            swal.fire({
              title: "Aviso",
              customClass: {
                container: 'my-swal'
              },
              text:
                "El número de Tarjeta debe contener 16 dígitos",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.isLoading = false;
            return;
          } else {
            if (bank_account_attributes_array[index].expiration_year.length < 2) {
              swal.fire({
                title: "Aviso",
                customClass: {
                  container: 'my-swal'
                },
                text:
                  "El Año de Expiración debe contener 2 dígitos",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              this.isLoading = false;
              return;
            }
            if (bank_account_attributes_array[index].expiration_month <= 0) {
              swal.fire({
                title: "Aviso",
                customClass: {
                  container: 'my-swal'
                },
                text:
                  "El Mes de Expiración de la Tarjeta debe ser mayor a 0",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              this.isLoading = false;
              return;
            }
            if (bank_account_attributes_array[index].expiration_month.length < 2) {
              swal.fire({
                title: "Aviso",
                customClass: {
                  container: 'my-swal'
                },
                text:
                  "El Mes de Expiración debe contener 2 dígitos",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              this.isLoading = false;
              return;
            }
            if (bank_account_attributes_array[index].expiration_month > 12) {
              swal.fire({
                title: "Aviso",
                customClass: {
                  container: 'my-swal'
                },
                text:
                  "El Mes de Expiración de la Tarjeta no puede ser mayor a 12",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              this.isLoading = false;
              return;
            }
            if (!this.getLastMonthsYear().includes(bank_account_attributes_array[index].expiration_month)) {
              swal.fire({
                title: "Aviso",
                customClass: {
                  container: 'my-swal'
                },
                text:
                  "La tarjeta introducida ya ha expirado",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              this.isLoading = false;
              return;
            }
          }

          if(this.admission_request_update.admission_information.bank_account_attributes[0].expiration_month != "" && this.admission_request_update.admission_information.bank_account_attributes[0].expiration_year != ""){
            let cardYear = parseInt(this.admission_request_update.admission_information.bank_account_attributes[0].expiration_year);
            let cardMonth = parseInt(this.admission_request_update.admission_information.bank_account_attributes[0].expiration_month);
            let currentYear = parseInt(moment().format('YY'));
            let currentMonth = parseInt(moment().format('MM'));
            
            if(cardYear <= currentYear && cardMonth <= currentMonth){
              swal.fire({
                title: "Aviso",
                customClass: {
                  container: 'my-swal'
                },
                text: "La tarjeta ha expirado",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });

              this.isLoading = false;
              return;
            }
          }
        }
      }

      let saving_amount = this.fomartNumber(this.saving_amount);
      if ( saving_amount < 50 ){
        swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "El importe de ahorro debe mayor o igual a 50",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false
        return false
      }

      if ( saving_amount >= 100 && (saving_amount%50) != 0 ){
        swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "El importe de ahorro debe ser multiplo de 50",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false
        return false
      }

      this.admission_request_update.admission_information.saving_registration_attributes.amount = this.saving_amount

      this.admission_request_update.admission_information.salary_info_attributes.monthly_income = monthlyIncome;
      this.admission_request_update.admission_information.salary_info_attributes.liquidity = monthlyLiquidity;

      this.setNewAdmissionRequest();
      this.showModalInfoEconomicaYAhorro = false;
      this.setToLocalStorage("request_"+this.admission_request_update.id, this.admission_request);
    },
    changePhoneDatosLaborales(phone) {
      let contact_info = this.convertListToArray(this.admission_request_update.admission_information.workplace_attributes.contact_info_attributes);
      let contactType = "telefono"
      let value = this.removeMask(phone);

      let object_contact_info = null
      if (contact_info.map((res) => res.contact_type === contactType).includes(true)){
        let index = contact_info.findIndex(c => c.contact_type === contactType);
        contact_info[index].value = value
        object_contact_info = Object.assign({}, contact_info);
        this.admission_request_update.admission_information.workplace_attributes.contact_info_attributes = object_contact_info;
      }else{
        contact_info.push({value: value, status: 'activo', extension: '', contact_type: contactType})
        object_contact_info = Object.assign({}, contact_info);
        this.admission_request_update.admission_information.workplace_attributes.contact_info_attributes = object_contact_info;
      }
      
      contact_info = this.convertListToArray(this.admission_request_update.admission_information.workplace_attributes.contact_info_attributes);
      object_contact_info = Object.assign({}, contact_info.filter((res) => res.value !== ''));
      this.admission_request_update.admission_information.workplace_attributes.contact_info_attributes = object_contact_info;
    },
    changeDatosContactoPersonales(contactType, value) {
      var swal = require('sweetalert2');

      let contact_info = this.convertListToArray(this.admission_request_update.admission_information.contact_info_attributes);
      if(["celular","telefono"].includes(contactType.split(' ')[0])){
        value = this.removeMask(value)
      }

      let object_contact_info = null
      if (contact_info.map((res) => res.contact_type === contactType).includes(true)){
        let index = contact_info.findIndex(c => c.contact_type === contactType);
        contact_info[index].value = value
        object_contact_info = Object.assign({}, contact_info);
        this.admission_request_update.admission_information.contact_info_attributes = object_contact_info;
      }else{
        contact_info.push({value: value, status: 'activo', extension: '', contact_type: contactType})
        object_contact_info = Object.assign({}, contact_info);
        this.admission_request_update.admission_information.contact_info_attributes = object_contact_info;
      }
      
      contact_info = this.convertListToArray(this.admission_request_update.admission_information.contact_info_attributes);
      
      let telephone_number = contact_info.find(element => element.contact_type == "telefono particular");
      let cellphone_number = contact_info.find(element => element.contact_type == "celular");
      if(telephone_number && cellphone_number){
        if(telephone_number.value == cellphone_number.value){
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "El número de teléfono debe ser diferente al número de celular",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
        }
      }

      object_contact_info = Object.assign({}, contact_info.filter((res) => res.value !== ''));
      this.admission_request_update.admission_information.contact_info_attributes = object_contact_info;
    },
    changeExtensionDatosLaborales(extension) {
      let contact_info = this.convertListToArray(this.admission_request_update.admission_information.workplace_attributes.contact_info_attributes);
      let contactType = "telefono"
      let value = extension;

      let object_contact_info = null
      if (contact_info.map((res) => res.contact_type === contactType).includes(true)){
        let index = contact_info.findIndex(c => c.contact_type === contactType);
        contact_info[index].extension = value
        object_contact_info = Object.assign({}, contact_info);
        this.admission_request_update.admission_information.workplace_attributes.contact_info_attributes = object_contact_info;
      }
    },
    changePublicOffice(type, node, value) {
      var swal = require('sweetalert2');
      let message = ""
      if (["start_date_public_positions","end_date_public_positions"].includes(node)){
        if ("titular" == type && moment(this.start_date_public_positions,'DD-MM-YYYY').isAfter(moment(this.end_date_public_positions,'DD-MM-YYYY'))){
          if(node == "start_date_public_positions"){ 
            this.start_date_public_positions = ""
            message = "La fecha inicio de cargo público no puede ser mayor o igual a la fecha final"
          }
          if(node == "end_date_public_positions"){ 
            this.end_date_public_positions = ""
            message = "La fecha final de cargo público no puede ser menor o igual a la fecha inicio"
          }
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: message,
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return;
        }

        if ("familiar" == type && moment(this.start_date_public_positions_familiar,'DD-MM-YYYY').isAfter(moment(this.end_date_public_positions_familiar,'DD-MM-YYYY'))){
          if(node == "start_date_public_positions"){ 
            this.start_date_public_positions_familiar = ""
            message = "La fecha inicio de cargo público familiar no puede ser mayor o igual a la fecha final"
          }
          if(node == "end_date_public_positions"){ 
            this.end_date_public_positions_familiar = ""
            message = "La fecha final de cargo público familiar no puede ser menor o igual a la fecha inicio"
          }
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: message,
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return;
        }
      }

      let public_office_attributes = this.convertListToArray(this.admission_request_update.admission_information.public_office_attributes);
      let index = public_office_attributes.findIndex(c => c.relationship === type);

      if (node == "start_date_public_positions" || node == "end_date_public_positions") {
        public_office_attributes[index][node] = value
      } else {
        if(node != "has_had_public_positions") {
          public_office_attributes[index][node] = value.trim();
        } else {
          public_office_attributes[index][node] = value;
        }
      }
      
      if (node == "has_had_public_positions") {
        if (!value) {
          public_office_attributes[index].positions_name = "";
          public_office_attributes[index].start_date_public_positions = "";
          public_office_attributes[index].end_date_public_positions = "";
        }
        if (type == "titular") {
          this.positions_name = ""
          this.start_date_public_positions = "";
          this.end_date_public_positions = "";
        } else if (type == "familiar"){
          this.positions_name_familiar = ""
          this.start_date_public_positions_familiar = "";
          this.end_date_public_positions_familiar = "";
        }
      }

      
      let object_public_office_attributes = Object.assign({}, public_office_attributes);
      this.admission_request_update.admission_information.public_office_attributes = object_public_office_attributes;
    },
    changeBankAccountAttributes(node, value) {
      let bank_account_attributes_array = this.convertListToArray(this.admission_request_update.admission_information.bank_account_attributes);
      let index = 0;

      bank_account_attributes_array[index][node] = value;
      
      let object_bank_account_attributes = Object.assign({}, bank_account_attributes_array);
      this.admission_request_update.admission_information.bank_account_attributes = object_bank_account_attributes;

      if(this.admission_request_update.admission_information.bank_account_attributes[0].account_type == 'numero de tarjeta' && this.admission_request_update.admission_information.bank_account_attributes[0].expiration_month != "" && this.admission_request_update.admission_information.bank_account_attributes[0].expiration_year != ""){
        let cardYear = parseInt(this.admission_request_update.admission_information.bank_account_attributes[0].expiration_year);
        let cardMonth = parseInt(this.admission_request_update.admission_information.bank_account_attributes[0].expiration_month);
        let currentYear = parseInt(moment().format('YY'));
        let currentMonth = parseInt(moment().format('MM'));
        
        if(cardYear <= currentYear && cardMonth <= currentMonth){
          var swal = require('sweetalert2');
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "La tarjeta ha expirado",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          this.expirationMonth = ""
        }
      }

      if (node == "account_type") {
        this.changeKeyType();
      }
    },
    changePrincipalOccupation() {
      this.admission_request_update.admission_information.work_info_attributes.business_name = "";
    },
    updateDatosBeneficiarios() {
      this.isLoading = true;
      var porcentage = 0;
      var correcto = true;
      this.cleanControls();
      var swal = require('sweetalert2');
      if(this.list_beneficiaries.length > 0){
        for (let objBeneficiarie of this.list_beneficiaries) {
          porcentage += parseInt(objBeneficiarie.percentage)
        }

        if(porcentage != 100){
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "El porcentaje entre todos los beneficiarios debe ser 100%",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          this.isLoading = false;
          correcto = false;
        }
        if (correcto) {
          this.setNewAdmissionRequest();
          this.showModalBeneficiarios = false;
          let list_beneficiaries_object = Object.assign({}, this.list_beneficiaries);
          this.admission_request_update.admission_information.beneficiaries_attributes = list_beneficiaries_object;
          this.setToLocalStorage("request_"+this.admission_request_update.id, this.admission_request_update);
          this.isLoading = false;
        }
      }else{
        this.setNewAdmissionRequest();
        this.showModalBeneficiarios = false;
        let list_beneficiaries_object = Object.assign({}, this.list_beneficiaries);
        this.admission_request_update.admission_information.beneficiaries_attributes = list_beneficiaries_object;
        this.setToLocalStorage("request_"+this.admission_request_update.id, this.admission_request_update);
        this.isLoading = false;
      }
    },
    validateAlphanumericValue(value){
        if(!/\D/.test(value.replace(/ /g, "").replace(".", ""))) {
            return true
        }
        return false
    },
    deleteIdFromBeneficiaries(array) {
      let newArray = this.convertListToArray(array);
      for (let i = 0; i < newArray.length; i++) {
        delete newArray[i].id
      }

      return Object.assign({}, newArray);
    },
    clearWorkPlace(workplace){
      workplace.city =  "";
      workplace.name =  "";
      workplace.state =  "";
      workplace.status =  "";
      workplace.street =  "";
      workplace.suburb =  "";
      workplace.country =  "";
      workplace.employer =  "";
      workplace.center_key =  "";
      workplace.ext_number =  "";
      workplace.int_number =  "";
      workplace.postal_code =  "";
      workplace.municipality =  "";
      workplace.adress_attributes = {};
      workplace.between_street_one = "";
      workplace.between_street_two = "";
      workplace.representative_name = "";
      workplace.contact_info_attributes = {};

      return workplace;
    },
    async saveReviewRequest() {
      let jsonLocalStorage = this.getFromLocalStorage("request_"+this.getRequestIdFromParams());
      if (jsonLocalStorage) {
        await this.acceptRequest("en_revision")
      }
    },
    async acceptRequest(status="aceptado") {
      this.isLoading = true;
      var swal = require('sweetalert2');

      let {havePendingDocuments, missingDocuments, modulesMissingToValidated} = this.documentsAndInfoValidated();
      
      this.admission_request_review.admission_request_review.review_information.documents = this.$refs.documentControlRef.documents;
      if(status == "aceptado"){
        if(havePendingDocuments) {
          this.isLoading = false;
          swal.fire({
            title: "Aviso",
            html: "Favor de terminar de validar los documentos requeridos antes de aceptar la solicitud: <br>" + this.getStructureMissingDocumentsList(missingDocuments),
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
          return;
        }

        if(modulesMissingToValidated){
          this.isLoading = false;
          swal.fire({
            title: "Aviso",
            html: "Favor de terminar de validar la información antes de aceptar la solicitud. ",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
          return;
        }
      }
      
      if(status=="en_revision" || (!havePendingDocuments && !modulesMissingToValidated)) {

        let json = this.getFinalObject("en_revision");
        json.change_for = status;
        delete json.admission_request_params.admission_information.saving_registration_attributes.terms;
        //delete json.admission_request_params.admission_information.client_classification;
        json.client_classification = this.clientClassification;
        json.admission_request_params.admission_information.beneficiaries_attributes = this.deleteIdFromBeneficiaries(json.admission_request_params.admission_information.beneficiaries_attributes);
 
        let config = {
          headers: {
            'Content-Type': 'application/json'
          },
        }

        json = await this.addInformationProductsToJson(status, json)

        this.createReview(false).then(response => {
          this.axios.post(this.ccapi + `/${this.business}/admission_request/create`, json, config)
          .then((response) => {
            this.isLoading = false;
            let jsonLocalStorage = this.getFromLocalStorage("request_"+this.getRequestIdFromParams());
            jsonLocalStorage.status = status;
            this.setToLocalStorage("request_"+this.getRequestIdFromParams(), jsonLocalStorage);

              if (status == "aceptado"){
                swal.fire({
                  title: "Aviso",
                  text: "La solicitud fue aceptada correctamente",
                  icon: "success",
                  confirmButtonColor: '#FEB72B',
                }).then(function() {
                  window.location.href = "/analyst/principal"
                });
              }
              
            }).catch(error => {
              this.isLoading = false;
              /* if(this.clientClassification){
                json.admission_request_params.admission_information.workplace_attributes.contact_info_attributes = contact_info;
              } */
              swal.fire({
                title: "Aviso",
                text: error.response.data.errors,
                icon: "error",
                confirmButtonColor: '#FEB72B',
              });
            });
          });
      } 
      
    },
    async addInformationProductsToJson(status, json){
      if (status == "aceptado"){
        
        await this.admissionRequestObject.getParameter(
          sessionStorage.getItem("login"),
          "pago parte social"
        ).then(response => {
          this.enrollment_fee_amount = response.data.response.parameter;
        });

        if(['businessman','health_worker'].includes(this.clientClassification)){
          this.employer = "Santander"
        }
        if(["cabsa_employee"].includes(this.clientClassification)){
          this.employer = "CABSA"
        }
        if(['educator','student','retired',''].includes(this.clientClassification)){
          this.employer = "SEC"
        }

        json.admission_request_params.admission_information.enrollment_information = {
          employer: this.employer,
          enrollment_fee_collection_method: json.admission_request_params.admission_information.saving_registration_attributes.payment_method,//"transferencia, nomina, domiciliacion y manual",
          enrollment_fee_payment_date: json.admission_request_params.admission_information.saving_registration_attributes.date_of_start,
          enrollment_fee_amount: this.enrollment_fee_amount,
          enrollment_fee_payment_term: 1,
          enrollment_fee_frequency: json.admission_request_params.admission_information.saving_registration_attributes.payment_frecuency//"quincenal,mensual,trimestral,semestral,anual"
        }

        json.admission_request_params.admission_information.saving_registration_attributes.employer = this.employer
      }
      return json
    },
    rejectRequest(reason_rejection, comment_rejected) {
      var swal = require('sweetalert2');

      if (reason_rejection == "" || comment_rejected == "") {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "Favor de completar el formulario",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });
        return;
      }

      if (comment_rejected.length < 20) {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "La observación debe de contener más de 19 caracteres",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });
        return;
      }

      if (!this.saveCommentHistory(reason_rejection, comment_rejected)) {
        return;
      }

      this.createReview(false).then(response => {
        let self = this;
        this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/cancel`, {
          token_auth: this.getToken(),
          admission_request_id: this.getRequestIdFromParams(),
          change_for: "rechazar"
        },
        { 'Content-Type': 'application/json' })
        .then(response => {
          this.isLoading = false
          let jsonLocalStorage = this.getFromLocalStorage("request_"+this.getRequestIdFromParams());
          jsonLocalStorage.status = "rechazada";
          this.setToLocalStorage("request_"+this.getRequestIdFromParams(), jsonLocalStorage);
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "La solicitud fue rechazada correctamente",
            icon: "success",
            confirmButtonColor: '#FEB72B',
          }).then(function() {
              window.location.href = "/analyst/principal"
          });
        }).catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errores
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "Ocurrió un error al rechazar la solicitud",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
        });
      });
    },
    cancelRequest(reason_cancel, comment_cancel) {
      var swal = require('sweetalert2');

      if (reason_cancel == "" || comment_cancel == "") {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "Favor de completar el formulario",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });
        return;
      }

      if (comment_cancel.length < 20) {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "La observación debe de contener más de 19 caracteres",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });
        return;
      }

      if (!this.saveCommentHistory(reason_cancel, comment_cancel)) {
        return;
      }

      this.createReview(false).then(response => {
        let self = this;
        this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/cancel`, {
          token_auth: this.getToken(),
          admission_request_id: this.getRequestIdFromParams(),
          change_for: "cancelar"
        },
        { 'Content-Type': 'application/json' })
        .then(response => {
          this.isLoading = false
          let jsonLocalStorage = this.getFromLocalStorage("request_"+this.getRequestIdFromParams());
          jsonLocalStorage.status = "cancelada";
          this.setToLocalStorage("request_"+this.getRequestIdFromParams(), jsonLocalStorage);
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "La solicitud fue cancelada correctamente",
            icon: "success",
            confirmButtonColor: '#FEB72B',
          }).then(function() {
              window.location.href = "/analyst/principal"
          });
        }).catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errores
          swal.fire({
            title: "Aviso",
            customClass: {
              container: 'my-swal'
            },
            text: "Ocurrió un error al cancelar la solicitud",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
        });
      });
    },
    getFinalObject(status) {
      let json = this.getFromLocalStorage("request_"+this.getRequestIdFromParams());
      let admission_information = json.admission_information;
      let finalObject = {
        token_auth: this.getToken(),
        admission_request_id: this.getRequestIdFromParams(),
        user_id: this.userId,
        person_id: json.foncabsa_person_id,
        change_for: status,
        admission_request_params: {
          admission_information
        }
      };

      return JSON.parse(JSON.stringify(finalObject));
    },
    getFinalObjectAdmissionRequestReview() {
      this.admission_request_review.admission_request_review.review_information.documents = this.$refs.documentControlRef.documents
      this.setToLocalStorage("request_review_"+this.getRequestIdFromParams(), this.admission_request_review);  
      let json = this.getFromLocalStorage("request_review_"+this.getRequestIdFromParams());
      let admission_request_review = json.admission_request_review;
      admission_request_review.admission_request_id = this.getRequestIdFromParams();
      admission_request_review.start_date = this.getCurrentDate();
      admission_request_review.admission_request_review_id = this.admissionRequestReviewId;
      
      let finalObject = {
        token_auth: this.getToken(),
        admission_request_review
      };

      return finalObject;
    },
    validateInfo(object, validate) {
      if (validate) {
        this.admission_request_review.admission_request_review.review_information[object].validated = validate;
        this.admission_request_review.admission_request_review.review_information[object].validation_date = this.getCurrentDate();  
      } else {
        this.admission_request_review.admission_request_review.review_information[object].validated = validate;
        this.admission_request_review.admission_request_review.review_information[object].validation_date = "";  
      }
      
      this.setToLocalStorage("request_review_"+this.getRequestIdFromParams(), this.admission_request_review);  
    },
    validateDocument(validate, object) {
      console.log("Dato "+validate+" Validacion "+ object)
      this.admission_request_review.admission_request_review.review_information.documents[object].validated = validate
      this.admission_request_review.admission_request_review.review_information.documents[object].validation_date = validate ? this.getCurrentDate() : "";
      this.setToLocalStorage("request_review_"+this.getRequestIdFromParams(), this.admission_request_review);  
      this.activateMailingButton();
    },
    activateMailingButton(){
      const documents = this.admission_request_review.admission_request_review.review_information.documents;
      const documentsArr = [];
      for(var document in documents){
        documentsArr.push(this.admission_request_review.admission_request_review.review_information.documents[document]);
      }
      const active = documentsArr.find((document) => document.validated == false);
      this.displayInput = (active)? true: false;
    },
    getCurrentDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd;
      return today;
    },
    getCurrentHour() {

    },
    getCurrentDateAndHour(date) {
      let year = date.toLocaleDateString('es-MX', { year: 'numeric' });
      let monthName = this.camelize(date.toLocaleDateString('es-MX', { month: 'short' })).replaceAll(".", "");
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let day = date.toLocaleDateString('es-MX', { day: 'numeric' });

      let data = {
        date: `${year}-${month}-${day} ${date.getHours()}:${date.getMinutes()}:00`,
        dateName: `${day} ${monthName} ${year.substr(-2)}`,
        hour: `${date.getHours()}:${date.getMinutes()}`
      }
      return data
    },
    async createReview(showMessage) {
      this.isLoading = true;
      var swal = require('sweetalert2');
      
      let json = this.getFinalObjectAdmissionRequestReview();

      let config = {
        headers: {
          'Content-Type': 'application/json'
        },
      }
      return this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/create_review`, json, config)
        .then((response) => {
          if(showMessage) {
            this.saveReviewRequest();
            
            this.isLoading = false;
            swal.fire({
              title: "Aviso",
              text: "La revisión se guardó correctamente",
              icon: "success",
              confirmButtonColor: '#FEB72B',
            });
          }
      }).catch(error => {
        if(showMessage) {
          this.isLoading = false;
          swal.fire({
            title: "Aviso",
            text: "Ocurrió un error al guardar la revisión",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
        }
      });
    },
    documentsAndInfoValidated() {
      const missingDocuments = this.$refs.documentControlRef.missingRequiredDocuments();
      
      let workplace = this.admission_request_review.admission_request_review.review_information.workplace_information.validated;
      
      if(!["cabsa_employee","educator","businessman"].includes(this.clientClassification)){
        workplace = true
      }
      
      const modulesMissingToValidated = !this.admission_request_review.admission_request_review.review_information.personal_information.validated
       || !this.admission_request_review.admission_request_review.review_information.adress_information.validated
       || !this.admission_request_review.admission_request_review.review_information.workplace_information.validated
       || !this.admission_request_review.admission_request_review.review_information.economical_information.validated
       || !this.admission_request_review.admission_request_review.review_information.beneficiaries_information.validated;

      console.log("modulesMissingToValidated; ", modulesMissingToValidated)
      this.admission_request_review.admission_request_review.review_information.workplace_information.validated = workplace;
      return {
          modulesMissingToValidated,
          havePendingDocuments: missingDocuments.length > 0, 
          missingDocuments }

    },
    validateEmptyDate(){
      let list_erros = [];


      if(['businessman','educator'].includes(this.clientClassification)) {
        if (this.admission_request_update.admission_information.work_info_attributes.laboral_status == "ACTIVO") {
          if (this.active_since_datos_laborales == "" || this.active_since_datos_laborales == null) {
            list_erros.push("Fecha de ingreso");
          }
        }
      } else {
        if (this.active_since_datos_laborales == "" || this.active_since_datos_laborales == null) {
          list_erros.push("Fecha de ingreso");
        }
      }

      if (this.admission_request_update.admission_information.public_office_attributes.has_had_public_positions == "1") {
        if (this.start_date_public_positions == "" || this.start_date_public_positions == null || this.end_date_public_positions == "" || this.end_date_public_positions == null) {
          list_erros.push("Rango de fechas del Cargo Público");
        }
      }
      return list_erros;
    },
    validateEmptyDateInfoEconomica(){
      if (this.start_date_saving == "" || this.start_date_saving == null || this.end_date_saving == "" || this.end_date_saving == null) {
        return true;
      } else {
        return false;
      }
    },
    openlModalObservationHistory() {
      this.showModalObservationHistory = false;
      this.showModalObservationHistoryAdd = true;
    },
    openModalDatosPersonales() {
      this.gender_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.gender : "";
      this.rfc_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.rfc : "";
      this.name_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.name : "";
      this.middle_name_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.middle_name : "";
      this.paternal_name_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.paternal_name : "";
      this.maternal_name_spouse = this.admission_request_update.admission_information.spouse_attributes != undefined ? this.admission_request_update.admission_information.spouse_attributes.maternal_name : "";
      this.setContactInfoDatosPersonalesUpdate();
      this.showModalDatosPersonales = true;
    },
    setContactInfoDatosPersonales() {
      let contact_info_datos_personales = this.convertListToArray(this.admission_request_update.admission_information.contact_info_attributes);
      this.phone_datos_personales = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'telefono particular').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'telefono particular')[0].value : "";
      this.cellphone_datos_personales = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'celular').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'celular')[0].value : "";
      this.email_datos_personales = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'correo').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'correo')[0].value : "";
    },
    setContactInfoDatosPersonalesUpdate() {
      let contact_info_datos_personales = this.convertListToArray(this.admission_request_update.admission_information.contact_info_attributes);
      this.phone_datos_personales_update = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'telefono particular').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'telefono particular')[0].value : "";
      this.cellphone_datos_personales_update = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'celular').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'celular')[0].value : "";
      this.email_datos_personales_update = contact_info_datos_personales.filter(({contact_type}) => contact_type === 'correo').length > 0 ? contact_info_datos_personales.filter(({contact_type}) => contact_type === 'correo')[0].value : "";
    },
    setContactInfoDatosLaboralesUpdate(){
      let contact_info_datos_laborales = this.convertListToArray(this.admission_request_update.admission_information.workplace_attributes.contact_info_attributes);
      this.phone_datos_laborales_update = contact_info_datos_laborales.filter(({contact_type}) => contact_type === 'telefono').length > 0 ? contact_info_datos_laborales.filter(({contact_type}) => contact_type === 'telefono')[0].value : "";
      this.extension_datos_laborales_update = contact_info_datos_laborales.filter(({contact_type}) => contact_type === 'telefono').length > 0 ? contact_info_datos_laborales.filter(({contact_type}) => contact_type === 'telefono')[0].extension : "";
    },
    validateRankDate(startDate, endDate) {
      if (Date.parse(startDate) > Date.parse(endDate)) {
        return true;
      } else {
        return false
      }
    },
    getYearsSaving(startDate, endDate) {
      var swal = require('sweetalert2');
      var rankDate = this.validateRankDate(startDate, endDate);

      if (rankDate) {
        swal.fire({
          title: "Aviso",
          customClass: {
            container: 'my-swal'
          },
          text: "La fecha de Inicio del Ahorro no puede ser mayor a la fecha final del Ahorro",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        });

        return;
      }

      var startDateSplit = startDate.split('-');
      var endDateSplit = endDate.split('-');
      var startDateObject = new Date(startDateSplit[0], startDateSplit[1] -1, startDateSplit[2]);
      var endDateObject = new Date(endDateSplit[0], endDateSplit[1] -1, endDateSplit[2])

      var month = endDateObject.getMonth();
      var day = endDateObject.getDate();
      var year = endDateObject.getFullYear();

      endDateObject.setDate(day);
      endDateObject.setMonth(month);
      endDateObject.setFullYear(year);

      var years = Math.floor(((endDateObject - startDateObject) / (1000 * 60 * 60 * 24) / 365));
    },
    validateRfc(rfc, aceptarGenerico = true) {
      let rfc_pattern = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                      "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                      "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                      "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
      return rfc.match(rfc_pattern);
    },
    validateInputRfc(rfcStr) {
      let rfc = rfcStr.toUpperCase();
      
      let correctRfc = this.validateRfc(rfc);
  
      if (correctRfc) {
        return true;
      } else {
        return false;
      }
    },
    changeDiscountMethod() {
      this.keyType = "";
      this.keyAccount = "";
      this.cardNumber = "";
      this.expirationMonth = "";
      this.expirationYear = "";
      this.bank = "";
    },
    changeKeyType() {
      this.keyAccount = "";
      this.cardNumber = "";
      this.expirationMonth = "";
      this.expirationYear = "";
      this.bank = "";

      let bank_account_attributes_array = this.convertListToArray(this.admission_request_update.admission_information.bank_account_attributes);
      let index = 0;

      bank_account_attributes_array[index].bank_name = "";
      bank_account_attributes_array[index].account_number = "";
      bank_account_attributes_array[index].expiration_month = "";
      bank_account_attributes_array[index].expiration_year = "";

      let object_bank_account_attributes = Object.assign({}, bank_account_attributes_array);
      this.admission_request_update.admission_information.bank_account_attributes = object_bank_account_attributes;
    },
    downloadFile() {
      let documentItem = [];
      if (this.documentNameSelected != "" && this.documentNameSelected != undefined) {
        documentItem = this.documents[this.documentSelected].filter(doc => doc.document_name.toUpperCase() === this.documentNameSelected.toUpperCase());  
      } else {
        documentItem = this.documents[this.documentSelected].filter(doc => doc.document_type.toUpperCase() === this.documentSelected.toUpperCase());
      }
      
      var link = document.createElement("a");
      link.download = `${documentItem[0].document_name}.${documentItem[0].extension}`;
      link.href = documentItem[0].url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async getRecorderScript() {
      var swal = require('sweetalert2');
      if(["cabsa_member","member"].includes(clientClassifications[this.clientClassification])){
        return await this.videoClassObject.getRecorderScript(this.getToken(), 'admission_request', "FONCABSA", "activo", clientClassifications[this.clientClassification]).then(response => {
          this.recorderScript = this.getProcessedScript(response);
        }).catch(error => {
          swal.fire({
            title: "Aviso",
            text: error,
            icon: "error",
            confirmButtonColor: '#FEB72B',
          })
        })
      }
    },
    getProcessedScript(script) {
      let wordsToBeReplaced = [
        { value: '"Diga su nombre completo"', newValue: this.clientFullName },
        { value: '“Diga el tipo de descuento que eligió ya sea NÓMINA o DOMICILIACIÓN”', newValue: this.admission_request.admission_information.saving_registration_attributes.payment_method },
        { value: '“Diga la frecuencua elegida, ejemplo: QUINCENAL, MENSUAL”', newValue: this.admission_request.admission_information.saving_registration_attributes.payment_frecuency.toString().toUpperCase() }
      ]

      return this.videoClassObject.getProcessedScript(wordsToBeReplaced, script);
    },
    setAdmissionDatePicker() {
      let self = this;
      if (this.admission_request_update.admission_information.work_info_attributes.laboral_status == "ACTIVO") {
        $("body").delegate("#datepickerAdmissionDate", "focusin", function () {
          $.datepicker.setDefaults($.datepicker.regional["es"]);
          $("#datepickerAdmissionDate").datepicker({
            changeMonth: true,
            changeYear: true,
            yearRange: self.minYearDatePicker+":+nn",
            dateFormat: 'dd/mm/yy',
            maxDate: new Date(self.maxDate),
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
            dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
            onSelect:function(selectedDate, datePicker) {            
              self.active_since_datos_laborales = selectedDate;
            }
          });
        }); 
      }
    },
    redirect(ruta){
      window.location.href = ruta
    },
    async registerHistoryMissingDocument(description, missingDocuments, body){
      var send_to=this.promoter_email_check; 
      var token_auth = this.getToken()
      var process = "missing_documents"
      var copy_to=[];
      var sender = "clientes@foncabsa.com"
      var subject = "Documentación faltante"
      var table_id = this.getRequestIdFromParams()
      var table_type= "admission_requests";

      var content = {
        associate_name: `${this.name_associate} ${this.paternal_name_associate}`,
        client_full_name: this.clientFullName.toUpperCase(),
        missing_documents: missingDocuments,
        description: description,
        html: body
      };

      body = JSON.stringify(content);
      var params = {
        token_auth,
        process,
        sender,
        send_to,
        copy_to,
        subject,
        body,
        table_id,
        table_type
      };

      this.admissionRequestObject.registerHistoryMissingDocuments(params).then(response => {
        this.getHistoryMissingDocuments();
        console.log("historial", "registrado correctamente", response);
      }).catch(error => {
        console.log('error', error);
      });
    },
    async getHistoryMissingDocuments(){
      await this.admissionRequestObject.getHistoryMissingDocuments(
          sessionStorage.getItem("login"),
          "admission_requests",
          this.getRequestIdFromParams(),
          "missing_documents"
        ).then(response => {
          this.listMailHistory = response.data.response.data;
          this.listMailHistory.forEach((value) => {
            value.body = JSON.parse(value.body);
            return value;
          });
          console.log("historial", "obtenido correctamente", this.listMailHistory);
      }).catch(error => {
        console.log('error', error);
      });
    },
    selectBirthdayBeneficiarie(event){
      this.birthday_beneficiarie=moment(event._d).format("DD/MM/YYYY");
    },
    selectActiveSinceDatosLaborales(event){
      this.active_since_datos_laborales=moment(event._d).format("DD/MM/YYYY");
    },
    selectBirthday(event){
      this.birthday_datos_personales=moment(event._d).format("DD/MM/YYYY");
      this.admission_request_update.admission_information.birthday = this.birthday_datos_personales;
    },
    async sendMailResendDocumentCincel(){
     try {
      this.isLoading = true;
      const _response =await this.admissionRequestObject.sendMailResendDocumentCincel({
        token_auth:sessionStorage.getItem('login'),
        admission_request_id:this.admission_request.id,
        "abbreviation":"AUDSCTOAHORRO"
      });

      if(_response.status==200){
        Swal.fire({
          icon:"success",
          title:"El correo fue enviado.",
          text:"Sea realizo el envió al correo la firma del documento."
        });
        this.isLoading = false;
      }
      if(_response.status!=200){      
        let messages="";
        _response.data.errors.forEach(element => {
            messages+="<strong>■ "+element+".</strong><br>";
        });
        Swal.fire({
          title: "Aviso",
          html:messages,
          icon: "info",
          confirmButtonText: "OK",
          confirmButtonColor: '#FEB72B'
        });
         this.isLoading = false;
      }
     } catch(error){
        this.isLoading = false;
      console.log("Eror inesperado :: ",error)
      Swal.fire({
          title: "Ocurrio un error inesperado",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: '#FEB72B'
        });
     }
    },
    formatDate(date){
      var d = date.split("-")
      return d[2]+"/"+d[0]+"/"+d[1]
    },
    includeDashesCardNumber(cardNumber, bank){
      if(cardNumber.includes("-")){
        return cardNumber;
      }
      
      if(bank == "AMERICAN EXPRESS"){
        return cardNumber.substring(0,4) +"-"+ cardNumber.substring(4,10) +"-"+ cardNumber.substring(10,15);
      }
      return cardNumber.substring(0,4) +"-"+ cardNumber.substring(4,8) +"-"+ cardNumber.substring(8,12) +"-"+ cardNumber.substring(12,17);
    },

    selectDate(event,op){
      switch(op){
        case 1:
          if(moment(event._d).format("YYYY-MM-DD")>moment().format("YYYY-MM-DD")){
             Swal.fire({
              title: "Aviso",
              text:
                "La fecha de ingreso tiene que ser menor a la actual.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.start_date_public_positions =""
          }else{
            this.start_date_public_positions = moment(event._d).format("DD-MM-YYYY")
          }
          document.getElementById('vpd-admissionDate').value = this.admissionDate
          
        break;
        case 2:
         if(moment(event._d).format("YYYY-MM-DD")>moment().format("YYYY-MM-DD")){
              Swal.fire({
              title: "Aviso",
              text:
                "La fecha de inicio de cargo público tiene que ser menor a la actual.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
              this.end_date_public_positions=""
          } else{
            this.end_date_public_positions = moment(event._d).format("DD-MM-YYYY")
          }
          
        break;
        case 3:
        
          if(moment(event._d).format("YYYY-MM-DD")>moment().format("YYYY-MM-DD")){
            Swal.fire({
              title: "Aviso",
              text:
                "La fecha de final de cargo público tiene que ser mayor a del inicio.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.start_date_public_positions_familiar=""
          } else{
            this.start_date_public_positions_familiar = moment(event._d).format("DD-MM-YYYY")
          }
        break;
        case 4:

          if(moment(event._d).format("YYYY-MM-DD")>moment().format("YYYY-MM-DD")){
            Swal.fire({
              title: "Aviso",
              text:
                "La fecha de inicio de familiar con cargo público tiene que ser menor a la actual.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.end_date_public_positions_familiar=""
          } else{
            this.end_date_public_positions_familiar = moment(event._d).format("DD-MM-YYYY")
          }

        break;
      }
    },
    fomartNumber(value){
      var regex = /^[,.]+$/;
      if (regex.test(value) != true) {
        value = value.replace(
          /[^0-9.]+/g,
          ""
        );
      }
      return value;
    },
    pendingToAceptedRequestWithPayment(){
      var swal = require('sweetalert2');
      var paymentIntent = null
      var daysForAceptedRequest = 0
      
      if (this.listPaymentIntent.length > 0 && this.requestStatus == "en_revision"){

        paymentIntent = this.listPaymentIntent[0]
        var dateMoreThreeDays = new Date(paymentIntent.created_at)
        dateMoreThreeDays.setDate(dateMoreThreeDays.getDate() + 3);
        var dateNow = new Date();
        
        let difference = dateMoreThreeDays.getTime() - dateNow.getTime();
        daysForAceptedRequest = Math.ceil(difference / (1000 * 3600 * 24));
        
        if (daysForAceptedRequest > 0){
          var msg = "Cliente realizó el pago de parte social, tienes "+daysForAceptedRequest+" dias para aceptar o rechazar solicitud."
          swal.fire({
            title: "Atención",
            icon: 'info',
            text: msg,
            reverseButtons:true,
            confirmButtonColor: '#FEB72B',
            confirmButtonText: 'OK',
            allowOutsideClick: false
          })
        }
      }
    }
  }
}
</script>
<style scoped>
.modal-footer {
    padding: 15px;
    text-align: center!important;
    border-top: 1px solid #e5e5e5;
}
.bg-active {
  background-color: #53BB93;
}
.bg-inactive {
  background-color: #f98b04;
}
.border-green {
  border-color: #82c785;
}
.font-green {
  color: #82c785;
  font-size: 12px;
}
.swal2-input-label {
  display: table-row;
}
.swal2-confirm {
  order: 2;
}
.swal2-cancel {
 order: 1;
}
</style>
<style scoped>
.swal2-input-label {
  display: table-row;
}
.swal2-confirm {
  order: 2;
}
.swal2-cancel {
 order: 1;
}
.align-list{
  width: 30%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.buttonSave {
    background-color: #4CAF50; /* Green */
    opacity: 0.7;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 1px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-family:Lato;
    widows: 10px;
    height: 30px;
    cursor: pointer;
}

.buttonWithoutBackground {
    background-color: #fff; /* Green */
    border: 1px solid #85c875;
    border-radius: 5px;
    color: #85c875;
    padding: 1px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-family:Lato;
    widows: 10px;
    height: 30px;;
    cursor: pointer;
}
.buttonBorderBlur {
    background-color: #fff; /* Green */
    border: 1px solid #810042;
    border-radius: 5px;
    color: #810042;
    padding: 1px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-family:Lato;
    widows: 10px;
    height: 30px;;
    cursor: pointer;
}
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20%;
  cursor: pointer;
  height: 22px;
  left: 0;
  position: relative;
  top: 0;
  width: 22px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}
.comment label{
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20%;
  cursor: pointer;
  height: 22px;
  left: 0;
  position: relative;
  top: 0;
  width: 22px;
}
.comment label:after {
  border: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 6px;
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

/*Checkbox with message*/
.comment input[type="checkbox"] {
  visibility: hidden;
}

/* .comment input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
} */

.comment input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.comment svg {
  width:13px;
  height: 13px;
  margin:0 auto;
  margin-top:4px;
  margin-left:3px;
}

.pdfobject-container { 
  height: 100%; 
  border: 1rem solid rgba(0,0,0,.1); 
}

.inputText {
  border: 1px solid #810042 !important;
  height: 30px !important;
  text-transform: uppercase;
  border-radius:5px;
}

.labelTitle {
  font-size:15px;
  color:#810042;
  font-weight:700;
  font-family:Lato;
}

.imageContainer {
  padding:10px;
}

@media (max-width: 767px) {
  .pdfobject-container { 
    height: 77rem; 
  }
  .imageContainer {
    margin-top:30px;
  }
}

.my-swal {
  display: -webkit-box;
  display: flex;
  position: fixed;
  z-index: 300000;
}

.container_video_authentication {
  padding:10px;
  font-family:Lato;
  font-weight:400;
  text-align:center;
  font-size:18px;
  color:#174A84;
}

.video_authentication {
  width: 100%;
}
table.width200,table.rwd_auto {border:1px solid #ccc;width:100%;margin:0 0 50px 0}
    .width200 th,.rwd_auto th {background:#ccc;padding:5px;text-align:center;}
    .width200 td,.rwd_auto td {border-bottom:1px solid #ccc;padding:5px;text-align:center}
    .width200 tr:last-child td, .rwd_auto tr:last-child td{border:0}

@media only screen and (max-width: 1024px)  
{
    table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }
    
    .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }
    
    .width200 tr { border: 1px solid #ccc; }
    
    .width200 td { border: none;border-bottom: 1px solid #ccc; position: relative;padding-left: 40%;text-align:left }
    
    .width200 td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}

    .width200 td:nth-of-type(1):before { content: "Nombre:"; color: #000; font-weight: bold; }
    .width200 td:nth-of-type(2):before { content: "Apellidos:"; color: #000; font-weight: bold; }
    .width200 td:nth-of-type(3):before { content: "Fec. de Nac:"; color: #000; font-weight: bold; }
    .width200 td:nth-of-type(4):before { content: "%:"; color: #000; font-weight: bold; }
    .width200 td:nth-of-type(5):before { content: "Parentesco:"; color: #000; font-weight: bold; }
    .width200 td:nth-of-type(6):before { content: "Acciones:"; color: #000; font-weight: bold; }
    
    .descarto {display:none;}
    .fontsize {font-size:10px}
}

/* #datepickerStartDatePublicPosition {
  background-color: #fff;
} */

/* #datepickerStartDatePublicPositionFamiliar {
  background-color: #fff;
} */

/* #datepickerEndDatePublicPosition {
  background-color: #fff;
} */

/* #datepickerEndDatePublicPositionFamiliar {
  background-color: #fff;
} */

#datepickerAdmissionDate {
  background-color: #fff;
}

#datepickerBirthDateBeneficiarie {
  background-color: #fff;
}

#datepickerStartDateSaving {
  background-color: #fff;
}

#datepickerEndDateSaving {
  background-color: #fff;
}

.modal-mask.grid.grid-cols-1 {
  z-index: 1;
}
</style>

<style>
.vpd-input-group input{
  border: 2px solid rgba(205, 205, 205, 0.416) !important;
  border-start-end-radius: 10px !important;
  border-end-end-radius: 10px !important;
  padding: 5px !important;
  box-shadow: 0px 5px 10px 0px  rgba(205, 205, 205, 0.416) !important;
}
input[value="Descargar documento"]{
  background-color: #4CAF50;
  opacity: 0.7;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-family: Lato;
  widows: 10px;
  height: 30px;
  cursor: pointer;
}
embed{
  width: 100%;
  height: 700px;
  border-radius: 0.25rem;
}
.document_label{
  font-weight: 700;
}
.document_description{
  margin-top: 13px;
  margin-inline: 5px;
}
.video_custom_class{
  width: 100%;
}
.custom-textarea {
  width: 100%!important;
  margin: initial;
}
</style>

<style>
.dp__theme_dark {
  --dp-background-color: #810042;
  --dp-text-color: #ffffff;
  --dp-hover-color: #484848;
  --dp-hover-text-color: #ffffff;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #005cb2;
  --dp-primary-text-color: #ffffff;
  --dp-secondary-color: #a9a9a9;
  --dp-border-color: #2d2d2d;
  --dp-menu-border-color: #2d2d2d;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #737373;
  --dp-scroll-bar-background: #212121;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #ffffff;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #ffffff;
  --dp-danger-color: #e53935;
  --dp-highlight-color: rgba(0, 92, 178, 0.2);
}
</style>