import api from '@/api/api';

const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA
const hostMultiApi = process.env.VUE_APP_MULTIAPI;
export default class Category {
    constructor() {

    }

    getByClientClasification(clasification){
        if(!['educator','student','retired'].includes(clasification)){
            return []
        }
        return this.getList();
    }

    getList(){
        return [
            {value: "PREESCOLAR",   name:"Preescolar"},
            {value: "PRIMARIA",     name:"Primaria"},
            {value: "SECUNDARIA",      name:"Secundaria"},
            {value: "MEDIA SUPERIOR",name:"Media superior"},
            {value: "SUPERIOR",name:"Superior"},
            {value: "EDUCACIÓN ESPECIAL",name:"Educación especial"},
            {value: "INDIGENA",name:"Indigena"},
            {value: "PROGRAMAS COMPENSATORIOS",name:"Programas compensatorios"},
            {value: "EDUCACION PARA ADULTOS",name:"Educación para adultos"},
            {value: "EDUCACION TECNOLOGICA",name:"Educación tecnológica"},
            {value: "EDUCACION NORMAL",name:"Educación normal"}
        ]
    }
}