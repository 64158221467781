export default class HousingType {
    constructor() {

    }

    getByClientClasification(clasification){
        if(!['businessman','educator','student','retired','health_worker','cabsa_employee'].includes(clasification)){
            return []
        }
        return this.getList();
    }

    getList(){
        return [
            {value: 'propia', name: 'Propia'},
            {value: 'rentada', name: 'Rentada'},
            {value: 'pagandola', name: 'Pagandola'},
            {value: 'con familiares', name: 'Con Familiares'},
            {value: 'prestada', name: 'Prestada'}
        ]
    }
}